import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import axios from "axios";
import Comments from "./Comments";
import Table from "./Table/Table";
import { useSelector } from "react-redux";
import EmptyTable from "./errorComponents/EmptyTable";
import { DatePicker, Space } from "antd";
import { useMemo, useState } from "react";
import CommentPop from "./errorComponents/CommentPop";
import { environment } from "./../env";
import swal from "sweetalert";
import { CalendarTick } from "iconsax-react";

interface DataRow {
  id: number;
  date: any;
  day: string;
  break_type: string;
  break_in_time: string;
  break_out_time: any;
  // comment: string;
  remark: any;
}

const UserBreakLog = () => {
  const useratt = useSelector(
    (state: any) => state?.root?.reducers?.userAttendance
  );
  console.log('useratt', useratt)
  
  const breaklog = useSelector((state: any) => state?.root?.reducers?.breaklog);
  console.log('breaklog', breaklog);

  const [att, setAtt] = useState([]);
  const [comments, setComments] = useState();
  const { RangePicker } = DatePicker;
  const [modal, setModal] = useState(false);
  var comment = "";

  const current = (data: any) => {
    setModal(true);
    comment = data;
    setComments(data);
  };

  useMemo(() => {
    setComments(comments);
  }, [comments]);

  const columns: TableColumn<DataRow>[] = [
    {
      name: "Date",
      selector: (row) => moment(row?.date).format('DD-MM-YYYY'),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Day",
      selector: (row) => moment(row?.date).format("dddd"),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Break In Time",
      selector: (row) => moment(row?.break_in_time.split('T')[1].split('+')[0],'HH:mm:ss').format('hh:mm A'),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Break Out Time",
      selector: (row) => row?.break_out_time == "null" ? '---' : moment(row?.break_out_time.split('T')[1].split('+')[0],'HH:mm:ss').format('hh:mm A'),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Break Type",
      selector: (row) => (
        <div
          className={`${
            row?.break_type == "Lunch"
              ? "status-bg-green status-color-green "
              : row.remark == "Tea"
              ? "status-bg-orange status-color-orange"
              : row.remark == "Loo"
              ? "status-bg-blue status-color-blue"
              : row.remark == "Other"
              && "status-bg-yellow status-color-yellow"
          } w-100 padding-box rounded-5 d-flex justify-content-center align-items-center`}
        >
          <span className="base-font-inter fw-bold fs-6 text-capitalize">
            {row?.break_type}
          </span>
        </div>
      ),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Remark",
      selector: (row) => row?.remark == 'null' ? '---' : row?.remark,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
  ];

  const onSubmit = (data: any) => {
    if (data) {
      let SearchUrl;
      SearchUrl = `${environment.baseUrl}attendance-filter?employee_id=${
        useratt[0].employee_id
      }&date_after=${data[0].format("YYYY-MM-DD")}&date_before=${data[1].format(
        "YYYY-MM-DD"
      )}`;
      axios(SearchUrl).then((response) => {
        if (response?.data) {
          setAtt(response?.data);
        } else {
          swal({
            title: "Warning",
            text: "Data is Not Available for search 🤷🏻‍♂️",
            icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
            dangerMode: true,
          });
        }
      });
    } else {
      setAtt([]);
    }
  };

  return (
    <>
      {modal && (
        <CommentPop modaldata={modal} modal={setModal} data={comments} />
      )}
      <section className="main-wrapper-section px-4 pb-5 ">
        <div className="dashboard-heading pb-3  mb-4  px-0 d-flex align-items-center">
          <span className="base-text-color base-font-700 fs-4">
            <CalendarTick size="35" variant="Bulk" />
          </span>
          <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
            Break Log
          </h4>
        </div>
        <div className="bg-white py-3 px-5 d-flex gap-5 flex-column rounded-tb shadow">
          <div className="">
            {breaklog[0] ? (
              <Table columns={columns} data={breaklog.length != 0 && breaklog} />
            ) : (
              <EmptyTable />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default UserBreakLog;
