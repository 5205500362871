import { ArchiveBook } from 'iconsax-react'
import React from 'react'

const TaskCard = ({ tasks }: any) => {
    return (
        <div className="todo-box-main" >
            <div className="Todo-lists-main p-2 px-1 pb-0">
                <div className="Todo-lists-box rounded-4 px-3 py-4 pb-0">
                    <h4 className='fs-4 text-info pb-2 base-font-pop fw-bold '>Your Task list.. ✍🏻</h4>
                    <div className="Todo-lists d-flex flex-column gap-3">
                        {
                            tasks?.map((task: any) => {
                                return <div className="d-flex gap-3  px-2 py-2 mb-1 status-bg-blue rounded-4 align-items-center project_task border">
                                    <div className="todo-box text-warning">
                                        <ArchiveBook variant="Outline" className='fs-2 ' />
                                    </div>
                                    <div className="">
                                        <h6 className='fs-5  mb-0 text-black text-capitalize base-font-500'>#{task?.team?.project.project_key.toUpperCase()}-{task?.id}</h6>
                                        <h6 className='fs-5  mb-0 text-black text-capitalize base-font-500'>{task?.task_name.length > 12 ? `${task?.task_name.slice(0, 12)}...` : task?.task_name}</h6>
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TaskCard