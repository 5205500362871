import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom'
import { Button, Card, CardBody, CardFooter, CardTitle, Form, Input, Label } from 'reactstrap'
import { environment } from '../../../env';

const AddFamilyDetails = () => {
    let [show, setShow] = useState(false)
    const [userDetail, setUserDetail] = useState(
        {
            father_name: ' ',
            father_occupation: ' ',
            father_contact: ' ',
            mother_name: ' ',
            mother_occupation: ' ',
            mother_contact: ' ',
            maritial_status: ' ',
            spouse_name: ' ',
            spouse_dob: ' ',
            spouse_ocupation: ' ',
            spouse_contact_no: ' ',
            number_of_children: ' '
        }
    );
    let EmpId = localStorage.getItem("employee_id");
    let userId = localStorage.getItem("userid");

    const navigate = useNavigate();

    const {
        reset,
        formState: { errors },
    } = useForm();

    const handleOnChange = (e: any) => {
        setUserDetail({
            ...userDetail,
            [e.target.name]: e.target.value
        });
        if ((e.target.value) === "unmarried") {
            setShow(false)
        }
        if ((e.target.value) === "married") {
            setShow(true)
        }
    }

    const UserPersonalDetails = async () => {
        try {
            const result = await axios(`${environment.baseUrl}get-basic-details/${EmpId}`).then((res) => {

                if (res.data.father_name === "null") {
                    res.data.father_name = ""
                }
                if (res.data.father_occupation === "null") {
                    res.data.father_occupation = ""
                }
                if (res.data.father_contact === "null") {
                    res.data.father_contact = ""
                }
                if (res.data.mother_name === "null") {
                    res.data.mother_name = ""
                }
                if (res.data.mother_ocupation === "null") {
                    res.data.mother_ocupation = ""
                }
                if (res.data.mother_contact === "null") {
                    res.data.mother_contact = ""
                }
                if (res.data.maritial_status === "null") {
                    res.data.maritial_status = ""
                }
                if (res.data.spouse_name === "null") {
                    res.data.spouse_name = ""
                }
                if (res.data.spouse_occupation === "null") {
                    res.data.spouse_occupation = ""
                }
                if (res.data.spouse_contact_no === "null") {
                    res.data.spouse_contact_no = ""
                }
                if (res.data.spouse_dob === "null") {
                    res.data.spouse_dob = ""
                }
                if (res.data.number_of_children === "null") {
                    res.data.number_of_children = ""
                }

                setUserDetail(res.data)
                localStorage.setItem("LoggedUserDetails", JSON.stringify(res.data))
                reset(res.data)
            })
                .catch((error) => {
                    console.log("Error", error);
                })

        } catch (err) {
            console.log("Error", err);
        }
    };
    useEffect(() => {
        UserPersonalDetails();

    }, [])

    const ProfilePostMethod = async () => {
        let form_data = new FormData();
        form_data.append('father_name', userDetail.father_name)
        form_data.append('father_occupation', userDetail.father_occupation)
        form_data.append('father_contact', userDetail.father_contact)
        form_data.append('mother_name', userDetail.mother_name)
        form_data.append('mother_occupation', userDetail.mother_occupation)
        form_data.append('mother_contact', userDetail.mother_contact)
        form_data.append('maritial_status', userDetail.maritial_status)
        form_data.append('spouse_name', userDetail.spouse_name)
        form_data.append('spouse_contact_no', userDetail.spouse_contact_no)
        form_data.append('spouse_ocupation', userDetail.spouse_ocupation)
        form_data.append('spouse_dob', userDetail.spouse_dob)
        form_data.append('number_of_children', userDetail.number_of_children)
        try {
            const res = await axios.put(`${environment.baseUrl}update-basic-details/${EmpId}`, form_data);
            return res.data
        }
        catch (err) {
            console.log("err");
            return err;

        }
        return
    };

    const onSubmit = async (e: any) => {
        e.preventDefault();
        ProfilePostMethod().then((profileResponse) => {
            if (profileResponse.status === 'success') {
                swal({
                    title: "Success",
                    text: 'Family Details are updated Successfully !',
                    icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                })
                    .then(() => {
                        navigate(-1)
                    })
            }
        });

    }


    return (
        <div>
            <Card style={{ background: "white", width: "95%", marginLeft: "36px", height: "80%" }}>
                <CardTitle >
                    <h1 className="h3 mb-0 text-gray-800" style={{ fontWeight: 500, paddingTop: "20px", paddingLeft: "20px" }}>Family Details</h1>
                </CardTitle>
                <CardBody style={{}}>
                    <Form
                        id="details" onSubmit={(e) => onSubmit(e)}>
                        <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", gap: 20, marginTop: '5px' }}>
                            <div className="width-left" style={{ width: "50%" }}>
                                <Label style={{ color: "black", fontSize: 18, fontWeight: 500 }}>

                                    Father's Name
                                </Label>
                                <Input
                                    type="text"
                                    id="f_name"
                                    name="father_name"
                                    value={userDetail.father_name}
                                    onChange={handleOnChange}
                                    placeholder="Father's Name">
                                </Input>
                                <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>

                                    Father's Occupation
                                </Label>
                                <Input
                                    type="text"
                                    id="f_occupation"
                                    name="father_occupation"
                                    value={userDetail.father_occupation}
                                    onChange={handleOnChange}
                                    placeholder="Father's Occupation">
                                </Input>
                                <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>

                                    Father's Contact Number
                                </Label>
                                <Input
                                    type="text"
                                    id="f_contact"
                                    name="father_contact"
                                    value={userDetail.father_contact}
                                    onChange={handleOnChange}
                                    placeholder="Father's Contact"
                                >
                                </Input>

                            </div>
                            <div className="width-right" style={{ width: "50%" }}>
                                <Label style={{ color: "black", fontSize: 18, fontWeight: 500 }}>

                                    Mother's Name
                                </Label>
                                <Input
                                    type="text"
                                    id="m_name"
                                    name="mother_name"
                                    value={userDetail.mother_name}
                                    onChange={handleOnChange}
                                    placeholder="Mother's Name">
                                </Input>
                                <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                    Mother's Occupation
                                </Label>
                                <Input
                                    type="text"
                                    id="m_occupation"
                                    name="mother_occupation"
                                    value={userDetail.mother_occupation}
                                    onChange={handleOnChange}
                                    placeholder="Mother's Occupation">
                                </Input>
                                <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                    Mother's Contact
                                </Label>
                                <Input
                                    type="text"
                                    id="m_contact"
                                    name="mother_contact"
                                    value={userDetail.mother_contact}
                                    onChange={handleOnChange}
                                    placeholder="Mother's Contact Number">
                                </Input>
                            </div>
                        </div>
                        <div style={{ marginTop: "15px" }}>
                            <Label style={{ marginBottom: "5px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                Maritial Status
                            </Label><br />
                            <Input
                                type="radio"
                                id="maritial_status"
                                name="maritial_status"
                                value="unmarried"
                                checked={userDetail.maritial_status === "unmarried"}
                                onChange={handleOnChange}
                                style={{ marginLeft: "10px", fontSize: 20 }}
                                
                            >
                            </Input>
                            <Label style={{ paddingLeft: "35px" }} >
                                Un-Married
                            </Label>

                            <Input
                                type="radio"
                                id="maritial_status"
                                name="maritial_status"
                                value="married"
                                checked={userDetail.maritial_status === "married"}
                                onChange={handleOnChange}
                                style={{ marginLeft: "15px", fontSize: 20 }}

                            >
                            </Input>
                            <Label
                                style={{ marginLeft: "39px" }} 
                            >
                                Married
                            </Label>
                        </div>

                        {show || userDetail.maritial_status === "married" ?
                            <>
                                <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", gap: 20, marginTop: '5px' }}>
                                    <div className="width-left" style={{ width: "50%" }}>
                                        <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                            Spouse Name
                                        </Label>
                                        <Input
                                            type="text"
                                            id="s_name"
                                            name="spouse_name"
                                            value={userDetail.spouse_name}
                                            onChange={handleOnChange}
                                            placeholder="Spose Name">
                                        </Input>
                                        <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                            Spouse Occupation
                                        </Label>
                                        <Input
                                            type="text"
                                            id="spouse_ocupation"
                                            name="spouse_ocupation"
                                            value={userDetail.spouse_ocupation}
                                            onChange={handleOnChange}
                                            placeholder="Spose Occupation">
                                        </Input>
                                    </div>
                                    <div className="width-right" style={{ width: "50%" }}>
                                        <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                            Spouse DOB
                                        </Label>
                                        <Input
                                            type="date"
                                            id="s_dob"
                                            name="spouse_dob"
                                            value={userDetail.spouse_dob}
                                            onChange={handleOnChange}
                                            placeholder="Spouse Dob">
                                        </Input>
                                        <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                            Spouse Contact Number
                                        </Label>
                                        <Input
                                            type="text"
                                            id="spouse_contact_no"
                                            name="spouse_contact_no"
                                            value={userDetail.spouse_contact_no}
                                            onChange={handleOnChange}
                                            placeholder="Spouse Contact Number">
                                        </Input>
                                    </div>
                                </div>
                                <div>
                                    <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                        Number Of Children
                                    </Label>
                                    <Input
                                        type="text"
                                        id="number_of_children"
                                        name="number_of_children"
                                        value={userDetail.number_of_children}
                                        onChange={handleOnChange}
                                        placeholder="Number Of Children">
                                    </Input>
                                </div>
                            </> : <></>}
                    </Form>
                </CardBody>
                <CardFooter>
                    <Button
                        color='success'
                        type="submit"
                        form="details"
                        value="Submit"
                        style={{ fontWeight: 600, position: "relative", left: 975, width: "200px" }}>

                        Submit
                    </Button>
                </CardFooter>
            </Card>
        </div >
    )
}

export default AddFamilyDetails


