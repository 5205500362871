import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { environment } from '../env';
import RewardsModal from './RewardsModel';
import Table from './Table/Table';
import EmptyTable from './errorComponents/EmptyTable';
import { Gift } from 'iconsax-react';

interface DataRow {
    employee_id: any;
    file: string;
    created_date: string;
    doc_info: any;
}

const RewardsPage = () => {
    const EmpId = localStorage.getItem('employee_id')
    const [update, setUpdate] = useState(false)
    const [rewards, setRewards] = useState<DataRow[]>([])

    const GetRewards = async () => {
        await axios.get(`${environment.baseUrl}rewarddocument/${EmpId}`).then(res => {
            setRewards(res.data)
        })
            .catch(res => console.log("Upload doc error"))
    };

    const ExamplePDFViewer = (file: any) => {
        window.open(`${file}`);
    };

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'EmpId',
            selector: row => row?.employee_id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Document',
            selector: row => <Link onClick={() => ExamplePDFViewer(row?.file)} to=''>View</Link>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Upload Date',
            selector: row => moment(row?.created_date).format('DD/MM/YYYY'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'WordCount',
            selector: row => row?.doc_info == 'null' ? <span>no count</span> : row?.doc_info,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Points',
            selector: row => row?.doc_info ? row?.doc_info / 2 : <span>Invalid Doc</span>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
    ];

    useEffect(() => {
        GetRewards()
    }, [update])

    return (
        < >

            <section className="main-wrapper-section px-4 pb-5 ">
                <div className="dashboard-heading pb-3  mb-4  px-0 d-flex align-items-center">
                    <span className='base-text-color base-font-700 fs-4'><Gift size="35" variant="Bulk" /></span>
                    <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                        Rewards</h4>
                </div>
                <div className="bg-white py-3 px-5 d-flex gap-5 flex-column rounded-tb shadow">
                    <div className="">
                        <div className='d-flex align-items-center justify-content-end pb-4'>

                            <RewardsModal update={setUpdate} />
                        </div>
                        {rewards[0] ? <Table columns={columns} data={rewards} /> : <EmptyTable />}
                    </div>
                </div>
            </section>
        </>
    )
}

export default RewardsPage