import {    Modal, ModalBody, ModalFooter } from 'reactstrap';

const CommentPop = (props:any) => {
    return (
        <div>
            <Modal isOpen={props.modaldata} centered className='modalstyle' size='lg' >
                <ModalBody >
                    <div className=''>
                        <h2 className="fs-2 text-base-color base-font-pop fw-semibold text-center pb-3 ">Your Comment</h2>
                            <p className='base-font-pop fs-6 mb-3 px-5 text-justify' style={{lineHeight:1.8}}>{props.data}</p>
                    </div>

                </ModalBody>
                <ModalFooter className='border-0 justify-content-center'>
                    <button onClick={()=>{props.modal(false)}} className="close-btn">
                        Close
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default CommentPop;