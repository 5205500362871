import React, { useEffect, useState } from 'react'
import axios from 'axios';
import moment from 'moment';
import { environment } from '../../../env';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import HolidaysModal from './HolidaysModal';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import { useSelector } from 'react-redux';
import { MenuBoard } from 'iconsax-react';
import HolidaysInfo from './HolidayInfo';

interface UserData {
    name: string;
    date: any;
    day: any;
}
interface Event {
    title: string;
    overlap: boolean;
    date: string;
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    fontSize: string;
}


const Holidays = () => {

    const holidayss = useSelector((state: any) => state?.root?.reducers?.holidays);

    const [events, setEvents] = useState<Event[]>([]);
    const [modal, setModal] = useState(false)
    const [selectedinfo, setSelectedinfo] = useState();

    const handleDateClick = (e: any) => {
        console.log(e.event._def.title)
        setModal(true)
        setSelectedinfo(e.event._def.title);
    }
    useEffect(() => {
        if (holidayss) {
            setEvents(holidayss.map((post: { name: string; date: any; }) => {
                return {
                    title: post.name,
                    fontSize: '1rem',
                    overlap: false,
                    date: post.date,
                    backgroundColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                    borderColor: 'transparent',
                    textColor: 'white',
                }
            }))
        }
    }, [holidayss])


    return (
        < >
            <HolidaysInfo selectedinfo={selectedinfo} modal={modal} set={setModal} />
            <section className="main-wrapper-section px-4 pb-5 ">
                <div className="dashboard-heading pb-3  mb-4  px-0 d-flex align-items-center">
                    <span className='base-text-color base-font-700 fs-4'><MenuBoard size="35" variant="Bulk" /></span>
                    <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                        Holidays</h4>
                </div>
                <div className="">
                    <div className="bg-white p-5 shadow rounded-tb position-relative">
                        <HolidaysModal />
                        <div className="pt-3">
                            <FullCalendar
                                height={'50rem'}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                initialView="dayGridMonth"
                                events={events}
                                // dateClick={handleDateClick}
                                eventClick={handleDateClick}
                            />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Holidays