import { createStore,compose, applyMiddleware  } from "redux";
import rootReducer from "./userRedux/reducers";
import { persistStore,persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';


import { combineReducers } from 'redux';
const persistConfig = {
  key: 'root',
  storage,
}


const reducers = combineReducers({ root: rootReducer });
const persistedReducer = persistReducer(persistConfig, reducers);
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}
const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__());

export const persistor = persistStore(store);
export default store


