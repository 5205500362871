import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import axios from 'axios';
import moment from 'moment';
import Mispunch from './pages/mispunch';
import { environment } from '../env';
import Table from './Table/Table';
import EmptyTable from './errorComponents/EmptyTable';
import { Note } from 'iconsax-react';

interface DataRow {
    date: any;
    day: any;
    login_time: any;
    logout_time: any;
    comment: any;
    remark: any
}

const Mispunchdetails = () => {
    const [post, setPost] = useState<DataRow[]>([]);
    const [updateMispunch, setUpdateMispunch] = useState(false);

    let EmpId = localStorage.getItem("employee_id");

    const GetMisPunch = () => {
        axios.get(`${environment.baseUrl}mispunch-by-id/${EmpId}`)
            .then(res => {
                setPost(res.data);
            })
            .catch(res => console.log("error"));
    };

    useEffect(() => {
        GetMisPunch()
        setUpdateMispunch(false)
    }, [updateMispunch]);

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Day',
            selector: row => row.day,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Time-In',
            selector: row => moment(row.login_time.split("T")[1], "HH:mm:ss").format('hh:mm A'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Time-Out',
            selector: row => moment(row.logout_time.split("T")[1], "HH:mm:ss").format('hh:mm A'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Comments',
            selector: row => row.comment,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Remark',
            selector: row => <div
                className={`${row.remark == "Late" ? "status-bg-green status-color-green " :
                    row.remark == "Late EarlyLeave" ? "status-bg-yellow status-color-yellow" :
                        row.remark == "Late HalfDay" ? "status-bg-blue status-color-blue" :
                            row.remark == "Denied" ? "status-bg-red status-color-red" :
                                row.remark == "Absent" ? "status-bg-orange status-color-orange" :
                                    row.remark == "EarlyLeave" ? "status-bg-lime status-color-lime" :
                                        row.remark == "pending" ? "status-bg-yellow status-color-yellow" :
                                            row.remark == "FullDay" ? "status-bg-cyan status-color-cyan" :
                                                row.remark == "Approved" ? "status-bg-emerald status-color-emerald" :
                                                    row.remark == "HalfDay" && "status-bg-purple status-color-purple"
                    } w-100 py-2 px-5 rounded-5 d-flex justify-content-center align-items-center`}>
                <span className="base-font-inter fw-bold fs-6 text-capitalize">{row?.remark}</span>
            </div>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },

    ];

    return (
        <>
            <section className="main-wrapper-section px-4 pb-5 ">
                <div className="dashboard-heading pb-3  mb-4  px-0 d-flex align-items-center">
                    <span className='base-text-color base-font-700 fs-4'><Note size="35" variant="Bulk" /></span>
                    <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                        Mispunch</h4>
                </div>
                <div className="bg-white py-3 px-5 d-flex gap-5 flex-column rounded-tb shadow">
                    <div className="">
                        <div className='d-flex align-items-center justify-content-end pb-4'>
                            <Mispunch update={setUpdateMispunch} />
                        </div>
                        {post[0] ? <Table columns={columns} data={post} /> : <EmptyTable />}
                    </div>
                </div>
            </section>

        </>

    )
}

export default Mispunchdetails