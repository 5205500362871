import React, { useEffect, useState, useRef, useMemo } from "react";
import { NavLink, useLinkClickHandler } from "react-router-dom";
import userImg from "../../../image/user-demo.jpeg";
import ProfileModal from "../../ProfileModal";
import { environment } from "../../../env";
import "../../../css/sidebar.css";
import SidebarApi from "./SidebarApi";
import SidebarApi2 from "./SidebarApi2";
import SidebarMenu from "./SidebarMenu";
import {
  Calendar1,
  LogoutCurve,
  MessageNotif,
  TaskSquare,
} from "iconsax-react";
import TaskCard from "../Navbar/TaskCard";
import NotifyCard from "../Navbar/NotifyCard";
import AnimatedNumbers from "react-animated-numbers";
import "react-day-picker/dist/style.css";
import CalendarCard from "../Navbar/CalendarCard";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import swal from "sweetalert";
import { getBreakLogData, loginAction, userAttendanceAction, userDataAction } from "../../../userRedux/actions";
import { ReminderAction } from "./../../../userRedux/actions/index";
import { resetState } from "sweetalert/typings/modules/state";
import Logout from "./LogoutModel";
import logo from '../../../image/favicon-insta-courier-png.png'

interface HolidayData {
  name: string;
  day: string;
  date: string;
}

const SideBar = ({ children }: any) => {
  const user = useSelector((state: any) => state?.root?.reducers?.userData);
  const userId = user?.employee_id;
  const breaklog = useSelector((state: any) => state?.root?.reducers?.breaklog);
  const [meetingBox, setMeetingBox] = useState(false);
  const [breakmodal, setBreakModal] = useState(false);
  const [NotifyBox, setNotifyBox] = useState(false);
  const [CalendarBox, setCalendarBox] = useState(false);
  const [LogoutUser, setLogoutUser] = useState(false);
  const [holidayData, setHolidayData] = useState<HolidayData[]>([]);
  console.log('holidayData', holidayData);
  const [value, setValue] = useState(false);
  const month = moment().format("MM");
  const year = moment().format("YYYY");
  const tasks = useSelector((state: any) => state?.root?.reducers?.tasks);

  var today = new Date();
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const useratt = useSelector(
    (state: any) => state?.root?.reducers?.userAttendance
  );

  const dispatch = useDispatch();

  useEffect(() => {
    EmployeeAttendance(useratt[0]?.employee_id)
    getuserData(useratt[0]?.employee_id)
    const handleClickOutside = (event: any) => {
      setCalendarBox(false);
      setNotifyBox(false);
      setMeetingBox(false);
      setLogoutUser(false);
    };
    if (useratt[0].mispunch == 'True') {
      if (String(new Date().getDate()) == moment(useratt[1]?.date).format('D') && useratt[1]?.logout_time == 'null') {
        if (useratt[1].login_time == 'null' && useratt[1]?.logout_time == 'null') { setValue(false) }
        else {
          if (breaklog[0]?.break_out_time == "null") {
            setValue(false)
          } else {
            setValue(true)
          }
        }
      } else {
        setValue(false);
      }
    } else {
      if (String(new Date().getDate()) == moment(useratt[0]?.date).format('D') && useratt[0]?.logout_time == 'null') {
        if (useratt[0]?.login_time == 'null' && useratt[0]?.logout_time == 'null') { setValue(false) }
        else {
          if (breaklog[0]?.break_out_time == "null") {
            setValue(false)
          } else {
            setValue(true)
          }
        }
      } else {
        setValue(false);
      }
    }
    if (
      String(new Date().getDate()) == moment(useratt[0]?.date).format("D") &&
      useratt[0]?.logout_time == "null"
    ) {
      if (useratt[0]?.login_time == null && useratt[0]?.logout_time == "null") {
        setValue(false);
      } else {
        if (breaklog[0]?.break_out_time == "null") {
          setValue(false)
        } else {
          setValue(true)
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getuserData = async (id: string) => {
    await axios
      .get(`${environment.baseUrl}user-details/${id}`)
      .then((res) => {
        dispatch(userDataAction(res.data));
      })
      .catch((res) => console.log("error", res));
  };

  const getbreaklog = async (id: string) => {
    await axios
      .get(`${environment.baseUrl}break-by-user/${id}`)
      .then((res) => {
        dispatch(getBreakLogData(res.data));
      })
      .catch((res) => console.log("error", res));
  };

  const logoutTime = async () => {
    const userdata = {
      employee_id: useratt[0].employee_id,
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
    try {
      const timeout = await axios
        .post(`${environment.baseUrl}logout-time/`, userdata)
        .then((res) => {
          if (res.status == 200) {
            (willDelete: any) => {
              if (willDelete) {
                checkLeave();
              } else {
                swal("Not Logged Off successfully!");
              }
            };
          }
        });
    } catch (err) {
      console.log("err");
    }
  };
  const checkLeave = async () => {
    const userdata = {
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
    try {
      const timeout = await axios.post(
        `${environment.baseUrl}testingdate/${user.employee_id}`,
        userdata
      );
      EmployeeAttendance(useratt[0].employee_id);
      localStorage.removeItem("employee_id");
    } catch (err) {
      console.log("err");
    }
  };

  // const handleLocationClick = async () => {
  //   /* Gets Location */
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(async function (position) {
  //       var latitude = position.coords.latitude;
  //       var longitude = position.coords.longitude;
  //       console.log("Position", "latitude", latitude, "longitude", longitude)
  //       try {
  //         // Making a request to Nominatim reverse geocoding API
  //         const locationResponse = await axios.get(
  //           `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
  //         );
  //         var locationData = locationResponse.data;
  //         var areaName = locationResponse.data.display_name
  //       } catch (error) {
  //         console.log("Error: " + error);
  //       }

  //       /* Gets IP */
  //       try {
  //         // Making a request to get the IP address
  //         const ipResponse = await axios.get('https://api.ipify.org/?format=json');
  //         login(areaName, ipResponse.data.ip);
  //       } catch (error) {
  //         console.log("Error: " + error);
  //       }
  //     });
  //   } else {
  //     console.log("Geolocation is not supported by this browser.");
  //   }
  // }



  // const login = async (address,ip) => {

  const login = async () => {
    setValue(true);
    const res = await axios.get("https://api.ipify.org/?format=json");
    const userdata = {
      employee_id: user?.employee_id,
      user: user.id,
      ip_address: `${res.data.ip}`,
      month: moment(new Date()).format("YYYY-MM"),
    };
    try {
      const res = await axios.post(
        `${environment.baseUrl}employee-attendance/`,
        userdata
      );
      EmployeeAttendance(useratt[0].employee_id);
    } catch (err) {
      console.log("err");
    }
  };

  const Break = async (data: any) => {
    const userdata = {
      break_out_time: moment(new Date()).format()
    };
    try {
      await axios
        .put(`${environment.baseUrl}edit-break/${data}`, userdata)
        .then((res) => {
          if (res.data.code == 2000) {
            swal({
              title: "Success",
              text: 'LoggedIN again Successfully!',
              icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
            })
            getbreaklog(user.id)
            setValue(true)
          } else {
            swal("Break Not Applied");
          }
        });
    } catch (err) {
      console.log("err");
    }
  }

  const twoFunctions = () => {
    if (useratt[0]?.logout_time === "null") {
      var duratio = moment.duration(
        new Date().getTime() - new Date(useratt[0].login_time).getTime()
      );
      var hours = duratio.hours();
      swal({
        title: hours >= 10 ? "Achieved" : "Warning",
        text: `You Worked for,\n ${duratio.hours()} hours & ${duratio.minutes()} minutes ${duratio.seconds()} seconds\n Do you want to End yor Session`,
        icon:
          hours >= 10
            ? "https://i.giphy.com/media/QUlucMCL0wHuSRrQND/giphy.webp"
            : "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
        buttons: ["No", "Yes"],
        dangerMode: true,
      })
        .then((willDelete: any) => {
          if (willDelete) {
            logoutTime();
            checkLeave();
            setValue(false);
            dispatch(ReminderAction(false))
          }
        });
    } else if (useratt[0]?.mispunch == "True") {
      if (useratt[1]?.mispunch == "True") {
        var duratio = moment.duration(new Date().getTime() - new Date(useratt[2].login_time).getTime());
        var hours = duratio.hours();
        swal({
          title: hours >= 10 ? "Achieved" : "Warning",
          text: `You Worked for,\n ${duratio.hours()} hours & ${duratio.minutes()} minutes ${duratio.seconds()} seconds\n Do you want to End yor Session`,
          icon: hours >= 10 ?
            "https://i.giphy.com/media/QUlucMCL0wHuSRrQND/giphy.webp" :
            "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
          buttons: ["No", "Yes"],
          dangerMode: true,
        })
          .then((willDelete: any) => {
            if (willDelete) {
              logoutTime();
              checkLeave();
              setValue(false);
              dispatch(ReminderAction(false))
            }
          });
      } else {
        var duratio = moment.duration(new Date().getTime() - new Date(useratt[1].login_time).getTime());
        var hours = duratio.hours();
        swal({
          title: hours >= 10 ? "Achieved" : "Warning",
          text: `You Worked for,\n ${duratio.hours()} hours & ${duratio.minutes()} minutes ${duratio.seconds()} seconds\n Do you want to End yor Session`,
          icon: hours >= 10 ?
            "https://i.giphy.com/media/QUlucMCL0wHuSRrQND/giphy.webp" :
            "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
          buttons: ["No", "Yes"],
          dangerMode: true,
        })
          .then((willDelete: any) => {
            if (willDelete) {
              logoutTime();
              checkLeave();
              setValue(false);
              dispatch(ReminderAction(false))
            }
          });
      }
    }
  };

  const EmployeeAttendance = async (id: string) => {
    var URL = "";
    if (moment(today).format("DD") == "01") {
      var monthe = Number(month) - 1;
      URL = `${environment.baseUrl
        }attendance-filter?employee_id=${id}&date_after=${year}-${monthe}-01&date_before=${year}-${month}-${moment(
          lastDayOfMonth
        ).format("DD")}`;
    } else {
      URL = `${environment.baseUrl
        }attendance-filter?employee_id=${id}&date_after=${year}-${month}-01&date_before=${year}-${month}-${moment(
          lastDayOfMonth
        ).format("DD")}`;
    }
    await axios
      .get(URL)
      .then((res) => {
        if (res.data.length != 0) {
          dispatch(userAttendanceAction(res.data));
          if (res.data[0].date == today && res.data[0].login_time == null) {
            swal("Please Start your Session!");
          }
        } else {
          dispatch(
            userAttendanceAction([
              {
                date: `2023-${month}-01`,
                employee_id: `${id}`,
                day: `2023-${month}-01`,
                login_time: `null`,
                logout_time: `null`,
                comment: "",
                remark: "Please Start your session",
                mispunch: "False",
                show: "False",
                ip_address: "False",
              },
            ])
          );
        }
      })
      .catch((res) => console.log("error"));
  };

  const nLogout = () => {
    swal({
      title: "Warning",
      icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
      text: "Would you like to Logout",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((willDelete: any) => {
      if (willDelete) {
        dispatch(loginAction(false));
        dispatch(ReminderAction(true));
        window.location.href = "/";
      }
    });
  };

  const ClickHandler = (e: any) => {
    if (
      moment(useratt[0]?.date).format("YYYY-MM-DD") !=
      moment(today).format("YYYY-MM-DD")
    ) {
      console.log("asdsadsasda", typeof (useratt[0]?.login_time))
      if (useratt[0]?.login_time == 'null' ||
        moment(useratt[0]?.login_time).format().split("T")[0] !=
        moment().format("YYYY-MM-DD")
      ) {
        if (e.target.checked) {
          swal({
            title: "Alert",
            text: `Do You Want To Start your Session`,
            icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
            buttons: ["No", "Yes"],
            dangerMode: true,
          }).then((willDelete: any) => {
            if (willDelete) {
              login();
              const upcomingEvent = holidayData.find(event => moment(event.date).isAfter(moment()));
              if (upcomingEvent) {
                swal({
                  title: "Upcoming Event",
                  text: `Event Name: ${upcomingEvent.name}\nEvent Date: ${moment(upcomingEvent.date).format('YYYY-MM-DD')}`,
                  icon: "info",
                  // buttons: 'OK',
                })
              }
            } else {
              e.target.checked = false;
              setValue(false);
              swal({
                text: `Not Logging IN`,
                icon: "https://media0.giphy.com/media/1oaDRmHNYuzlw4LsUC/giphy.gif",
                dangerMode: true,
              });
            }
          });
        }
      } else {
      }
    } else {
      if (moment(breaklog[0]?.date).format("YYYY-MM-DD") == moment(today).format("YYYY-MM-DD")) {
        if (breaklog[0]?.break_out_time == "null") {
          Break(breaklog[0]?.id);
          setValue(true)
          return
        }
      }
      if (useratt[0]?.remark == "Absent") {
        swal({
          text: "You have been marked Absent for Today",
          icon: "https://i.giphy.com/media/sxBoB0ZEqL6ImQYYCS/giphy.webp",
        });
        return;
      }
      if (useratt[0]?.logout_time == 'null' || useratt[0].mispunch == "True") {
        setBreakModal(true)
      }
      else {
        if (useratt[0]?.remark == "Registered") {
          login();
        } else {
          swal(
            {
              text: "Your Session already ended for Today",
              // icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
              icon: 'https://i.giphy.com/media/sxBoB0ZEqL6ImQYYCS/giphy.webp'
            }
          )
        }
      }
    }
  }

  // const holidayUrl = `${environment.baseUrl}/holiday`;
  const fetchHoliday = () => {
    axios.get(`${environment.baseUrl}holiday/`)
      .then(res => {
        setHolidayData(res.data)
      })
      .catch(err => console.log("error", err));
  };

  useEffect(() => {
    fetchHoliday();
  }, [])

  useMemo(() => {

  }, [breaklog, useratt])

  return (
    <>
      <div className="">
        <div className="main d-flex align-items-center">
          <div className="menu-sidebar">
            <div className="dashboard-box d-flex align-items-center justify-content-center py-4 border-bottom ">
              <div
                className=""
                onClick={() => {
                  EmployeeAttendance(useratt[0].employee_id);
                  getbreaklog(user.id)
                }}
              >
                <img
                  className="img-fit w-75"
                  alt=""
                  src={logo}
                // style={{ cursor: "pointer" }}
                />
              </div>
            </div>
            <div className="user-box d-flex justify-content-center align-items-center gap-3 py-4 flex-column">
              <div
                className={`${value ? " user-image-border" : "user-image "}`}
              >
                <div className="userBox">
                  <img
                    src={
                      user?.profile_pic != 'null'
                        ? `${user?.profile_pic}`
                        : 'https://instalogisticts-media-techiebears.s3.ap-south-1.amazonaws.com/default.jpg'
                    }
                    alt="user-img"
                    className="user-image-fit"
                  />
                </div>
              </div>
              <div className="text-center">
                <h4 className="base-font-muli fw-bold text-nowrap text-capitalize fs-5 mb-0 text-base-color">
                  {user?.firstname} {user?.lastname}
                </h4>
                <label className="base-font-500 base-font-pop  text-nowrap text-capitalize mb-0">
                  Software Developer
                </label>
                <div className={`${value ? "lines-border " : "lines"}`}></div>
              </div>
            </div>
            <div className="d-flex flex-column gap-2 overflow-scroll h-100 sidebar-tabs">
              {
                user?.team_lead != '-1'
                  ? SidebarApi.map((curElm, i: any) => {
                    if (curElm.childs) {
                      return <SidebarMenu key={i} curElm={curElm} />;
                    }
                    return (
                      <NavLink to={curElm.link} key={i} className="sidebar-link">
                        <span className="d-flex gap-2 align-items-center">
                          <span>{curElm.icon}</span>
                          <h5 className="mb-0 title-font-700 fs-5 base-font-muli fw-bold">
                            {curElm.title}
                          </h5>
                        </span>
                      </NavLink>
                    );
                  })
                  : SidebarApi2.map((curElm, i: any) => {
                    if (curElm.childs) {
                      return <SidebarMenu key={i} curElm={curElm} />;
                    }
                    return (
                      <NavLink to={curElm.link} key={i} className="sidebar-link">
                        <span className="d-flex gap-2 align-items-center">
                          <span>{curElm.icon}</span>
                          <h5 className="mb-0 title-font-700 fs-5 base-font-muli fw-bold">
                            {curElm.title}
                          </h5>
                        </span>
                      </NavLink>
                    );
                  })
              }
            </div>
          </div>
          <section className="main-section">
            <div className="navbars-main position-relative">
              <div className="navbars">
                <div className="navbar-card d-flex">
                  <div
                    className="py-1"
                    title={value ? "Session LogOut" : "Session LogIn"}
                  >
                    <label className="switchbtn m-0 ">
                      <input
                        type="checkbox"
                        checked={value}
                        onChange={ClickHandler}
                      />
                      <span className="slider"></span>
                    </label>
                    <Logout checked={breakmodal} break={Break} tog={setValue} change={setBreakModal} log={twoFunctions} />
                  </div>
                  <div
                    className={`${!CalendarBox
                      ? "navbar-box-active-cal "
                      : "navbar-box text-primary"
                      }`}
                    title="Calendar"
                    onClick={() => {
                      setCalendarBox(!CalendarBox);
                      setNotifyBox(false);
                      setMeetingBox(false);
                      setLogoutUser(false);
                    }}
                  >
                    <Calendar1 variant="Outline" className="fs-2" />
                  </div>
                  {CalendarBox && <CalendarCard />}
                  <div
                    className={`${!NotifyBox
                      ? "navbar-box-active-notify "
                      : "navbar-box text-info"
                      }`}
                    title="Notification"
                    onClick={() => {
                      setCalendarBox(false);
                      setNotifyBox(!NotifyBox);
                      setMeetingBox(false);
                      setLogoutUser(false);
                    }}
                  >
                    <MessageNotif className="fs-2" variant="Outline" />
                  </div>
                  {NotifyBox && <NotifyCard />}

                  <div
                    className={`${!meetingBox
                      ? "navbar-box-active position-relative"
                      : "navbar-box text-warning position-relative"
                      }`}
                    title="Task"
                    onClick={() => {
                      setCalendarBox(false);
                      setNotifyBox(false);
                      setMeetingBox(!meetingBox);
                      setLogoutUser(false);
                    }}
                  >
                    <div className="redbox">
                      <p className="mb-0 base-font-600 m-1">
                        <AnimatedNumbers
                          animateToNumber={Number(tasks?.filter((task: any) => task?.status != "completed" && task.status != "denied")?.length)}
                          configs={(number, index) => {
                            return {
                              mass: 1,
                              tension: 230 * (index + 1),
                              friction: 150,
                            };
                          }}
                        /></p>
                    </div>
                    <TaskSquare className="fs-2" variant="Outline" />
                  </div>
                  {meetingBox && <TaskCard tasks={tasks} />}
                  <div
                    className={`${!LogoutUser
                      ? "navbar-box-active-logout "
                      : "navbar-box text-danger"
                      }`}
                    title="logout"
                    onClick={() => {
                      setCalendarBox(false);
                      setNotifyBox(false);
                      setMeetingBox(false);
                      setLogoutUser(!LogoutUser);
                    }}
                  >
                    <LogoutCurve
                      onClick={() => {
                        nLogout();
                      }}
                      className="fs-2"
                      variant="Outline"
                    />
                    {/* <Logout /> */}
                  </div>
                </div>
              </div>
            </div>
            <main className="wrapper-main">{children}</main>
          </section>
        </div>
      </div>
    </>
  );
};

export default SideBar;
