import React from 'react'
import Table from '../Table/Table';
import DataTable, { TableColumn } from 'react-data-table-component';

interface DataRow {
  id: number;
  date: any;
  day: string;
  mispunch: string;
  galaNo: any;
  title: string;
  status: any;
}
const EmptyTable = () => {
  
  const columns: TableColumn<DataRow>[] = [
    {
      name: '',
      selector: row => row.status,
      sortable: true,
      style: {
        fontSize: "2rem",
      }
    },
    {
        name: '',
        selector: row => row.title,
        sortable: true,
        style: {
          fontSize: "2rem",
        }
      },
    {
      name: '',
      selector: row => row.galaNo,
      sortable: true,
      style: {
        fontSize: "4rem",
      }
    },
    {
      name: '',
      selector: row => row.title,
      sortable: true,
      style: {
        fontSize: "2rem",
      }
    },
    {
      name: '',
      selector: row => row.title,
      sortable: true,
      style: {
        fontSize: "2rem",
      }
    },
    
  ];

  const data = [
    {
      id: 1,
      galaNo: 'No Data To Display !',
    },

  ]
  return (
    <>
            <Table columns={columns} data={data} />
    </>
  )
}

export default EmptyTable