import { BREAKLOG, HOLIDAYS, LEADLEAVES, LOGIN, PROJECT, REMINDER, TASKS, TEAMMEMBER, TEAMS, USERATTENDANCE, USERDATA } from "../actions/types";

const initialState = {
  loggedIn: false,
  userData: undefined,
  userAttendance: undefined,
  holidays: undefined,
  reminder: true,
  projects: undefined,
  teams: undefined,
  tasks: [],
  teamMembers: undefined,
  breaklog: [],
  leadleaves:[]
};
const reducers = (state = initialState, action: any) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loggedIn: action.data
      }
    case USERDATA:
      return {
        ...state,
        userData: action.data
      }
    case USERATTENDANCE:
      return {
        ...state,
        userAttendance: action.data
      }
    case HOLIDAYS:
      return {
        ...state,
        holidays: action.data
      }
    case REMINDER:
      return {
        ...state,
        reminder: action.data
      }
    case PROJECT:
      return {
        ...state,
        projects: action.data
      }
    case TEAMS:
      return {
        ...state,
        teams: action.data
      }
    case TASKS:
      return {
        ...state,
        tasks: action.data
      }
    case TEAMMEMBER:
      return {
        ...state,
        teamMembers: action.data
      }
    case BREAKLOG:
      return {
        ...state,
        breaklog: action.data
      }
    case LEADLEAVES:
      return {
        ...state,
        leadleaves: action.data
      }
    default:

      return state;
  }

}

export default reducers






