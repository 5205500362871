import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import axios from 'axios';
import { environment } from '../../../env';
import { Link } from 'react-router-dom';
import SalarySlip from './SalarySlip';
import Table from '../../Table/Table';
import EmptyTable from '../../errorComponents/EmptyTable';
import { ReceiptItem } from 'iconsax-react';
import moment from 'moment';

interface UploadDocumentData {
    employee_id: any,
    doc_info: any,
    created_date: any,
    file: any
}
interface DataRow {
    id: any;
    payslip_month: string;
}
const UserSalarySlipTable = () => {
    const userid = localStorage.getItem('userid')
    const [salaryslip, setSalarySlip] = useState<UploadDocumentData[]>([])
    const [update, setUpdate] = useState(false);

    const GetUploadDocument = async () => {
        await axios.get(`${environment.baseUrl}get-salaryslip/${userid}`).then(res => {
            setSalarySlip(res.data)
        })
            .catch(res => console.log("error"))
    };

    const ExamplePDFViewer = (file: any) => {
        window.open(`${file}`);
    };

    useEffect(() => {
        GetUploadDocument()
    }, [update])

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'ID',
            selector: row => row?.id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Month',
            selector: row => moment(row?.payslip_month).format('MMMM YYYY'),
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'View File',
            selector: row => <Link  to='#'><SalarySlip id={userid}/></Link>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }
    ];

    const data=[{
        id: 1,
        payslip_month: "Example",
    }]
   

    return (
        < >

<section className="main-wrapper-section px-4 pb-5 ">
                <div className="dashboard-heading pb-3  mb-4  px-0 d-flex align-items-center">
                    <span className='base-text-color base-font-700 fs-4'><ReceiptItem size="35" variant="Bulk" /></span>
                    <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                    SalarySlip</h4>
                </div>
                <div className="bg-white py-3 px-5 d-flex gap-5 flex-column rounded-tb shadow">
                    <div className="">
                        <div className='d-flex align-items-center justify-content-end pb-3'>
                        </div>
                        {salaryslip[0] ? <Table columns={columns} data={salaryslip} />:
                        <EmptyTable/>}
                    </div>
                </div>
            </section>
        </>
    )
}

export default UserSalarySlipTable



