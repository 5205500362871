import React from 'react'

const NotifyCard = () => {
  return (
    <div className="meeting-box-main py-3 rounded-5 shadow" >
    <div className="Todo-lists-main p-2 px-1 pb-0">
    <div className="meeting-main-box pb-2 px-3 gap-2 d-flex flex-column">
        <div className="meeting-box  rounded-4 px-4 py-3">
            <div className="d-flex align-items-center pb-2">
            <h5 className='fs-5 base-font-600 text-base-color mb-0'>
                Meeting's</h5>
            </div>
            <h5 className='fs-5 mb-0 text-info base-font-pop'>Coming soon... 🤷🏻‍♂️</h5>
        </div>
        {/* <div className="meeting-box  rounded-4 px-4 py-3">
            <div className="d-flex align-items-center pb-2">
            <i className="bx bx-radio-circle-marked bx-flashing fs-4 text-base-green"></i>
            <h5 className='fs-5 base-font-600 text-base-color mb-0'>
                Board Meeting</h5>
            </div>
            <h5 className='fs-5 mb-0 text-info base-font-pop'>feb 22 at 6:00</h5>
            <p className='fs-6 todo-text-para mb-0 mb-0'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo, tempora?</p>
        </div>
        <div className="meeting-box  rounded-2 px-4 py-3">
            <div className="d-flex align-items-center pb-2">
            <i className="bx bx-radio-circle-marked bx-flashing fs-4 text-base-green"></i>
            <h5 className='fs-5 base-font-600 text-base-color mb-0'>
                Board Meeting</h5>
            </div>
            <h5 className='fs-5 mb-0 text-info base-font-pop'>feb 22 at 6:00</h5>
            <p className='fs-6 todo-text-para mb-0 mb-0'>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo, tempora?</p>
        </div> */}
    </div>
    </div>
    </div>
  )
}

export default NotifyCard