import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import UserLogin from "./components/auth/UserLogin";
import SideBar from "./components/Layout/sidebar/SideBar";
import UserAttendance from "./components/pages/UserAttendance";
import ProfileModal from "./components/ProfileModal";
import Mispunchdetails from "./components/UserMispunch";
import UserBreakLog from "./components/BreakLog";
import JoiningLetter from "./components/pages/joiningletter";
import UserDocuments from "./components/pages/udocuments";
import LeavePage from "./components/pages/Leave/LeavePage";
import AddBankDetails from "./components/pages/Basic_Details/AddBankDetails";
import AddExperience from "./components/pages/Basic_Details/AddExperience";
import Holidays from "./components/pages/Leave/holidays";
import { useSelector } from "react-redux";
import DashBoard from "./components/Dashboard";
import RewardsPage from "./components/Rewards";
import DetailsNavigation from "./components/pages/Basic_Details/DetailsNavigation";
import EditBasicDetails from "./components/pages/Basic_Details/EditBasicDetails";
import AddFamilyDetails from "./components/pages/Basic_Details/AddFamilyDetails";
import UserSalarySlipTable from "./components/pages/UserSalarySlip/UserSalarySlipTable";
import Project from "./components/pages/Project/Project";
import ErrorPage from "./components/pages/Error/ErrorPage";
import Tasks from "./components/pages/Task/Task";
import TaskDetails from "./components/pages/Task/TaskDetails";
import ScrollToTop from "./helper/ScrollToTop";
import SubTaskDetails from "./components/pages/Task/SubTaskDetails";
import TeamAttendance from "./components/pages/TeamLead/TeamAttendance";
import TeamLeaves from "./components/pages/TeamLead/TeamLeaves";
import TeamMembers from "./components/pages/TeamLead/TeamMembers";

function App(props: any) {
  const Logged = useSelector((state: any) => state?.root?.reducers?.loggedIn);
  const [lodingSpinner, setLodingSpinner] = useState(false);
  const spinner = document.getElementById("lodingSpinner");

  if (spinner) {
    setTimeout(() => {
      spinner.style.display = "none";
      setLodingSpinner(true);
    }, 1500);
  }

  return (
    <>
      {lodingSpinner && (
        <>
          {Logged ? (
            <SideBar>
              <ScrollToTop>
                <Routes>
                  <Route path="/" element={<DashBoard />} />
                  <Route path="/attendance" element={<UserAttendance />} />
                  <Route path="/profilemodal" element={<ProfileModal />} />
                  <Route path="/usermispunch" element={<Mispunchdetails />} />
                  <Route path="/userbreaklog" element={<UserBreakLog />} />
                  <Route path="/projects" element={<Project />} />
                  <Route path="/task" element={<Tasks />} />
                  <Route path="/taskdetails" element={<TaskDetails />} />
                  <Route path="/joiningletter" element={<JoiningLetter />} />
                  <Route
                    path="/basicdetails/editbasicdetails"
                    element={<EditBasicDetails />}
                  />
                  <Route
                    path="/basicdetails/addexperience"
                    element={<AddExperience />}
                  />
                  <Route
                    path="/basicdetails/editfamily"
                    element={<AddFamilyDetails />}
                  />
                  <Route
                    path="/basicdetails/editbankdetails"
                    element={<AddBankDetails />}
                  />
                  <Route path="/basicdetails" element={<DetailsNavigation />} />
                  <Route path="/userdocument" element={<UserDocuments />} />
                  <Route path="leave" element={<LeavePage />} />
                  <Route path="holidays" element={<Holidays />} />
                  <Route path="dashboard" element={<DashBoard />} />
                  <Route path="rewards" element={<RewardsPage />} />
                  <Route path="/teamMembers" element={<TeamMembers />} />
                  <Route
                    path="usersalarysliptable"
                    element={<UserSalarySlipTable />}
                  />
                  <Route path="tasks/:id" element={<Tasks />} />
                  <Route path="taskDetails" element={<TaskDetails />} />
                  <Route path="subTaskDetails" element={<SubTaskDetails />} />
                  <Route path="teamAttendance" element={<TeamAttendance />} />
                  <Route path="teamLeaves" element={<TeamLeaves />} />
                  <Route path="*" element={<ErrorPage />} />
                </Routes>
              </ScrollToTop>
            </SideBar>
          ) : (
            <Routes>
              <Route path="/" element={<UserLogin />} />
            </Routes>
          )}
        </>
      )}
    </>
  );
}
export default App;
