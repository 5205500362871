import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import { useForm } from "react-hook-form";
import axios from "axios";
import swal from "sweetalert";
import { Button, Input } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { BsFillCameraFill } from "react-icons/bs";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "../../../css/BasicDetails.css";
import { environment } from "../../../env";
import defaultimage from "../../../image/user-demo.jpeg";

const EditBasicDetails = () => {
  const [upload, setUpload] = useState<any>();
  const [contect, setContect] = useState<any>();
  const [emgcontectno, setEmgContectNo] = useState<any>();

  const [userDetail, setUserDetail] = useState({
    firstname: "",
    lastname: "",
    email_id: "",
    address: "",
    gender: "",
    city: "",
    state: "",
    country: "",
    phone_no: "",
    profile_pic: "",
    personal_email: " ",
    pan: " ",
    aadhar: " ",
    emg_contact_name: " ",
    relationship_with_emg_contact: " ",
    emg_contact_number: " ",
    reporting_manager: " ",
    check_in_time: " ",
    check_out_time: " ",
    blood_group: " ",
    date_of_birth: " ",
    department: " ",
    designation: " ",
    date_of_joining: " ",
    probation_period: " ",
    notice_period: " ",
  });

  let EmpId = localStorage.getItem("employee_id");
  let userId = localStorage.getItem("userid");

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm();

  const imageUploader = React.useRef(null);
  const uploadedImage = React.useRef(null);
  const user = localStorage.getItem("LoggedUserDetails");

  const handleOnChange = (event: any) => {
    setUserDetail({
      ...userDetail,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageChange = (e: any) => {
    const fileList = e.target.files;
    setUpload(fileList[0]);
  };

  const UserPersonalDetails = async () => {
    try {
      const result = await axios(`${environment.baseUrl}user-details/${EmpId}`)
        .then((res) => {
          if (res.data.personal_email === "null") {
            res.data.personal_email = "";
          }
          if (res.data.pan === "null") {
            res.data.pan = "";
          }
          if (res.data.aadhar === "null") {
            res.data.aadhar = "";
          }
          if (res.data.emg_contact_name === "null") {
            res.data.emg_contact_name = "";
          }
          if (res.data.emg_contact_number === "null") {
            res.data.emg_contact_number = "";
          }
          if (res.data.blood_group === "null") {
            res.data.blood_group = "";
          }
          if (res.data.date_of_birth === "null") {
            res.data.date_of_birth = "";
          }
          if (res.data.relationship_with_emg_contact === "null") {
            res.data.relationship_with_emg_contact = "";
          }
          if (res.data.address === "null") {
            res.data.address = "";
          }
          if (res.data.country === "null") {
            res.data.country = "";
          }
          if (res.data.state === "null") {
            res.data.state = "";
          }
          if (res.data.city === "null") {
            res.data.city = "";
          }
          if (res.data.phone_no === "null") {
            res.data.phone_no = "";
          }
          setUserDetail(res.data);
          setEmgContectNo(res.data.emg_contact_number);
          setContect(res.data.phone_no);
          localStorage.setItem("LoggedUserDetails", JSON.stringify(res.data));
          reset(res.data);
        })
        .catch((error) => {
          console.log("Error", error);
        });
    } catch (err) {
      console.log("Error", err);
    }
  };

  useEffect(() => {
    UserPersonalDetails();
  }, []);

  const ProfilePostMethod = async () => {
    let form_data = new FormData();
    form_data.append("personal_email", userDetail.personal_email);
    form_data.append("pan", userDetail.pan);
    form_data.append("aadhar", userDetail.aadhar);
    form_data.append("blood_group", userDetail.blood_group);
    form_data.append("gender", userDetail.gender);
    form_data.append("date_of_birth", userDetail.date_of_birth);
    form_data.append("emg_contact_name", userDetail.emg_contact_name);
    form_data.append(
      "relationship_with_emg_contact",
      userDetail.relationship_with_emg_contact
    );
    form_data.append("emg_contact_number", emgcontectno);
    form_data.append("phone_no", contect);
    if (upload && upload.name) {
      form_data.append("profile_pic", upload, upload.name);
    }
    try {
      const res = await axios.put(
        `${environment.baseUrl}update-basic-details/${EmpId}`,
        form_data
      );
      return res.data;
    } catch (err) {
      console.log("err");
      return err;
    }
    return;
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    ProfilePostMethod().then((profileResponse) => {
      if (profileResponse.status === "success") {
        swal({
          title: "Success",
          text: "Profile is updated Successfully !",
          icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
        }).then(() => {
          UserPersonalDetails();
          navigate(-1);
        });
      }
    });
  };

  return (
    <>
      <Card
        style={{
          background: "white",
          width: "95%",
          marginLeft: "36px",
          height: "80%",
        }}
        className="mt-5"
      >
        <div>
          <div className="row p-2">
            <div className="row col-9 order-last">
              <form id="details" onSubmit={(e) => onSubmit(e)}>
                <div className="row my-5 py-4" id="main">
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        First Name
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.firstname}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Last Name
                      </p>
                      <label className="border-1 w-100">
                        {userDetail.lastname}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Personal Email
                      </p>
                      <Input
                        type="text"
                        id="pemail"
                        name="personal_email"
                        value={userDetail.personal_email}
                        onChange={handleOnChange}
                        placeholder="Personal Email"
                        style={{
                          paddingLeft: "1rem",
                          fontSize: 14,
                          width: "100%",
                          height: "1.7rem",
                          fontWeight: 700,
                        }}
                      // style={{ paddingLeft: '1rem', fontSize: 14, width: '100%', height: '1.7rem' }}
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Contact Number
                      </p>
                      <PhoneInput
                        className="form-control"
                        type="tel"
                        maxLength={12}
                        defaultCountry="IN"
                        value={contect}
                        onChange={setContect}
                        placeholder="Contact Number"
                        style={{
                          fontSize: 14,
                          width: "100%",
                          height: "1.7rem",
                        }}
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Pan Number
                      </p>
                      <Input
                        className="BD-ED-inputs"
                        type="text"
                        id="pancard_number"
                        name="pan"
                        maxLength={10}
                        value={userDetail.pan}
                        onChange={handleOnChange}
                        placeholder="Pan Card Number"
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p
                        className="m-0 BD-labels-head base-font-600"
                        style={{ fontSize: 14, fontWeight: 600 }}
                      >
                        Aadhar Number
                      </p>
                      <Input
                        type="text"
                        id="Aadhar_number"
                        name="aadhar"
                        className="BD-ED-inputs"
                        maxLength={12}
                        value={userDetail.aadhar}
                        onChange={handleOnChange}
                        placeholder="Aadhar Card Number"
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600 ">
                        Blood Group
                      </p>
                      <Input
                        type="text"
                        id="blood_group"
                        name="blood_group"
                        className="BD-ED-inputs"
                        value={userDetail.blood_group}
                        onChange={handleOnChange}
                        placeholder="Blood Group"
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">Gender</p>
                      <input
                        type="radio"
                        id="male"
                        name="gender"
                        value="Male"
                        checked={userDetail.gender === "Male"}
                        onChange={handleOnChange}
                        placeholder="Example@gmail.com"
                        style={{ paddingLeft: "1rem", fontSize: 16 }}
                      />
                      {"  "}
                      Male
                      <input
                        type="radio"
                        id="female"
                        name="gender"
                        value="Female"
                        checked={userDetail.gender === "Female"}
                        onChange={handleOnChange}
                        style={{
                          marginLeft: "30px",
                          fontWeight: 300,
                        }}
                      />
                      {"  "}
                      Female
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Date Of Birth
                      </p>
                      {/* <label className="border-1 w-100" style={{ paddingTop: "2px", fontSize: 15 }}>{userDetail.date_of_birth}</label> */}
                      <Input
                        type="date"
                        id="dob"
                        name="date_of_birth"
                        value={userDetail.date_of_birth}
                        onChange={handleOnChange}
                        placeholder="Date Of Birth"
                        className="BD-ED-inputs"
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Emergency Contact Name
                      </p>
                      <Input
                        type="text"
                        id="emg_contact_name"
                        name="emg_contact_name"
                        value={userDetail.emg_contact_name}
                        onChange={handleOnChange}
                        placeholder="Emergency Contact Person"
                        className="BD-ED-inputs"
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Relation with Emergency Contact
                      </p>
                      <Input
                        type="text"
                        id="relationship_with_emg_contact"
                        name="relationship_with_emg_contact"
                        value={userDetail.relationship_with_emg_contact}
                        onChange={handleOnChange}
                        placeholder="Relation with Emergency Contact"
                        className="BD-ED-inputs"
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Emergency Contact Number
                      </p>

                      <PhoneInput
                        className="form-control BD-inputs"
                        maxLength={12}
                        defaultCountry="IN"
                        value={emgcontectno}
                        onChange={setEmgContectNo}
                        placeholder="Emergency Contact Number"
                        style={{
                          fontSize: 14,
                          width: "100%",
                          height: "1.7rem",
                        }}
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Office Email
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.email_id}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Date of Joining
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.date_of_joining}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Reporting Manager
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.reporting_manager}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Check-In Time
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.check_in_time}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Check-Out Time
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.check_out_time}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Department
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.department}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Designation
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.designation}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Probation Period
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.probation_period}
                      </label>
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <p className="m-0 BD-labels-head base-font-600">
                        Notice Period
                      </p>
                      <label
                        className="border-1 w-100"
                        style={{ paddingTop: "2px", fontSize: 15 }}
                      >
                        {userDetail.notice_period}
                      </label>
                    </div>
                    <div className="BD-submit">
                      <Button
                        color="success"
                        type="submit"
                        form="details"
                        value="Submit"
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className=" col-3 ">
              <div className="  p-0  bg-body">
                <div className="d-flex justify-content-center" style={{}}>
                  <img
                    src={
                      userDetail.profile_pic
                        ? `${userDetail.profile_pic}`
                        : `${defaultimage}`
                    }
                    className="BD-ED-profile"
                  />
                </div>

                <div
                  className="App"
                  style={{
                    position: "relative",
                    marginLeft: "230px",
                    width: "1rem",
                    bottom: "36px",
                    left: "-58px",
                    cursor: "pointer",
                    zIndex: "123",
                  }}
                >
                  <input
                    id="file-input"
                    type="file"
                    name="profile_pic"
                    ref={imageUploader}
                    accept="image/jpeg,image/png,image/gif"
                    onChange={(e) => {
                      handleImageChange(e);
                    }}
                    style={{
                      width: "2px",
                      height: 35,
                      paddingLeft: "3rem",
                      color: "transparent",
                      background: "transparent",
                    }}
                  />
                </div>
                <div className="shadow-lg Edit-profile-cam">
                  <BsFillCameraFill />
                </div>
              </div>
              <hr style={{ height: "1px", color: "black" }} />
              <div className=" " style={{ marginTop: "-45px" }}>
                <div className=" p-0 bg-body ">
                  <div className="p-2">
                    <p className="m-0 BD-labels-head base-font-600">Name</p>
                    <label
                      className="border-1 w-100"
                      style={{ paddingTop: "2px", fontSize: 15 }}
                    >
                      {userDetail.firstname} {userDetail.lastname}
                    </label>
                  </div>
                  <div className="p-2">
                    <p className="m-0 BD-labels-head base-font-600">Email</p>
                    <label
                      className="border-1 w-100"
                      style={{ paddingTop: "2px", fontSize: 15 }}
                    >
                      {userDetail.personal_email}
                    </label>
                  </div>
                  <div className="p-2">
                    <p className="m-0 BD-labels-head base-font-600">
                      Contact Number
                    </p>
                    <label
                      className="border-1 w-100"
                      style={{ paddingTop: "2px", fontSize: 15 }}
                    >
                      {userDetail.phone_no}
                    </label>
                  </div>
                  <div className="p-2">
                    <p className="m-0 BD-labels-head base-font-600">
                      Designation
                    </p>
                    <label
                      className="border-1 w-100"
                      style={{ paddingTop: "2px", fontSize: 15 }}
                    >
                      {userDetail.designation}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
export default EditBasicDetails;
