import React, { useState, useRef, useEffect, useMemo } from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import swal from 'sweetalert';
import { ReminderAction, getBreakLogData, loginAction } from '../../../userRedux/actions';
import { C } from '@fullcalendar/core/internal-common';
import { environment } from '../../../env';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Logout = (props: any) => {
    const breaklog = useSelector((state: any) => state?.root?.reducers?.breaklog);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [selectedBreakType, setSelectedBreakType] = useState('');
    const dispatch = useDispatch();

    const loginHandler = () => {
        setIsLoggedIn(!isLoggedIn);
        props.break(breaklog[0]?.id);
    }

    const {
        register,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const user = useSelector((state: any) => state?.root?.reducers?.userData);
    const useratt = useSelector((state: any) => state?.root?.reducers?.userAttendance);

    const [inputc, setInputC] = useState(false)
    const com = watch("break_type")

    const toggle = () => {
        setSelectedBreakType('');
        props.change(!props.change);
    };

    useMemo(() => {
        if (com == "Other") {
            setSelectedBreakType(com)
            setInputC(true)
        } else {
            setSelectedBreakType(com)
            setInputC(false)
        }
    }, [com])

    const getbreaklog = async (id: string) => {
        await axios
            .get(`${environment.baseUrl}break-by-user/${id}`)
            .then((res) => {
                dispatch(getBreakLogData(res.data));
            })
            .catch((res) => console.log('error', res));
    };

    const Break = async (data: any) => {
        const userdata = {
            user: user.id,
            attendance: useratt[0].id,
            break_type: data.break_type,
            remark: data.break_comment,
            date: moment(new Date()).format('YYYY-MM-DD'),
            break_in_time: moment(new Date()).format(),
        };
        try {
            await axios.post(`${environment.baseUrl}create-break/`, userdata).then((res) => {
                if (res.data.code == 2000) {
                    swal({
                        title: 'Success',
                        text: 'Break Added Successfully!',
                        icon:
                            'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                    });
                    getbreaklog(user.id);
                    props.change(false);
                    props.tog(false);
                    setIsLoggedIn(!isLoggedIn);
                } else {
                    swal('Break Not Applied');
                }
            });
        } catch (err) {
            console.log('err');
        }
    };

    const submit = (data: any) => {
        if (data.break_type == "sessionLogout") {
            props.change(false);
            props.log()
        } else {
            props.tog(false);
            props.change(false);
            swal({
                title: 'Warning',
                text: `Are You Going for a ${data.break_type} Break`,
                icon: 'https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp',
                buttons: ['No', 'Yes'],
                dangerMode: true,
            }).then((willDelete: any) => {
                if (willDelete) {
                    Break(data);
                } else {
                    props.tog(true);
                    props.tog(false);
                }
            });
        }
        reset();
    };

    return (
        <>
            {
                <Modal isOpen={isLoggedIn} size='md' centered className='modalstyle' >
                    <ModalHeader className='py-3 px-5'>
                        <span className="base-font-muli fs-4 text-base-color fw-bold"> Login Back</span>
                    </ModalHeader>
                    <ModalBody className="pt-3 px-5">
                        <div className='d-flex flex-column align-items-center' >
                           <p className='swal-title'> You are on Break </p>
                           <p className='swal-text'> Login Back </p>
                        </div>
                    </ModalBody>
                    <ModalFooter className="border-0 d-flex justify-content-center">
                        <button className="submit-btn" type="submit" onClick={loginHandler}>
                            Login
                        </button>
                    </ModalFooter>
                </Modal>
            }
            <Modal isOpen={props.checked} toggle={toggle} size="md" centered className="modalstyle">
                <ModalHeader toggle={toggle} className="py-3 px-5">
                    <span className="base-font-muli fs-4 text-base-color fw-bold"> LOGOUT</span>
                </ModalHeader>
                <Form className="d-flex justify-content gap-3 flex-column" onSubmit={handleSubmit(submit)}>
                    <ModalBody className="py-3 px-5">
                        <div className="InputFeild">
                            <Label htmlFor="break_type" className="base-font-muli fw-bold text-base-black">
                                Type:
                            </Label>
                            <select
                                id="break_type"
                                value={selectedBreakType}
                                {...register("break_type")}
                                className="border-primary p-2 rounded-2 base-font-pop fw-normal mb-3 w-100"
                            >
                                <option value="">Options...</option>
                                <option value="Lunch">Lunch Break</option>
                                <option value="Tea">Tea Break</option>
                                <option value="Other">Other Break</option>
                                <option value="sessionLogout">Session LogOut</option>
                            </select>
                        </div>
                        {
                            inputc == true &&
                            <div>
                                <Label htmlFor="break_comment"
                                    className="base-font-muli fw-bold text-base-black" >
                                    Comments :
                                </Label>
                                <textarea
                                    {...register("break_comment")}
                                    id="break_comment"
                                    className="py-1 fs-6 shadow-none form-control w-100"
                                    name="break_comment"
                                    placeholder="Type here..."
                                />
                            </div>
                        }
                    </ModalBody>
                    <ModalFooter className="border-0">
                        <button className="submit-btn" type="submit">
                            Yes
                        </button>
                        <button onClick={toggle} type="button" className="close-btn">
                            No
                        </button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export default Logout;

