import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Label,
} from 'reactstrap';
import axios from 'axios';
import swal from 'sweetalert'
import { useForm } from "react-hook-form";
import moment from 'moment';
import { environment } from '../env';
import { Message, MessageText1 } from 'iconsax-react';
import { userAttendanceAction } from '../userRedux/actions';
import { useDispatch } from 'react-redux';


const Comments = (props: any) => {
  const {
    register, handleSubmit, reset, watch,
    formState: { errors },
  } = useForm();

  const [modal, setModal] = useState(false);
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const EmpID = localStorage.getItem("employee_id")

  const toggle = () => { setModal(!modal) };
  const CancelButton = () => {
    toggle();
    reset({ "comment": undefined })
  };
  const dispatch = useDispatch();

  var comments = watch("comment")
  const EmployeeAttendance = async (id: string) => {
    await axios.get(`${environment.baseUrl}get-employee-attendance/${id}`)
      .then(res => {
        dispatch(userAttendanceAction(res.data))
      })
      .catch(res => console.log("error"));
  };

  const SubmitComment = async (data: any) => {
    const UserComment = {
      employee_id: localStorage.getItem('employee_id'),
      date: moment(new Date()).format('YYYY-MM-DD'),
      comment: data.comment
    }
    try {
      const res = await axios.post(`${environment.baseUrl}comment/`, UserComment).then(
        res => {
          if (res.status == 200) {
            toggle()
            if (EmpID) {
              EmployeeAttendance(EmpID)
            }
            swal({
              title: "Success",
              text: 'Comment Added Successfully !',
              icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
            })
          }
        }
      ).catch(res => console.log("error"))

    }
    catch (err) {
      console.log("err");
    }

  };



  return (
    <>
      <Button className={`tb-btn-comments ${props.dashboard == true && "py-1"} base-font-muli fw-bold shadow-none`} onClick={toggle} disabled={props.post !== moment(new Date()).format('YYYY-MM-DD') ? true : false}>
        <MessageText1 size="30" variant="Bulk" className='' />
      </Button>
      <Modal isOpen={modal} toggle={toggle} unmountOnClose={unmountOnClose} size="md" centered className='modalstyle'>
        <ModalHeader toggle={toggle} className="py-3 px-3"><span className='base-font-muli fs-4 text-base-color fw-bold '> Add Comment</span></ModalHeader>

        <ModalBody className=''>
          <Form
            onSubmit={handleSubmit(SubmitComment)}
          >
            <div className="InputFeild" >
              <Label htmlFor="Comment-feild" className="base-font-muli fw-bold text-base-color fs-4" >
                Comment *
              </Label>

              <textarea
                id="Comment-feild"
                className="py-1 fs-6 shadow-none border-primary  input-border-rounded px-2  form-control"
                // name="Comment-feild"
                rows={5}
                {...register("comment", { required: true })}
                placeholder="Write something..."
              ></textarea>
              {errors.comment && <h6 className='text-danger pt-2'>
                Please add comment to submit this form *
              </h6>}

            </div>
            <ModalFooter className='border-0 py-3'>
              <button type='submit' className="submit-btn" >
                Submit
              </button>
              <button type='button' onClick={CancelButton} className="close-btn">
                Close
              </button>
            </ModalFooter>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );

}

export default Comments