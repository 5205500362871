import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import UserDocuments from "../udocuments";
import Address from "./Address";
import BankDetails from "./BankDetails";
import BasicDetails from "./BasicDetails";
import Education from "./Education";
import Experience from "./Experience";
import FamilyDetails from "./FamilyDetails";
import axios from "axios";
import { environment } from "../../../env";
import "../../../css/BasicDetails.css";
import "../../../css/BasicDetailsAll.css";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { userDataAction } from "../../../userRedux/actions";
import { Personalcard, Profile2User, User } from "iconsax-react";

interface UserData {
  firstname: string;
  lastname: string;
  profile_pic: any;
}

function DetailsNavigation() {
  const [update, setUpdate] = useState(false);
  const userDetails = useSelector(
    (state: any) => state?.root?.reducers?.userData
  );
  const dispatch = useDispatch();

  const getuserData = async (id: string) => {
    await axios
      .get(`${environment.baseUrl}user-details/${id}`)
      .then((res) => {
        dispatch(userDataAction(res.data));
      })
      .catch((res) => console.log("error", res));
  };

  var fileDownload = require("js-file-download");

  const handlePDFDownload = () => {
    axios
      .get(`${environment.baseUrl}joiningletter-download/${userDetails.id}`, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, `${userDetails.firstname}-JoiningLetter.pdf`);
      })
      .catch((err) => {
        swal({
          title: "Error",
          text: "Joining Letter Not Yet Added",
          icon: "https://media2.giphy.com/media/1oaDRmHNYuzlw4LsUC/giphy.gif?cid=790b7611143e97e9257a94ee1c94260fd10462bf3f058b24&rid=giphy.gif&ct=g",
        });
      });
  };
  useEffect(() => {
    getuserData(userDetails?.employee_id);
    setUpdate(false);
  }, [update]);

  return (
    <>
      <section className="main-wrapper-section px-4 pb-5 ">
        <div className="dashboard-heading pb-3  mb-4  px-0 d-flex align-items-center">
          <span className="base-text-color base-font-700 fs-4">
            <Profile2User size="35" variant="Bulk" />
          </span>
          <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
            Basic Details
          </h4>
        </div>
        <div className="">
          <Tabs className="">
            <div className="d-flex justify-content-center mb-4">
              <TabList className="d-flex p-0 sellGalaTabsMain gap-2  py-2 rounded-2">
                <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs rounded-3">
                  Basic Details
                </Tab>
                <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs rounded-3">
                  Documents
                </Tab>
                <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs rounded-3 ">
                  Address
                </Tab>
                <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs rounded-3 ">
                  Education
                </Tab>
                <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs rounded-3 ">
                  Experience
                </Tab>
                <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs rounded-3 ">
                  Bank Details
                </Tab>
                <Tab className="sellGalaTabs fs-5 base-font-pop py-2 px-3 tb-btn-tabs rounded-3 ">
                  Family Details
                </Tab>
              </TabList>
            </div>
            <TabPanel>
              <div className="bg-white  rounded-tb shadow">
                <div className=" pb-4">
                  <BasicDetails basicdetails={userDetails} update={setUpdate} />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bg-white py-3 px-2  rounded-tb shadow">
                <div className=" pb-4">
                  <UserDocuments />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bg-white py-3 px-2  rounded-tb shadow">
                <div className=" pb-4">
                  <Address details={userDetails} update={setUpdate} />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bg-white py-3 px-2  rounded-tb shadow">
                <div className=" pb-4">
                  <Education />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bg-white py-3 px-2  rounded-tb shadow">
                <div className=" pb-4">
                  <Experience />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bg-white py-3 px-2  rounded-tb shadow">
                <div className=" pb-4">
                  <BankDetails details={userDetails} update={setUpdate} />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="bg-white py-3 px-2  rounded-tb shadow">
                <div className=" pb-4">
                  <FamilyDetails details={userDetails} />
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </section>
    </>
  );
}

export default DetailsNavigation;
