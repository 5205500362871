import React, { useEffect, useState } from 'react'
import ApplyLeaveModel from './ApplyLeaveModel'
import DataTable, { TableColumn } from 'react-data-table-component';

import axios from 'axios';
import { environment } from '../../../env';
import Table from '../../Table/Table';
import EmptyTable from '../../errorComponents/EmptyTable';
import { ReceiptEdit } from 'iconsax-react';


interface leaveData {
    employee_id: any;
    leave_type: any;
    apply_date: any;
    start_date: any;
    end_date: any;
    leave_durations: any;
    leave_reason: any;
    leave_status: any;
}
const LeavePage = () => {
    const [myleave, setMyLeave] = useState<leaveData[]>([])
    const [update, setUpdate] = useState(false);

    let EmpId = localStorage.getItem('employee_id')

    const Leaves = () => {
        axios.get(`${environment.baseUrl}get-my-leaves/${EmpId}`)
            .then(res => {
                setMyLeave(res.data);
            })
            .catch(res => console.log("error"))
    };

    useEffect(() => {
        Leaves();
        setUpdate(false);
    }, [update])

    const columns: TableColumn<leaveData>[] = [
        {
            name: 'Apply Date',
            selector: row => row.apply_date.split("T")[0],
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Start Date',
            selector: row => row.start_date.split("T")[0],
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'End Date',
            selector: row => row.end_date.split("T")[0],
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Leave Durations',
            selector: row => row.leave_durations,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Leave Reason',
            selector: row => row.leave_reason,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Leave Status',
            selector: row => <div
                className={`${row.leave_status == "Denied" ? "status-bg-red status-color-red" :
                    row.leave_status == "Pending" ? "status-bg-yellow status-color-yellow" :
                        row.leave_status == "Approved" && "status-bg-emerald status-color-emerald"
                    } w-100 py-2 px-5 rounded-tb-2 d-flex justify-content-center align-items-center`}>
                <span className="base-font-inter fw-bold fs-6 text-capitalize">{row?.leave_status}</span>
            </div>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
    ];

    return (
        < >
            <section className="main-wrapper-section px-4 pb-5 ">
                <div className="dashboard-heading pb-3  mb-4  px-0 d-flex align-items-center">
                    <span className='base-text-color base-font-700 fs-4'><ReceiptEdit size="35" variant="Bulk" /></span>
                    <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                    Leaves</h4>
                </div>
                <div className="bg-white py-3 px-5 d-flex gap-5 flex-column rounded-tb shadow">
                    <div className="">
                        <div className='d-flex align-items-center justify-content-end pb-4'>
                            <ApplyLeaveModel update={setUpdate} />
                        </div>
                        {myleave[0] ? <Table columns={columns} data={myleave} /> : <EmptyTable />}
                    </div>
                </div>
            </section>
        </>
    )
}

export default LeavePage