import { border } from "@mui/system";
import defaultimage from "../image/man.png";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { environment } from "../../src/env";
import React, { useEffect, useState } from "react";

import EditProfileModal from "./EditProfileModal";
import ShowProfile from "./pages/showprofile";
import { loginAction } from "../userRedux/actions";
import { useDispatch } from "react-redux";
import swal from "sweetalert";
import { TaskSquare } from "iconsax-react";

interface UserData {
  firstname: string;
  lastname: string;
  profile_pic: any;
}
const ProfileModal = (props: any) => {
  const [modalShow, setmodalShow] = useState(false);
  const [userDetails, setUserDetails] = useState<UserData>({
    firstname: "",
    lastname: "",
    profile_pic: "",
  });
  const [modal, setModal] = useState(false);

  let userId = localStorage.getItem("userid");
  let EmpId = localStorage.getItem("employee_id");
  const toggle = () => {
    setModal(!modal);
  };
  const dispatch = useDispatch();

  const EmployeeDetails = async () => {
    await axios
      .get(`${environment.baseUrl}user-details/${EmpId}`)
      .then((res) => {
        setUserDetails(res.data);
      })
      .catch((res) => console.log("error"));
  };

  useEffect(() => {
    EmployeeDetails();
  }, []);

  const logoutTime = async () => {
    const userdata = {
      employee_id: localStorage.getItem("employee_id"),
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
    try {
      const timeout = await axios
        .post(`${environment.baseUrl}logout-time/`, userdata)
        .then((res) => {
          // if(res.status == 200){
          //   swal({
          //     title: "Are you sure?",
          //     // text: "Once deleted, you will not be able to recover this imaginary file!",
          //       icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
          //     dangerMode: true,
          //   })
          //   .then((willDelete) => {
          //     if (willDelete) {
          //       checkLeave();
          //     } else {
          //       swal("Your imaginary file is safe!");
          //     }
          //   });
          // }
        });
    } catch (err) {
      console.log("err");
    }
  };
  const updateRemark = async () => {
    const userdata = {
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
    try {
      const timeout = await axios.post(
        `${environment.baseUrl}update-logout-remark/${EmpId}`,
        userdata
      );
      localStorage.removeItem("employee_id");
      dispatch(loginAction(false));
      window.location.href = "/";
      console.log("logout success");
    } catch (err) {
      console.log("err");
    }
  };
  const checkLeave = async () => {
    const userdata = {
      date: moment(new Date()).format("YYYY-MM-DD"),
    };
    try {
      const timeout = await axios.post(
        `${environment.baseUrl}testingdate/${EmpId}`,
        userdata
      );
      localStorage.removeItem("employee_id");
      dispatch(loginAction(false));

      window.location.href = "/";
      console.log("logout success");
    } catch (err) {
      console.log("err");
    }
  };
  const twoFunctions = () => {
    swal({
      title: "Warning",
      text: "Are you sure?",
      icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
      buttons: ["No", "Yes"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        logoutTime();
        checkLeave();
      }
    });
  };
  const openModalHandler = () => {
    setmodalShow(!modalShow);
  };
  const closeModalHandler = () => {
    setmodalShow(!modalShow);
    props.getUser();
  };

  const removeProfile = async () => {
    const profilepic = {
      profile_pic: userDetails.profile_pic,
    };
    try {
      const profile = await axios
        .post(`${environment.baseUrl}delete-profile/E0001`, profilepic)
        .then((res) => {
          if (res.status == 200) {
            swal({
              title: "Are you sure?",
              // text: "Once deleted, you will not be able to recover this imaginary file!",
              icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                swal("Your profile image has been deleted!", {
                  icon: "success",
                });
              }
            });
          }
        });
    } catch (err) {
      console.log("err");
    }
  };

  return (
    <>
      <div className="meeting-box-main">
        <div className="Todo-lists-main p-2 px-1 pb-0">
          {modalShow ? (
            <div className="back-shed shadow-none">
              <ProfileModal
                className="modal"
                show={modalShow}
                close={closeModalHandler}
                // EditData={handleProfilePic}
              />
            </div>
          ) : null}
          <Dropdown className="">
            <Dropdown.Toggle
              style={{
                backgroundColor: "#fff",
                border: "none",
                boxShadow: "none",
              }}
            >
              <div className="navbar-box text-warning" title="Task">
                <TaskSquare className="fs-2" variant="Outline" />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="mt-3 shadow-lg bg-body rounded dropdowndata">
              <Dropdown.Item className="border-0 outline-none px-3 py-1">
                <i className="fa-regular fa-user pe-2 fs-6 base-text-color"></i>
                <Link
                  className="text-dark text-decoration-none base-font-600 fs-6"
                  to="/basicdetails"
                >
                  Show Profile
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className="border-0 outline-none px-3 py-1">
                {/* <EditProfileModal profile={userDetails.profile_pic} />
                 */}
                <i className="fa-regular fa-file-image pe-2 fs-6 base-text-color"></i>
                <Link
                  className="text-dark text-decoration-none base-font-600 fs-6"
                  to="#"
                  onClick={() => removeProfile()}
                >
                  Remove Profile
                </Link>
              </Dropdown.Item>
              <Dropdown.Item className="border-0 outline-none px-3 py-1">
                <i className="fa-solid fa-arrow-right-to-bracket pe-2 fs-6 text-danger"></i>
                <Link
                  className="text-decoration-none base-font-600 text-danger fs-6"
                  to=""
                  onClick={() => twoFunctions()}
                >
                  Logout
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default ProfileModal;

function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
