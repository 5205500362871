import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select'
import swal from "sweetalert";
import { Form, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Edit2 } from 'iconsax-react';
import { environment } from '../env';
import { getLeadLeaveData } from '../userRedux/actions';

const Lstatus = (props: any) => {
  const user = useSelector((state: any) => state?.root?.reducers?.userData);
  var option = [
        { value: 1, label: "Approve" },
        { value: 2, label: "Deny" }
    ]

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors },
    } = useForm();

    const [status, setStatus] = useState(option);
    const [modal, setModal] = useState(false);
    const toggle = () => { setModal(!modal) };

    const dispatch = useDispatch();

    const handle = (e: any) => {
        setStatus(e.value)
    }

    

    useEffect(() => {
    }, [status])

    const submit = (e: any) => {
        e.preventDefault();
        let empleavedata = {
            "lead_approve": status
        }
        axios.put(`${environment.baseUrl}lead-leave-status/${props.post.id}`,empleavedata).then((EmpResponse) => {
            if (EmpResponse.data.status === 'success') {
                toggle()
                AllLeaves()
                swal({
                    title: "Success",
                    text: 'Leave Status Updated',
                    icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
                })
            }
        })
            .catch(res => console.log("errorSubmit===================", status))
    }

    const AllLeaves = async () => {
        await axios
        .get(`${environment.baseUrl}get-lead-leaves/${user.employee_id}`)
        .then((res) => {
          dispatch(getLeadLeaveData(res.data));
        })
        .catch((res) => console.log("error", res));
    }

    const two = (e: any) => {
        submit(e);
    }

    return (
        <>
            <Form encType="multipart/form-data">
                <div className='edit-attendance'>
                    <Link className='text-dark text-decoration-none' to='' onClick={() => { toggle() }}> <Edit2 size="32" className='text-base-color' /></Link>
                </div>
            </Form>
            <Modal isOpen={modal} toggle={toggle} centered
                size='md'
                className='mispunch-modal'>
                <ModalHeader className="py-3 px-4"><span className='base-font-muli fs-3 text-base-color fw-bold '>Edit Leaves</span></ModalHeader>
                <form onSubmit={(e) => two(e)}>
                    <ModalBody className='py-5 px-4'>
                        <Select onChange={handle} options={option} className='fs-5 text-black fw-bold' />
                    </ModalBody>
                    <ModalFooter className='border-0 justify-content-center'>
                        <button className="submit-btn" type='submit'>
                            Submit
                        </button>
                        <button type={'button'} onClick={() => { toggle() }} className="close-btn">
                            Cancel
                        </button>
                    </ModalFooter>
                </form>
            </Modal >
        </>
    )
}

export default Lstatus