import { useForm } from "react-hook-form";
import React, { useState, useMemo } from "react";
import axios from "axios";
import "../../css/registration.css";
import signIn from "../../image/login.png";
import { useDispatch } from "react-redux";
import {
  HolidaysAction,
  getBreakLogData,
  getLeadLeaveData,
  loginAction,
  userAttendanceAction,
  userDataAction,
} from "../../userRedux/actions";
import { environment } from "../../env";
import moment from "moment";
import swal from "sweetalert";
import { Typewriter } from "react-simple-typewriter";
import { Eye, EyeSlash, Key, KeySquare, SmsNotification } from "iconsax-react";

const UserLogin = () => {
  const [errormessage, setErrormessage] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [eyeIcon, setEyeIcon] = useState(false);
  const [btndis, setBtndis] = useState(false);
  const [load, setLoad] = useState(false);
  const dispatch = useDispatch();
  const month = moment().format("MM");
  const year = moment().format("YYYY");
  var today = new Date();
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // {
  //     "id": 1,
  //     "date": "2022-01-26",
  //     "day": "2022-01-26",
  //     "name": "Republic Day"
  // },

  const submitform = async (logindata: object) => {
    setLoad(true);
    setBtndis(true);
    try {
      const res = await axios.post(`${environment.baseUrl}login/`, logindata);
      if (res.data.status !== "error") {
        setBtndis(false);
        setErrormessage("");
        await getuserData(res.data.employee_id);
        await getbreaklog(res.data.userid)
        await EmployeeAttendance(res.data.employee_id);
        await getLeadLeaves(res.data.employee_id)
        localStorage.setItem("employee_id", res.data.employee_id);
        localStorage.setItem("userid", res.data.userid);
        setLoad(false);
        dispatch(loginAction(true));
      } else {
        setLoad(false);
        setBtndis(false);
        setErrormessage("* invalid credentials");
      }
    } catch (err) {
      setLoad(false);
      setBtndis(false);
      console.log("err");
    }
  };

  const getuserData = async (id: string) => {
    await axios
      .get(`${environment.baseUrl}user-details/${id}`)
      .then((res) => {
        dispatch(userDataAction(res.data));
      })
      .catch((res) => console.log("error", res));
  };

  const getbreaklog = async (id: string) => {
    await axios
      .get(`${environment.baseUrl}break-by-user/${id}`)
      .then((res) => {
        dispatch(getBreakLogData(res.data));
      })
      .catch((res) => console.log("error", res));
  };

  const getLeadLeaves = async (id: string) => {
    await axios
      .get(`${environment.baseUrl}get-lead-leaves/${id}`)
      .then((res) => {
        dispatch(getLeadLeaveData(res.data));
      })
      .catch((res) => console.log("error", res));
  };

  const EmployeeAttendance = async (id: string) => {
    var URL = "";
    if (moment(today).format("DD") == "01") {
      var monthe = Number(month) - 1;
      URL = `${
        environment.baseUrl
      }attendance-filter?employee_id=${id}&date_after=${year}-${monthe}-01&date_before=${year}-${month}-${moment(
        lastDayOfMonth
      ).format("DD")}`;
    } else {
      URL = `${
        environment.baseUrl
      }attendance-filter?employee_id=${id}&date_after=${year}-${month}-01&date_before=${year}-${month}-${moment(
        lastDayOfMonth
      ).format("DD")}`;
    }
    await axios
      .get(URL)
      .then((res) => {
        if (res.data.length != 0) {
          Holidays();
          dispatch(userAttendanceAction(res.data));
          if (res.data[0].date == today && res.data[0].login_time == null) {
            swal("Please Start your Session!");
          }
        } else {
          dispatch(
            userAttendanceAction([
              {
                date: `2023-${month}-01`,
                employee_id: `${id}`,
                day: `2023-${month}-01`,
                login_time: `2023-${month}-01 00:00:00.312827+05:30`,
                logout_time: `2023-${month}-01 00:00:00.312827+05:30`,
                comment: "",
                remark: "Please Start your session",
                mispunch: "False",
                show: "False",
                ip_address: "False",
              },
            ])
          );
        }
      })
      .catch((res) => console.log("error"));
  };

  const Holidays = () => {
    axios
      .get(`${environment.baseUrl}holiday/`)
      .then((res) => {
        dispatch(HolidaysAction(res.data));
      })
      .catch((res) => console.log("error"));
  };

  useMemo(() => {
    if (errors.password) {
      setErrormessage("");
    }
  }, [errors.password]);

  return (
    <>
      <div className="signin-page">
        <div className="card border-0 login-card shadow-lg p-3 rounded-5">
          <div className="row m-0 p-0 w-100 h-100 align-items-center">
            <div className="col-12 col-md-6 col-lg-6 col-xl-7 px-5 pb-md-5">
              <div className="d-flex justify-content-start align-items-center px-5 py-4">
                <span className="signupHeading"></span>
                <h1
                  className="base-font-pop fw-bold pt-2  text-base-color "
                  style={{ fontSize: "3.4rem", height: "65px" }}
                >
                  {
                    <Typewriter
                      words={["Welcome", "To ,", "InstaCourier", "SIGN IN"]}
                      loop={0}
                      cursor={false}
                      // cursorStyle='|'
                      typeSpeed={100}
                      deleteSpeed={50}
                      delaySpeed={1000}
                    />
                  }
                </h1>
              </div>
              <div className="">
                <form
                  className="d-flex flex-column px-5"
                  onSubmit={handleSubmit(submitform)}
                >
                  <div className="d-flex  flex-column align-items-start w-100 margin-bottom-0">
                    <label
                      htmlFor="username"
                      className="base-font-600 fs-5 text-start text-base-color"
                    >
                      Username
                    </label>
                    <div
                      className={
                        errors.email_id
                          ? "border-base-red w-100 rounded-pill d-flex align-items-center px-2"
                          : "border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2"
                      }
                    >
                      <SmsNotification
                        size="32"
                        className={
                          errors.email_id ? "text-danger" : "text-base-color"
                        }
                      />
                      <input
                        className="border-0 rounded-pill  base-font-600 py-2 px-2 fs-5 w-100"
                        type="email"
                        placeholder="Email"
                        {...register("email_id", { required: true })}
                      />
                    </div>

                    {errors.email_id && (
                      <span className="fs-6 pt-2 text-danger base-font-600">
                        Email Id is required *
                      </span>
                    )}
                  </div>
                  <div className="d-flex  flex-column align-items-start w-100 mb-1">
                    <label
                      htmlFor="examplePassword"
                      className=" base-font-600 fs-5 text-start text-base-color"
                    >
                      Password *
                    </label>
                    <div
                      className={
                        errors.password
                          ? "border-base-red w-100 rounded-pill d-flex align-items-center px-2"
                          : "border-base-color-1 w-100 rounded-pill d-flex align-items-center px-2"
                      }
                    >
                      <Key
                        size="32"
                        className={
                          errors.password ? "text-danger" : "text-base-color"
                        }
                      />
                      <input
                        className="border-0 rounded-pill  base-font-600 py-2 px-2 fs-5 w-100"
                        type={eyeIcon ? "text" : "password"}
                        {...register("password", { required: true })}
                        placeholder="Password"
                      />
                      {eyeIcon ? (
                        <Eye
                          size="32"
                          onClick={() => setEyeIcon(!eyeIcon)}
                          className={
                            errors.password ? "text-danger" : "text-base-color"
                          }
                        />
                      ) : (
                        <EyeSlash
                          size="32"
                          onClick={() => setEyeIcon(!eyeIcon)}
                          className={
                            errors.password ? "text-danger" : "text-base-color"
                          }
                        />
                      )}
                    </div>
                    {errormessage && (
                      <h6 className="fs-6 pt-2 text-danger">{errormessage}</h6>
                    )}
                    {errors.password && (
                      <h6 className="fs-6 pt-2 text-danger base-font-600">
                        Password is required *
                      </h6>
                    )}
                  </div>
                  <div className="pt-4">
                    {load ? (
                      <button
                        className="border-0 rounded-pill  base-font-pop py-2 px-2 fs-4 w-100 bg-base-color text-white d-flex gap-2 justify-content-center align-items-center"
                        type="button"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading . . .
                      </button>
                    ) : (
                      <button
                        className="border-0 rounded-pill  base-font-pop py-2 px-2 fs-4 w-100 bg-base-color text-white"
                        disabled={btndis}
                        type="submit"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center w-100 px-2">
                    {/* <Link className='d-flex justify-content-center base-font-700 mt-3' to='/forgot-password'>Reset Password ?</Link>
                                        <Link className='d-flex justify-content-center base-font-700 mt-3 text-decoration-underline' to='registration'><span className="">Register Here</span></Link> */}
                  </div>
                </form>
              </div>
            </div>
            <div className="col col-md-6 col-lg-6 col-xl-5 p-0 d-flex justify-content-center align-items-center border-left-side">
              <div className="img-card p-2">
                <img src={signIn} alt="" className="img-fit" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserLogin;
