import { InfoCircle } from 'iconsax-react';
import React, { useState } from 'react';
import {    Modal, ModalBody, ModalFooter } from 'reactstrap';

const HolidaysModal = () => {
    const [modal, setModal] = useState(false);

    const toggle = () => setModal(!modal);

    return (
        <div>
            <div className="d-flex justify-content-end holidays-btn overflow-hidden">
            <button className="btn-s  " onClick={toggle} >
                <p className="paragraphs"> Leave Instructions </p>
                <span className="icon-wrapper">
                    <InfoCircle size="32" className="icon" />
                </span>
            </button>
            </div>
            <Modal isOpen={modal} toggle={toggle} centered className='modalstyle' size='lg' >
                <ModalBody >
                    <div className=''>
                        <h2 className="fs-2 text-base-color base-font-pop fw-semibold text-center pb-3 ">Leave Instructions</h2>
                        <ol className=''>
                            <li className='base-font-pop fs-6 mb-3'>Make sure everyone apply for leave prior one month</li>
                            <li className='base-font-pop fs-6 mb-3'>Yearly <span className='base-font-inter fw-bold text-base-red'>
                                12 leaves</span> available,every month you are allowed to take 1 leave</li>
                            <li className='base-font-pop fs-6 mb-3'>After completion of <span className='base-font-inter fw-bold text-base-red'>
                                12 leaves</span> your salary will be deducted on applied leaves</li>
                            <li className='base-font-pop fs-6 mb-3'>No leaves allowed to take in <i>Probation period</i> <span className='base-font-inter fw-bold text-base-color'>(First 3 months)</span>  </li>
                            <li className='base-font-pop fs-6 mb-3'> Yearly <span className='base-font-inter fw-bold text-base-red'>5 Sick leaves</span> allowed to take</li>
                        </ol>
                    </div>

                </ModalBody>
                <ModalFooter className='border-0 justify-content-center'>
                    <button onClick={toggle} className="close-btn">
                        Close
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default HolidaysModal;