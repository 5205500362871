import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import swal from "sweetalert";
import axios from 'axios';
import { S3_BUCKET, environment, link, myBucket } from '../../../env';
import { Button, Card, CardBody, CardFooter, CardTitle, Form, Input, Label } from 'reactstrap'

const AddExperience = () => {
    var date = Math.round(+new Date() / 1000);
    const [upload, setupload] = useState<any>()
    const Emp_Id = localStorage.getItem("employee_id");
    const [UploadingDetails, setUploadingDetails] = useState(
        {
            Emp_Id: '',
            company_name: '',
            position: '',
            joining_date: '',
            leaving_date: '',
            file: ''
        }
    );

    const Image = async (data: any) => {
        const params = {
            ACL: "public-read",
            Body: data,
            Bucket: S3_BUCKET,
            Key: `document_${Emp_Id}_${date}_${data.name}`,
        };
        myBucket.upload(params, function (err: any, uploadData: any) {
            if (uploadData) {
                data = uploadData.Location;
                return (data);
            } else {
                console.log("error", err);
            }
        });
    }

    const handleOnChange = (event: any) => {

        setUploadingDetails({
            ...UploadingDetails,
            [event.target.name]: event.target.value
        });
    }

    const navigate = useNavigate();

    const handleOnFileChange = (e: any) => {
        const fileList = e.target.files;
        setupload(fileList[0])
    };

    const postexperience = async (document: any) => {
        let form_data = new FormData();
        form_data.append('employee_id', '')
        form_data.append('company_name', UploadingDetails.company_name)
        form_data.append('position', UploadingDetails.position)
        form_data.append('joining_date', UploadingDetails.joining_date)
        form_data.append('leaving_date', UploadingDetails.leaving_date)
        if (upload && upload.name) {
            Image(upload)
            form_data.append('file', `${link}document_${Emp_Id}_${date}_${upload.name}`)
        }

        try {
            const res = await axios.post(`${environment.baseUrl}experience-post/${Emp_Id}`, form_data, {

            });
            return res.data
        }
        catch (err) {
            console.log('err');
            return err
        }
        return
    };

    const onSubmit = async (data: any) => {
        postexperience(data).then((docResponse) => {

            if (docResponse.status === 'success') {
                swal({
                    title: "Success",
                    text: 'Experience Details Added Sucessfully',
                    icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                }).then(() => {
                    navigate(-1)
                })
            }
        });
    }


    return (
        <>

            <div>
                <Card style={{ background: "white", width: "95%", marginLeft: "36px", height: "80%" }}>
                    <CardTitle style={{ display: "flex", position: "relative" }}>
                        <h1 className="h3 mb-0 text-gray-800" style={{ fontWeight: 500, paddingTop: "20px", paddingLeft: "20px" }}>Add Experience</h1>
                    </CardTitle>
                    <CardBody style={{ marginTop: "50px" }}>
                        <Form>
                            <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", gap: 20, marginTop: '-35px' }}>
                                <div className="width-left" style={{ width: "50%" }}>
                                    <Label>
                                        Company Name
                                    </Label>
                                    <Input
                                        type="text"
                                        id="company_name"
                                        name="company_name"
                                        value={UploadingDetails.company_name}
                                        onChange={handleOnChange}
                                        placeholder="Comapny Name">
                                    </Input>
                                    <Label style={{ paddingTop: "20px " }}>

                                        Joining Date
                                    </Label>
                                    <Input
                                        type="date"
                                        id="Joiningdate"
                                        name="joining_date"
                                        value={UploadingDetails.joining_date}
                                        onChange={handleOnChange}
                                        placeholder="Joining Date">
                                    </Input>

                                </div>
                                <div className="width-right" style={{ width: "50%" }}>
                                    <Label>
                                        Position
                                    </Label>
                                    <Input
                                        type="text"
                                        id="Position"
                                        name="position"
                                        value={UploadingDetails.position}
                                        onChange={handleOnChange}
                                        placeholder="Position">
                                    </Input>
                                    <Label style={{ paddingTop: "20px " }}>
                                        Leaving Date
                                    </Label>
                                    <Input
                                        type="date"
                                        id="leavingdate"
                                        name="leaving_date"
                                        value={UploadingDetails.leaving_date}
                                        onChange={handleOnChange}
                                        placeholder="Leaving Date   ">
                                    </Input>
                                </div>
                            </div>
                            <Label style={{ paddingTop: "20px " }}>
                                Upload Document
                            </Label>
                            <Input accept='.pdf'
                                type="file"
                                id="file"
                                name="file"
                                onChange={(e) => { handleOnFileChange(e) }}
                            >
                            </Input>
                            <p className='mt-2'><span style={{ fontWeight: '800' }}>Note: </span>Accept only PDF File</p>
                        </Form>
                    </CardBody>
                    <CardFooter>
                        <Button color="success"
                            onClick={onSubmit}>
                            Upload
                        </Button>{' '}
                    </CardFooter>
                </Card>
            </div></>
    )
}

export default AddExperience