import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios';
// import { environment } from '../../env';
// import Lstatus from './Lstatus';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useForm } from 'react-hook-form';

import { ReceiptEdit, Refresh, SearchNormal } from 'iconsax-react';
import Table from '../../Table/Table';
// import EmptyTable from '../errorComponents/EmptyTable';
import { useDispatch } from 'react-redux';
// import { getLeaves } from '../../Redux/actions';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { environment } from '../../../env';
import { getLeadLeaveData } from '../../../userRedux/actions';
import EmptyTable from '../../errorComponents/EmptyTable';
import Lstatus from '../../../Models/Lstatus';

interface DataRow {
    employee_id: string;
    leave_type: string;
    user: {
        firstname: string;
        lastname: string;
    };
    firstname: string;
    apply_date: string;
    start_date: string;
    end_date: string;
    leave_durations: string;
    leave_reason: any;
    leave_status: any;
    lead_approve:any;
}

const LeavePage = (props: any) => {
    const user = useSelector((state: any) => state?.root?.reducers?.userData);
    const [filLeave, setFilLeave] = useState<[]>([])
    const [rotate, setRotate] = useState(false)

    const allLeaves = useSelector((state: any) => state?.root?.reducers?.leadleaves);

    var Leavess = allLeaves.filter((leave: { show: number; }) => leave.show == 1)
    const dispatch = useDispatch();
    const {
        register,
        watch
    } = useForm();

    const submit = (data: any) => {
        var empID = watch("employee_id")
        var firstname = watch("firstname")
        if (empID == undefined) {
            empID = ''
        }
        if (firstname == undefined) {
            firstname = ''
        }
        const SearchUrl = `${environment.baseUrl}get-all-leaves-filter?employee_id=${empID}&firstname=${firstname}`
        const result = axios(SearchUrl).then(res => {
            if (res?.data?.length > 0) {
                const fil = res.data.filter((dat: { show: number; }) => dat.show == 1)
                setFilLeave(fil);
            } else {
                alert("data not available")
            }
        })
            .catch(res => console.log("error"));
    }

    const onSubmit = (data: any) => {
        debounce(data);
    };

    const debounce = useCallback(
        _.debounce((_searchVal) => {
            submit(_searchVal);
        }, 900),
        []
    );

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Emp ID',
            selector: row => row?.employee_id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Name',
            selector: row => `${row?.user.firstname} ${row?.user?.lastname}`,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Leave Type',
            selector: row => row?.leave_type,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Apply Date',
            selector: row => row?.apply_date.split("T")[0],
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Start Date',
            selector: row => row?.start_date.split("T")[0],
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'End Date',
            selector: row => row?.end_date.split("T")[0],
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Leave Duration',
            selector: row => row?.leave_durations,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Leave Reason',
            selector: row => row?.leave_reason,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Leave Status',
            // selector: row => row.leave_status,
            selector: row => <div
                className={`${row?.leave_status == "Late" ? "status-bg-green status-color-green " :
                    row?.leave_status == "Late EarlyLeave" ? "status-bg-orange status-color-orange" :
                        row?.leave_status == "Working..." ? "status-bg-blue status-color-blue" :
                            row?.leave_status == "pending" ? "status-bg-yellow status-color-yellow" :
                                row?.leave_status == "Absent" ? "status-bg-red status-color-red" :
                                    row?.leave_status == "Denied" ? "status-bg-red status-color-red" :
                                        row?.leave_status == "EarlyLeave" ? "status-bg-lime status-color-lime" :
                                            row?.leave_status == "Week-Off" ? "status-bg-emerald status-color-emerald" :
                                                row?.leave_status == "FullDay" ? "status-bg-cyan status-color-cyan" :
                                                    row?.leave_status == "Late HalfDay" ? "status-bg-sky status-color-sky" :
                                                        row?.leave_status == "HalfDay" ? "status-bg-purple status-color-purple" : "status-bg-green status-color-green"
                    } w-100 padding-box rounded-5 d-flex justify-content-center align-items-center`}>
                <span className="base-font-inter fw-bold fs-6 text-capitalize"
                    style={{ margin: '0 5rem' }}>{row?.leave_status}</span>
            </div>,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, 
        {
            name: 'Lead Approval',
            selector: row => row?.lead_approve == 0 ? "Pending" : row.lead_approve == 1 ? "Approved" : "Declined",
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Edit',
            selector: row => <Lstatus post={row} />,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
    ];
    useEffect(() => {
        setTimeout(() => {
            setRotate(false)
        }, 6000);
    }, [filLeave,allLeaves])
    return (
        < >
            <div className="dashboard_main px-4 mt-3">
                <form>
                    <nav>
                        <div className=" d-flex align-items-center">
                            <div className="col-12 d-flex align-items-center p-0 gap-2">
                                <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
                                    <span className='base-text-color base-font-700 fs-4'><ReceiptEdit size="35" variant="Bulk" /></span>
                                    <h4 className='fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2'>
                                        Team Leaves</h4>
                                </div>
                                {/* <div className="px-3 py-2 col-5  bg-white rounded-3 shadow">
                                    <div className='d-flex align-items-center bg-transparent border-base-bottom-2'>
                                        <SearchNormal size="25" className='text-base-color' />
                                        <select className="p-2 border-0 shadow-none bg-transparent  w-100 title-font-pop"
                                            style={{ fontSize: "1rem" }}
                                            {...register("firstname", { onChange: (e) => { submit(e.target.value) } })}
                                        >
                                            <option value="" className='attoptions'>Employee Name...</option>
                                            {allUsers.reverse().map((user: any, i: any) => {
                                                return <option className='attoptions' value={user.firstname} key={i}>{user.employee_id} - {user.firstname} {user.lastname}  </option>;
                                            })}
                                        </select>
                                    </div>
                                </div> */}
                                {/* <div className="py-2 col-1 bg-white rounded-3 shadow d-flex justify-content-center align-items-center" title='Refresh'>
                                    <div className={rotate ? "rotateBox" : ""} style={{ cursor: "pointer" }} onClick={() => { AllLeaves() }}>
                                        <Refresh size="35" className='text-base-color' />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </nav>
                </form>
                <section className='mt-5'>
                    <div className="bg-white pt-3 pb-3 px-4 d-flex gap-5 flex-column rounded-5 shadow">
                        {allLeaves ? <Table columns={columns} data={filLeave?.length > 0 ? filLeave : Leavess} />
                            : <EmptyTable />}
                    </div>
                </section>
            </div>
        </>
    )
}
export default LeavePage