import React, { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../env";

interface userDatavalue {
  employee_id: any;
  file: any;
  created_date: any;
  user: any;
}

const JoiningLetter = () => {
  const [joiningData, setjoiningData] = useState<userDatavalue[]>([]);
  const userId = localStorage.getItem("userid");
  const EmpId = localStorage.getItem("employee_id");

  const EmployeeJoiningLetter = async () => {
    await axios
      .get(`${environment.baseUrl}user-get-joining-letter/${EmpId}`)
      .then((res) => {
        setjoiningData(res.data);
      })
      .catch((res) => console.log("error"));
  };

  useEffect(() => {
    EmployeeJoiningLetter();
  }, []);

  return (
    <>
      <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-0 text-gray-800">Joining Letter</h1>
        </div>
        <table className="table table-hover">
          <thead key="thead">
            <tr>
              <th scope="col">Emp Id</th>
              <th scope="col">Name</th>
              <th scope="col">File</th>
              <th scope="col">Date</th>
              <th scope="col">View</th>
            </tr>
          </thead>
          <tbody key="tbody">
            {joiningData &&
              joiningData?.map((joining: any, i) => {
                return (
                  <tr key={i}>
                    <td>{joining.employee_id}</td>
                    <td>{joining.user?.firstname}</td>
                    <td>{joining.file}</td>
                    <td>{joining.created_date}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default JoiningLetter;
