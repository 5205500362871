import moment from "moment";
import {
  Speedometer,
  Hospital,
  Calendar,
  CalendarTick,
  Bookmark,
  Cup,
  SaveMinus,
  MagicStar,
  SmsEdit,
  TimerStart,
  TimerPause,
  Timer,
  Timer1,
  Briefcase,
} from "iconsax-react";
import thunder from "../image/GifBox/thunder.gif";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Comments from "./Comments";
import swal from "sweetalert";
const greetingTime = require("greeting-time");
import AnimatedNumbers from "react-animated-numbers";
import { ReminderAction, getProjectData, getTasksData, getTeamMembersData, getTeamsData } from "../userRedux/actions";
import axios from "axios";
import { environment } from "../env";
import Tasks from "./pages/Task/Task";
import AddTeam from "./AddTeam";

function DashBoard() {
  const today = new Date();
  const user = useSelector((state: any) => state?.root?.reducers?.userData);
  const useratt = useSelector(
    (state: any) => state?.root?.reducers?.userAttendance
  );
  let att = useratt.filter((data: any) => moment(data.date).format("DD-MM-YYYY") == moment(today).format("DD-MM-YYYY") && data.mispunch == "False")

  const reminder = useSelector((state: any) => state?.root?.reducers?.reminder);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      reminder == true &&
      useratt[0]?.date != moment(today).format("YYYY-MM-DD")
    ) {
      swal({
        title: "Reminder",
        icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
        text: "Please start your session",
        timer: 2500,
        dangerMode: true,
      });
      dispatch(ReminderAction(false));
    }
  }, [user, useratt]);

  const getProjects = async () => {
    axios.get(`${environment.baseUrl}employee-projects/${user?.id}`)
      .then(res => {
        dispatch(getProjectData(res.data))
      })
      .catch(res => console.log("error"))
  }

  const getTeamMembers = () => {
    axios.get(`${environment.baseUrl}create-team-member`)
      .then(res => {
        dispatch(getTeamMembersData(res.data))
      })
      .catch(res => console.log("error"))
  }

  const getTasks = () => {
    axios.get(`${environment.baseUrl}remainig-tasks/${user?.id}`)
      .then(res => {
        if (res.data.code != 5000) {
          dispatch(getTasksData(res.data))
        }
      })
      .catch(res => console.log("error"))
  }

  const getTeams = () => {
    axios.get(`${environment.baseUrl}create-team`)
      .then(res => {
        dispatch(getTeamsData(res.data))
      })
      .catch(res => console.log("error"))
  }

  const RefreshData = () => {
    getProjects();
    getTeamMembers();
    getTasks();
    getTeams()
  }

  useEffect(() => {
    RefreshData();
  }, [])

  return (
    <>
      <div className="">
        <div className="px-1 w-100 px-xl-5 px-lg-2 px-md-2">
          <div className="my-2 px-2 dashboard-heading">
            <h4 className=" mb-0 text-base-gray fw-semibold  pt-2 fs-4 base-font-pop ">
              {moment().format("dddd , DD MMMM YYYY")}
            </h4>
            <h1 className="fs-1 mb-0 text-black fw-bold  pt-2  base-font-inter text-capitalize">
              {greetingTime(new Date())},
              <span className="base-text-color base-font-Dancing fw-normal  text-capitalize ">
                {user?.firstname}.
              </span>
            </h1>
          </div> 
          <div className="row mt-0 mt-md-2 mt-lg-2 mt-xl-2 mb-4 px-1 gy-4">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
              <div className="card border-0  rounded-4 flex-row justify-content-between align-items-center p-4 p-md-3 p-lg-3 p-xl-4  card-box box-green-bg-1">
                <div className="d-flex flex-column gap-1">
                  <h4 className="fs-4 mb-0 fw-semibold box-green base-font-pop">
                    Late Marks
                  </h4>
                  <h2 className="  fw-semibold   base-font-inter light-icon fs-2">
                    <AnimatedNumbers
                      animateToNumber={user?.late_marks}
                      configs={(number, index) => {
                        return {
                          mass: 1,
                          tension: 230 * (index + 1),
                          friction: 140,
                        };
                      }}
                    />
                  </h2>
                </div>
                <div className="dashboardBox-card box-green-bg">
                  <SmsEdit size="34" color="#ffffff" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
              <div className="card border-0  rounded-4 flex-row justify-content-between align-items-center p-4 p-md-3 p-lg-3 p-xl-4  card-box box-blue-bg-1">
                <div className="d-flex flex-column gap-1">
                  <h4 className="fs-4 mb-0 box-blue base-font-pop fw-semibold text-nowrap">
                    Early Leave
                  </h4>
                  <h2 className="  fw-semibold   base-font-inter light-icon fs-2">
                    <AnimatedNumbers
                      animateToNumber={user?.early_leave}
                      configs={(number, index) => {
                        return {
                          mass: 1,
                          tension: 230 * (index + 1),
                          friction: 140,
                        };
                      }}
                    />
                  </h2>
                </div>
                <div className="dashboardBox-card box-blue-bg">
                  <Briefcase size="34" color="#fff" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
              <div className="card border-0  rounded-4 flex-row justify-content-between align-items-center p-4 p-md-3 p-lg-3 p-xl-4 card-box box-gray-bg-1">
                <div className="d-flex flex-column gap-1">
                  <h4 className="fs-4 mb-0  box-gray base-font-pop fw-semibold">
                    Over Time
                  </h4>
                  <h2 className="  fw-semibold   base-font-inter light-icon fs-2 d-flex align-items-center gap-1">
                    <AnimatedNumbers
                      animateToNumber={Number(
                        Number(user?.over_time / 60).toFixed(0)
                      )}
                      configs={(number, index) => {
                        return {
                          mass: 1,
                          tension: 230 * (index + 1),
                          friction: 140,
                        };
                      }}
                    />
                    <span className="fs-5 pt-2">Hrs</span>
                  </h2>
                </div>
                <div className="dashboardBox-card box-gray-bg">
                  <Timer1 size="34" color="#ffffff" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3 ">
              <div className="card border-0  rounded-4 flex-row justify-content-between align-items-center p-4 p-md-3 p-lg-3 p-xl-4 card-box box-pink-bg-1">
                <div className="d-flex flex-column gap-1">
                  <h4 className="fs-4 mb-0 base-font-pop fw-semibold box-pink">
                    Rewards
                  </h4>
                  <h2 className="  fw-semibold   base-font-inter light-icon fs-2">
                    {user?.rewards?.length == 0 ? (
                      <AnimatedNumbers
                        animateToNumber={Number("0")}
                        configs={(number, index) => {
                          return {
                            mass: 1,
                            tension: 230 * (index + 1),
                            friction: 140,
                          };
                        }}
                      />
                    ) : (
                      <AnimatedNumbers
                        animateToNumber={user?.rewards}
                        configs={(number, index) => {
                          return {
                            mass: 1,
                            tension: 230 * (index + 1),
                            friction: 150,
                          };
                        }}
                      />
                    )}
                  </h2>
                </div>
                <div className="dashboardBox-card box-pink-bg">
                  <Cup size="34" color="#ffffff" />
                </div>
              </div>
            </div>
          </div>
          <div className="row px-3">
            <div className="light-blue-bg py-5 px-3 px-md-4  rounded-4 mb-4 mt-3">
              <div className="row gy-4 ">
                <div className="col-12 col-sm-12 col-md-3 ">
                  <div className="dashboardBox mb-3 light-blue-dark p-1">
                    <img
                      src={thunder}
                      alt="thander"
                      className="user-image-fit"
                    />
                  </div>
                  <h3 className="fs-2 darkBlue  fw-semibold  base-font-Dancing fw-normal pt-2">
                    Statistics
                  </h3>
                  <p className="h5 mb-0 fw-semibold text-muted">
                    Your statistics For{" "}
                    <span className="text-black fw-bold">1 Year</span> Period.
                  </p>
                </div>
                <div className="col-12 col-sm-12 col-md-3 ">
                  <div className="card border-0 bg-white shadow-lg py-4 px-3 rounded-5">
                    <div className="dashboardBox mb-3 bg-info">
                      <Calendar size="24" color="#ffffff" />
                    </div>
                    <div className="">
                      <h2 className="  fw-semibold   base-font-inter light-icon fs-2">
                        {user?.previlage_leave}/12
                      </h2>
                      <h4 className="fs-5 mb-0 fw-bold text-muted d-flex">
                        Privilage{" "}
                        <span className="d-block d-md-none d-lg-block d-xl-block ms-1">
                          Leaves
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-3 ">
                  <div className="card border-0 bg-white shadow-lg py-4 px-3 rounded-5">
                    <div className="dashboardBox bg-warning mb-3">
                      <Speedometer size="26" color="#ffffff" />
                    </div>
                    <div className="">
                      <h2 className="  fw-semibold   base-font-inter light-icon fs-2">
                        {user?.comp_off}
                      </h2>
                      <h4 className="fs-5 mb-0 fw-bold text-muted">Comp-Off</h4>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-3 ">
                  <div className="card border-0 bg-white shadow-lg py-4 px-3 rounded-5">
                    <div className="dashboardBox bg-danger mb-3">
                      <Hospital size="24" color="#ffffff" />
                    </div>
                    <div className="">
                      <h2 className="  fw-semibold   base-font-inter light-icon fs-2">
                        {user?.sick_leave}/5
                      </h2>
                      <h4 className="fs-5 mb-0 fw-bold text-muted">
                        Sick Leaves
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center pb-2 mx-1">
            <h3 className="base-font-inter light-icon fw-bold fs-3">
              Today <CalendarTick size="30" color="#1D1956" />
            </h3>
          </div>
          {att.length != 0 ? <>
            <div className="bg-white py-4 px-2 py-md-2 px-md-4 px-lg-3 px-xl-4 align-self-center rounded-4 shadow align-items-center row mx-1 gy-2">
              <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-2 col-xl-2 col-md-2 text-center">
                {att[0]?.date}
              </h5>
              <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-0 col-xl-1 d-xl-block d-none col-md-0  text-center">
                {moment(att[0]?.day).format("dddd")}
              </h5>
              <>
                <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-2 col-xl-2 col-md-2 text-center">
                  {att[0]?.login_time == "null" ||
                    att[0]?.login_time == "Registered" ? (
                    <span>---</span>
                  ) : (
                    moment(
                      att[0]?.login_time.split(" ")[1].split(".")[0],
                      "HH:mm:ss"
                    ).format("hh:mm A")
                  )}
                </h5>
                <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-2 col-xl-2 col-md-2 text-center">
                  {att[0]?.logout_time === "Registered" ? (
                    <span>---</span>
                  ) : att[0]?.logout_time === "null" &&
                    att[0]?.login_time === "null" ? (
                    "---"
                  ) : att[0]?.logout_time === "null" ? (
                    <span>Please Logout</span>
                  ) : att[0]?.logout_time === "Registered" ? (
                    <span>---</span>
                  ) : (
                    moment(
                      att[0]?.logout_time.split(" ")[1].split(".")[0],
                      "HH:mm:ss"
                    ).format("hh:mm A")
                  )}
                </h5>
              </>
              <div className="comments-scroll pt-2 col-12 col-sm-6 col-lg-4 col-xl-3 col-md-4 d-flex justify-content-center">
                {att[0]?.comment &&
                  att[0]?.comment == "null" &&
                  moment(new Date()).format("YYYY-MM-DD") ? (
                  <Comments post={att[0]?.date} dashboard={true} />
                ) : (
                  att[0]?.comment
                )}
              </div>
              <div
                className={`${att[0]?.remark == "Late"
                  ? "status-bg-green status-color-green "
                  : att[0]?.remark == "Late EarlyLeave"
                    ? "status-bg-orange status-color-orange"
                    : att[0]?.remark == "Working..."
                      ? "status-bg-blue status-color-blue"
                      : att[0]?.remark == "pending"
                        ? "status-bg-yellow status-color-yellow"
                        : att[0]?.remark == "Absent"
                          ? "status-bg-red status-color-red"
                          : att[0]?.remark == "EarlyLeave"
                            ? "status-bg-lime status-color-lime"
                            : att[0]?.remark == "Week-Off"
                              ? "status-bg-emerald status-color-emerald"
                              : att[0]?.remark == "FullDay"
                                ? "status-bg-cyan status-color-cyan"
                                : att[0]?.remark == "Late HalfDay"
                                  ? "status-bg-sky status-color-sky"
                                  : att[0]?.remark == "HalfDay" &&
                                  "status-bg-purple status-color-purple"
                  }  w-100 py-2 px-4 rounded-5 col-12 col-sm-6 col-lg-2 col-xl-2 col-md-2 d-flex justify-content-center align-items-center`}
              >
                <span className=" base-font-inter fw-bold fs-6 text-capitalize">
                  {useratt[0]?.remark}
                </span>
              </div>
            </div>
          </> :
            useratt[0].remark == "Please Start your session" ? <>
              <div className="bg-white py-4 px-2 py-md-2 px-md-4 px-lg-3 px-xl-4 align-self-center rounded-4 shadow align-items-center row mx-1 gy-2">
                <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-2 col-xl-2 col-md-2 text-center">
                  Registered
                </h5>
                <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-0 col-xl-1 d-xl-block d-none col-md-0  text-center">
                  ---
                </h5>
                <>
                  <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-2 col-xl-2 col-md-2 text-center">
                    ---
                  </h5>
                  <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-2 col-xl-2 col-md-2 text-center">
                    ---
                  </h5>
                </>
                <div className="comments-scroll pt-2 col-12 col-sm-6 col-lg-4 col-xl-3 col-md-4 d-flex justify-content-center">
                  ---
                </div>
                <div
                  className={`${att[0]?.remark == "Late"
                    ? "status-bg-green status-color-green "
                    : att[0]?.remark == "Late EarlyLeave"
                      ? "status-bg-orange status-color-orange"
                      : att[0]?.remark == "Working..."
                        ? "status-bg-blue status-color-blue"
                        : att[0]?.remark == "pending"
                          ? "status-bg-yellow status-color-yellow"
                          : att[0]?.remark == "Absent"
                            ? "status-bg-red status-color-red"
                            : att[0]?.remark == "EarlyLeave"
                              ? "status-bg-lime status-color-lime"
                              : att[0]?.remark == "Week-Off"
                                ? "status-bg-emerald status-color-emerald"
                                : att[0]?.remark == "FullDay"
                                  ? "status-bg-cyan status-color-cyan"
                                  : att[0]?.remark == "Late HalfDay"
                                    ? "status-bg-sky status-color-sky"
                                    : att[0]?.remark == "HalfDay" &&
                                    "status-bg-purple status-color-purple"
                    }  w-100 py-2 px-4 rounded-5 col-12 col-sm-6 col-lg-2 col-xl-2 col-md-2 d-flex justify-content-center align-items-center`}
                >
                  <span className=" base-font-inter fw-bold fs-6 text-capitalize">
                    Registered
                  </span>
                </div>
              </div>
            </> :
              <>
                <div className="bg-white py-4 px-2 py-md-2 px-md-4 px-lg-3 px-xl-4 align-self-center rounded-4 shadow align-items-center row mx-1 gy-2">
                  <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-2 col-xl-2 col-md-2 text-center">

                  </h5>
                  <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-0 col-xl-1 d-xl-block d-none col-md-0  text-center">
                    ---
                  </h5>
                  <>
                    <h5 className="fs-6 base-font-500 text-muted col-4 col-sm-6 col-lg-2 col-xl-2 col-md-2 text-center">
                      ---
                    </h5>
                    <h5 className="fs-6 base-font-500 text-muted fw-bold col-4 col-sm-6 col-lg-2 col-xl-2 col-md-2 text-center status-bg-red status-color-red">
                      Please Start Your Session For Today
                    </h5>
                  </>
                  <div className="comments-scroll pt-2 col-12 col-sm-6 col-lg-4 col-xl-3 col-md-4 d-flex justify-content-center">
                    ---
                  </div>
                  <div
                    className={`${att[0]?.remark == "Late"
                      ? "status-bg-green status-color-green "
                      : att[0]?.remark == "Late EarlyLeave"
                        ? "status-bg-orange status-color-orange"
                        : att[0]?.remark == "Working..."
                          ? "status-bg-blue status-color-blue"
                          : att[0]?.remark == "pending"
                            ? "status-bg-yellow status-color-yellow"
                            : att[0]?.remark == "Absent"
                              ? "status-bg-red status-color-red"
                              : att[0]?.remark == "EarlyLeave"
                                ? "status-bg-lime status-color-lime"
                                : att[0]?.remark == "Week-Off"
                                  ? "status-bg-emerald status-color-emerald"
                                  : att[0]?.remark == "FullDay"
                                    ? "status-bg-cyan status-color-cyan"
                                    : att[0]?.remark == "Late HalfDay"
                                      ? "status-bg-sky status-color-sky"
                                      : att[0]?.remark == "HalfDay" &&
                                      "status-bg-purple status-color-purple"
                      }  w-100 py-2 px-4 rounded-5 col-12 col-sm-6 col-lg-2 col-xl-2 col-md-2 d-flex justify-content-center align-items-center`}
                  >
                    <span className=" base-font-inter fw-bold fs-6 text-capitalize">
                      ---
                    </span>
                  </div>
                </div>
              </>
          }
        </div>
      </div>
    </>
  );
}

export default DashBoard;
