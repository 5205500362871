import DataTable, { TableColumn } from "react-data-table-component";
import moment from "moment";
import axios from "axios";
import Comments from "../../Comments";
import Table from "../../Table/Table";
import { useSelector } from "react-redux";
import EmptyTable from "../../errorComponents/EmptyTable";
import { DatePicker, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import CommentPop from "../../errorComponents/CommentPop";
import { environment } from "../../../env";
import swal from "sweetalert";
import { CalendarTick } from "iconsax-react";

interface DataRow {
  id: number;
  employee_id: string;
  empName: string;
  date: any;
  day: string;
  mispunch: string;
  login_time: string;
  logout_time: any;
  comment: string;
  remark: any;
  user: {
    firstname: string;
    lastname: string;
  };
}

const TeamAttendance = () => {
  const useratt = useSelector(
    (state: any) => state?.root?.reducers?.userAttendance
  );
  const user = useSelector((state: any) => state?.root?.reducers?.userData);
  const userId = user?.employee_id;
  const [att, setAtt] = useState([]);
  const [comments, setComments] = useState();
  const [teamAtt, setTeamAtt] = useState<DataRow []>([]);
  console.log('teamAtt', teamAtt);
  const { RangePicker } = DatePicker;
  const [modal, setModal] = useState(false);
  var comment = "";

  const current = (data: any) => {
    setModal(true);
    comment = data;
    setComments(data);
  };

  useMemo(() => {
    setComments(comments);
  }, [comments]);

  const columns: TableColumn<DataRow>[] = [
    {
      name: "EMP ID",
      selector: (row) => row?.employee_id,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Name",
      selector: (row) => row?.user.firstname + ' '+ row?.user?.lastname,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Date",
      selector: (row) => row?.date,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Day",
      selector: (row) => moment(row?.day).format("dddd"),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Time-In",
      selector: (row) =>
        row?.mispunch == "True" ? (
          moment(row?.login_time?.split("T")[1], "HH:mm:ss").format("hh:mm A")
        ) : row?.login_time == "null" ||
          row.login_time == "Registered" ? (
          <span style={{ paddingLeft: "30px" }}>---</span>
        ) : (
          moment(row?.login_time?.split(" ")[1].split(".")[0], "HH:mm:ss").format(
            "hh:mm A"
          )
        ),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Time-out",
      selector: (row) =>
        row?.mispunch == "True" ? (
          row?.logout_time === "null" ? (
            <span style={{ paddingLeft: "30px" }}>---</span>
          ) : (
            moment(row?.logout_time?.split("T")[1], "HH:mm:ss").format("hh:mm A")
          )
        ) : row?.logout_time === "null" ||
          row.logout_time === "Registered" ? (
          <span style={{ paddingLeft: "30px" }}> --- </span>
        ) : (
          moment(
            row?.logout_time.split(" ")[1].split(".")[0],
            "HH:mm:ss"
          ).format("hh:mm A")
        ),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Comments",
      selector: (row) =>
        row?.comment == "null" && moment(new Date()).format("YYYY-MM-DD") ? (
          <Comments post={row?.date} />
        ) : (
          <div
            onClick={() => {
              current(row?.comment);
            }}
          >
            {row?.comment}
          </div>
        ),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Remark",
      selector: (row) => (
        <div
          className={`${
            row?.remark == "Late"
              ? "status-bg-green status-color-green "
              : row?.remark == "Late EarlyLeave"
              ? "status-bg-orange status-color-orange"
              : row?.remark == "Working..."
              ? "status-bg-blue status-color-blue"
              : row?.remark == "pending"
              ? "status-bg-yellow status-color-yellow"
              : row?.remark == "Absent"
              ? "status-bg-red status-color-red"
              : row?.remark == "Denied"
              ? "status-bg-red status-color-red"
              : row?.remark == "EarlyLeave"
              ? "status-bg-lime status-color-lime"
              : row?.remark == "Week-Off"
              ? "status-bg-emerald status-color-emerald"
              : row?.remark == "FullDay"
              ? "status-bg-cyan status-color-cyan"
              : row?.remark == "Late HalfDay"
              ? "status-bg-sky status-color-sky"
              : row?.remark == "HalfDay" &&
                "status-bg-purple status-color-purple"
          } w-100 padding-box rounded-5 d-flex justify-content-center align-items-center`}
        >
          <span className="base-font-inter fw-bold fs-6 text-capitalize">
            {row?.remark}
          </span>
        </div>
      ),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
  ];

  const onSubmit = (data: any) => {
    if (data) {
      let SearchUrl;
      SearchUrl = `${environment.baseUrl}attendance-filter?employee_id=${
        useratt[0].employee_id
      }&date_after=${data[0].format("YYYY-MM-DD")}&date_before=${data[1].format(
        "YYYY-MM-DD"
      )}`;
      axios(SearchUrl).then((response) => {
        if (response?.data) {
          setAtt(response?.data);
        } else {
          swal({
            title: "Warning",
            text: "Data is Not Available for search 🤷🏻‍♂️",
            icon: "https://i.giphy.com/media/05xkZpZokVu7pLxKtm/giphy.webp",
            dangerMode: true,
          });
        }
      });
    } else {
      setAtt([]);
    }
  };


  const fetchTeamAtt = () => {
    axios.get(`${environment.baseUrl}get-lead-att/${userId}`)
    .then(res => {
      setTeamAtt(res.data)
    })
    .catch(err =>console.log('err', err))
  }

  useEffect(()=>{
      fetchTeamAtt();
  },[])

  return (
    <>
      {modal && (
        <CommentPop modaldata={modal} modal={setModal} data={comments} />
      )}
      <section className="main-wrapper-section px-4 pb-5 ">
        <div className="dashboard-heading pb-3  mb-4  px-0 d-flex align-items-center">
          <span className="base-text-color base-font-700 fs-4">
            <CalendarTick size="35" variant="Bulk" />
          </span>
          <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
           Team Member Attendance
          </h4>
        </div>
        <div className="bg-white py-5 px-5 d-flex gap-5 flex-column rounded-tb shadow">
          <div className="">
            {/* <div className="d-flex align-items-center justify-content-end pb-4">
              <div className="">
                <Space direction="vertical" size={12}>
                  <RangePicker
                    onChange={(e: any) => {
                      onSubmit(e);
                    }}
                  />
                </Space>
              </div>
            </div> */}
            {teamAtt.length != 0 ? (
              <Table columns={columns} data={teamAtt} />
            ) : (
              <EmptyTable />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamAttendance;
