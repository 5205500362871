import React from "react";
import errorImage from "../../../image/error.gif";

const ErrorPage = () => {
  return (
    <>
      <div className="container h-100">
        <div className="d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-between align-items-center flex-column gap-4 pt-5 text-center">
            <h1
              className="base-font-bold text-base-color"
              style={{ fontSize: "5rem" }}
            >
              404
            </h1>
            <h1 className="base-font-pop fs-1">Page Not Found</h1>
            <h4 className="base-font-pop text-base-gray fs-4 text-center">
              Sorry,The page you are looking for does not exist.....
            </h4>
            <img src={errorImage} alt="Error" className="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
