import React, { useEffect, useState } from "react";
import axios from "axios";
import { UserTick } from "iconsax-react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { environment } from "../../../env";
import Table from "../../Table/Table";
import EmptyTable from "../../errorComponents/EmptyTable";
import { TableColumn } from "react-data-table-component";
import TeamMembar from "../../../Models/TeamMembar";
import AddTeam from "../../AddTeam";

interface DataRow {
    employee_id: any;
    firstname: any;
    lastname: any;
    email_id: any;
    phone_no: any;
    profile_pic: any;
}

function TeamMembers() {
    const user = useSelector((state: any) => state?.root?.reducers?.userData);
    const [allUsers, setAllUsers] = useState([])
    const dispatch = useDispatch();

    const getAllUsers = async () => {
        try {
            const res = await axios.get(`${environment.baseUrl}get-lead-members/${user.employee_id}`);
            if (res.data) {
                console.log("sdfsadfsadfsadf", res.data.length)
                setAllUsers(res.data)
            }
        } catch (err) {
            console.log("err");
        }
    };

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Emp ID',
            selector: row => row?.employee_id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Name',
            selector: row => `${row?.firstname} ${row?.lastname}`,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
        {
            name: 'Email ID',
            selector: row => row?.email_id,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        }, {
            name: 'Phone No.',
            selector: row => row?.phone_no,
            sortable: true,
            style: {
                fontSize: "2.3rem",
            }
        },
    ];

    useEffect(() => { getAllUsers() }, []);

    return (
        <>
            <div className="dashboard_main px-4 mt-3">
                <nav>
                    <div className=" ">
                        <div className="d-flex justify-content-between align-items-center p-0">
                            <div className="dashboard-heading  px-0 d-flex align-items-center">
                                <span className="base-text-color base-font-700 fs-4">
                                    <UserTick size="35" variant="Bulk" />
                                </span>
                                <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                                    Team Members
                                </h4>
                            </div>
                            <div className="d-flex justify-content-between align-items-center gap-4">
                                {/* <div>
                  <UploadDoc />
                </div> */}
                                {/* <AddTeam/> */}
                                <div className="">
                                    {/* <MailModal /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <section className="mt-5">
                    <div className="bg-white pt-4 pb-3 px-4 d-flex gap-5 flex-column rounded-5 shadow">
                    <div className="d-flex align-items-center justify-content-end pe-5">
                        <div className="">
                            <AddTeam />
                        </div>
                    </div>
                        {allUsers ? <Table columns={columns} data={allUsers} />
                            : <EmptyTable />}
                    </div>
                </section>
            </div>
        </>
    );
}

export default TeamMembers;
