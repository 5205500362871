import React, { useState } from 'react';
import swal from "sweetalert";
import axios from 'axios';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
} from 'reactstrap';
import { S3_BUCKET, environment, link, myBucket } from '../env';
import { Gift, Send } from 'iconsax-react';
import { useDispatch } from 'react-redux';
import { userDataAction } from '../userRedux/actions';

const RewardsModal = (props: any) => {
    var date = Math.round(+new Date() / 1000);
    const [modal, setModal] = useState(false);
    const EmpId = localStorage.getItem('employee_id')
    const [upload, setupload] = useState<any>()
    const [rewards, setRewards] = useState(
        {
            EmpId: `${EmpId}`,
            doc_type: 'Rewards',
            count: 0,
            doc_info: ''
        }
    );

    const toggle = () => { setModal(!modal) };

    const Image = async (data: any) => {
        const params = {
            ACL: "public-read",
            Body: data,
            Bucket: S3_BUCKET,
            Key: `document_${EmpId}_${date}_${data.name}`,
        };
        myBucket.upload(params, function (err: any, uploadData: any) {
            if (uploadData) {
                data = uploadData.Location;
                return (data);
            } else {
                console.log("error", err);
            }
        });
    }

    const handlefile = (e: any) => {
        let f = e.target.files[0];
        if (f) {
            const fileList = e.target.files;
            setupload(fileList[0])
            let r = new FileReader();

            r.onload = function (e) {
                let contents = `${e.target?.result}`;
                let res = contents.split(' ');
                setRewards({
                    ...rewards,
                    count: res.length
                });
            }
            r.readAsText(f);
        }
    }


    const uploadJoiningFile = async () => {
        let form_data = new FormData();
        form_data.append('employee_id', rewards.EmpId)
        form_data.append('doc_type', rewards.doc_type)
        form_data.append('doc_info', `${rewards.count}`)
        if (upload && upload.name) {
            Image(upload)
            form_data.append('file', `${link}document_${EmpId}_${date}_${upload.name}`)
        }
        try {
            const res = await axios.post(`${environment.baseUrl}User-document-upload`, form_data);
            return res.data
        }
        catch (err) {
            console.log('err');
            return err
        }
        return
    };
    const onSubmit = async (e: any) => {
        uploadJoiningFile().then((docResponse) => {
            if (docResponse.status === 'success') {
                if (EmpId) {
                    getuserData(EmpId)
                }
                swal({
                    title: "Success",
                    text: 'Document Uploaded Sucessfully',
                    icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                }).then(
                    props.update(true)
                ).then(toggle)
            }
        });
    }

    const submit = (e: any) => {
        e.preventDefault();
        axios.post(`${environment.baseUrl}rewards/${EmpId}`, {
            count: rewards.count
        })
    }

    const dispatch = useDispatch();
    const getuserData = async (EmpId: string) => {
        await axios.get(`${environment.baseUrl}user-details/${EmpId}`)
            .then(res => {
                dispatch(userDataAction(res.data))
            })
            .catch(res => console.log("error", res))
    }

    const two = (e: any) => {
        submit(e);
        onSubmit(e);
    }

    return (
        <>
            <Button className='tb-btn base-font-muli fw-bold ' onClick={toggle}>
                Rewards
                <Gift size="32" variant="Bulk" className='ps-2' />
            </Button>
            <Modal isOpen={modal} toggle={toggle} size="md" centered>

                <ModalHeader toggle={toggle} className="py-2"><span className='base-font-muli fs-4 text-base-color fw-bold '>Rewards</span></ModalHeader>
                <ModalBody className='d-flex justify-content-center '>
                    <Form >
                        <input
                            name="file"
                            type="file"
                            id="file"
                            onChange={handlefile}
                            accept="text/plain"
                            accessKey="u"
                            className="base-font-pop pb-3"
                        />

                        <h5 className="base-font-pop fs-6">Your Document has <span id="display_File_count" className='text-base-color base-font-pop fw-bold'>{rewards.count}</span><span> words</span></h5>
                    </Form>
                </ModalBody>
                <ModalFooter className='border-0'>
                    <button onClick={two} className="submit-btn">
                        Submit
                    </button>
                    <button onClick={toggle} className="close-btn">
                        Close
                    </button>
                </ModalFooter>
            </Modal >
        </>
    )
}

export default RewardsModal