import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, Button, FormFeedback, ModalFooter } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { environment } from '../../../env';
import axios from 'axios';
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const userDetails = useSelector(
    (state: any) => state?.root?.reducers?.userData
  );
  const { handleSubmit, control, watch, setError, reset } = useForm();
  const [isOpen, setIsOpen] = useState(false);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const new_password = watch('password');
  const confirm_password = watch('confirm_password');
  const navigate = useNavigate();

  const onSubmit = (data: any) => {
    if (data.password !== data.confirm_password) {
      setError('confirm_password', {
        type: 'manual',
        message: 'New password and confirm password must match.',
      });
    }
    else if (data.password === '' && data.confirm_password === '') {
      setError('password', {
        type: 'manual',
        message: 'Please Enter password.',
      });
    }
    else {
      console.log("else")
      console.log(data);
      callApi(data);
    }
  };

  const callApi = async (data: any) => {
    try {
      console.log('passworddata', data);
      const response = await axios.post(`${environment.baseUrl}reset-password/`, data);

      if (response.data.status === 'success') {
        swal({
          title: "Password Reset Successfully",
          icon: 'https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g',
        }).then(() => {
          navigate("/");
          reset(); // Assuming reset() is a function to clear the form data (you may have a different implementation)
        });
      }
    } catch (error) {
      console.log("Error:", error);
    }
  }

  const handleClose = () => {
    setIsOpen(!isOpen)
    reset();
  };

  return (
    <>
      <Button
        className="tb-btn base-font-muli fw-bold d-flex align-items-center "
        onClick={handleClose}>
        <span>Reset Password</span>
      </Button>
      <Modal centered className='modalstyle' isOpen={isOpen} toggle={handleClose}>
        <ModalHeader className='py-3 px-5' toggle={handleClose}>
          <span className="base-font-muli fs-4 text-base-color fw-bold">Change Password</span>
        </ModalHeader>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody className="pt-3 px-5">
            <FormGroup>
              <Label for="email_id" className='fw-bold text-base-black fs-5'>Email</Label>
              <Controller
                name="email_id"
                control={control}
                defaultValue={userDetails.email_id}
                rules={{
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                }}
                render={({ field, fieldState }) => (
                  <div>
                    <Input type="email" {...field} invalid={fieldState.invalid} readOnly />
                    {fieldState.error && <FormFeedback>{fieldState.error.message}</FormFeedback>}
                  </div>
                )}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password" className='fw-bold text-base-black fs-5'>New Password</Label>
              <div className="password-input-container">
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type={showNewPassword ? 'text' : 'password'}
                      {...field}
                    />
                  )}
                />
                <div className="show-password-checkbox ms-4 mt-1">
                  <Input
                    type="checkbox"
                    id="showNewPasswordCheckbox"
                    checked={showNewPassword}
                    onChange={() => setShowNewPassword(!showNewPassword)}
                  />
                  <Label for="showNewPasswordCheckbox">Show Password</Label>
                </div>
              </div>
            </FormGroup>
            <FormGroup>
              <Label for="confirm_password" className='fw-bold text-base-black fs-5'>Confirm Password</Label>
              <div className="password-input-container">
                <Controller
                  name="confirm_password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type={showConfirmPassword ? 'text' : 'password'}
                      {...field}
                      invalid={confirm_password !== new_password}
                    />
                  )}
                />
                {confirm_password !== new_password && (
                  <FormFeedback>
                    New password and confirm password must match.
                  </FormFeedback>
                )}
                <div className="show-password-checkbox ms-4 mt-1">
                  <Input
                    type="checkbox"
                    id="showConfirmPasswordCheckbox"
                    checked={showConfirmPassword}
                    onChange={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                  <Label for="showConfirmPasswordCheckbox">Show Password</Label>
                </div>
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter className="border-0">
            <Button className="submit-btn" color="primary" type="submit">
              Submit
            </Button>
            <button className="close-btn" onClick={() => { handleClose() }}>
              Close
            </button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default ResetPassword;
