import React, { useState } from 'react';

import { format } from 'date-fns';
import { DayPicker } from 'react-day-picker';

const CalendarCard = () => {

  const today = new Date();
  const [selectedDay, setSelectedDay] = useState<Date | undefined>(today);

  const footer = selectedDay ? (
    <p className='base-font-muli fw-bold text-base-color fs-6 mb-0  pt-3 text-center'>🌤 Today Date {format(selectedDay, 'PPP')}</p>
  ) : (
    <p>Please pick a day.</p>
  );

  return (
    <div className="meeting-box-main py-1 rounded-5 shadow" style={{ right: "4%" }} >
      <div className="Todo-lists-main p-2 px-1 pb-0">
        <DayPicker
          mode="single"
          required
          selected={selectedDay}
          onSelect={setSelectedDay}
          footer={footer}
        />
      </div>
    </div>

  );
}

export default CalendarCard
