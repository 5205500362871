import {
  ArrowRight2,
  BoxAdd,
  CalendarAdd,
  ClipboardText,
  Cup,
  Edit2,
  ElementPlus,
  FlashCircle,
  Graph,
  NoteRemove,
  Profile2User,
} from "iconsax-react";
import React, { useEffect } from "react";
import AnimatedNumbers from "react-animated-numbers";
import userImg from "../../../image/Copyright2.gif";
import PiaGraph from "../../Graphs/PiaGraph";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { environment } from "../../../env";
import { getProjectData, getTasksData, getTeamMembersData, getTeamsData } from "../../../userRedux/actions";
import moment from "moment";

const Project = () => {

  const user = useSelector((state: any) => state?.root?.reducers?.userData);
  const projects = useSelector((state: any) => state?.root?.reducers?.projects);
  const tasks = useSelector((state: any) => state?.root?.reducers?.tasks);
  console.log(tasks)
  const dispatch = useDispatch()

  const getProjects = async () => {
    axios.get(`${environment.baseUrl}employee-projects/${user.id}`)
      .then(res => {
        dispatch(getProjectData(res.data))
      })
      .catch(res => console.log("error"))
  }

  const getTeamMembers = () => {
    axios.get(`${environment.baseUrl}create-team-member`)
      .then(res => {
        dispatch(getTeamMembersData(res.data))
      })
      .catch(res => console.log("error"))
  }

  const getTasks = () => {
    axios.get(`${environment.baseUrl}remainig-tasks/${user.id}`)
      .then(res => {
        if (res.data.code != 5000) {
          dispatch(getTasksData(res.data))
        }
      })
      .catch(res => console.log("error"))
  }

  const getTeams = () => {
    axios.get(`${environment.baseUrl}create-team`)
      .then(res => {
        dispatch(getTeamsData(res.data))
      })
      .catch(res => console.log("error"))
  }

  const RefreshData = () => {
    getProjects();
    getTeamMembers();
    getTasks();
    getTeams()
  }

  useEffect(() => {
    RefreshData();
  }, [])


  return (
    <section className="main-wrapper-section px-4 pb-5 ">
      <div className="dashboard-heading pb-3  px-0 d-flex align-items-center">
        <span className="base-text-color base-font-700 fs-4">
          <Graph size="35" variant="Bulk" />
        </span>
        <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
          Projects
        </h4>
      </div>
      <div className="row m-0">
        <div className={tasks?.filter((task: any) => task.status != "completed" && task.status != "denied")?.length != 0 ? "col-9  d-flex flex-column gap-3 ps-0" : "col-12  d-flex flex-column gap-3 ps-0"}>
          <div className="row m-0 p-3 rounded-3">
            <div className="row m-0">
              <div className={tasks?.filter((task: any) => task.status != "completed" && task.status != "denied")?.length != 0 ? "col-5 " : "col-6"}>
                <h4 className="fs-4 fw-semibold text-black base-font-pop">
                  Graph
                </h4>
              </div>
              <div className={tasks?.filter((task: any) => task.status != "completed" && task.status != "denied")?.length != 0 ? "col-7 " : "col-6"}>
                <h4 className="fs-4 fw-semibold text-black base-font-pop">
                  Project Summary
                </h4>
              </div>
            </div>
            <div
              className={tasks?.filter((task: any) => task.status != "completed" && task.status != "denied")?.length != 0 ? "col-5 graph" : "col-6 graph"}
              style={{ width: "20rem", height: "19rem" }}
            >
              <PiaGraph data={tasks} />
            </div>
            <div className={tasks?.filter((task: any) => task.status != "completed" && task.status != "denied")?.length != 0 ? "col-7 border rounded-4 d-flex justify-content-center  flex-column" : "col-6 border rounded-4 d-flex justify-content-center  flex-column"}>
              <div className="d-flex gap-4">
                <div className="">
                  <div className="d-flex align-items-center gap-3">
                    <ClipboardText size="30" className="light-icon" />
                    <h5
                      className="fs-5 mb-0 base-font-inter light-icon"
                      style={{ fontWeight: 600 }}
                    >
                      Projects Detail
                    </h5>
                  </div>
                  <div className="card border-0 bg-transparent  rounded-2 flex-row gap-3 align-items-center py-3 px-1">
                    <div className="d-flex flex-column gap-2 py-3 px-2 shadow border bg-black rounded-4 card-width">
                      <div
                        className="dashboardBox-card-2 "
                        style={{ backgroundColor: "#f7f7f7a1" }}
                      >
                        <BoxAdd size="30" color="#ffffff" />
                      </div>
                      <h6 className=" fw-semibold mb-0 fs-6 pt-2  base-font-inter text-white">
                        Projects
                      </h6>
                      <h6 className="  fw-semibold mb-0   base-font-inter text-white fs-4 px-1">
                        <AnimatedNumbers
                          animateToNumber={Number(projects?.length ? projects?.length : 0)}
                          configs={(number, index) => {
                            return {
                              mass: 1,
                              tension: 230 * (index + 1),
                              friction: 150,
                            };
                          }}
                        />
                      </h6>
                    </div>
                    <div className="d-flex flex-column gap-2 py-3 px-2 shadow border bg-base-color rounded-4 card-width">
                      <div
                        className="dashboardBox-card-2 "
                        style={{ backgroundColor: "#f7f7f7a1" }}
                      >
                        <ElementPlus size="26" color="#ffffff" />
                      </div>
                      <h6 className=" fw-semibold mb-0 fs-6 pt-2   base-font-inter text-white">
                        Assigned
                      </h6>
                      <h4 className="  fw-semibold mb-0   base-font-inter text-white fs-4 px-1">
                        <AnimatedNumbers
                          animateToNumber={Number(tasks?.filter((task: any) => task.status != "completed" && task.status != "denied" && task.status != "pending")?.length)}
                          configs={(number, index) => {
                            return {
                              mass: 1,
                              tension: 230 * (index + 1),
                              friction: 150,
                            };
                          }}
                        />
                      </h4>
                    </div>
                    <div className="d-flex flex-column gap-2 py-3 px-2 shadow border bg-success rounded-4 card-width">
                      <div
                        className="dashboardBox-card-2 "
                        style={{ backgroundColor: "#f7f7f7a1" }}
                      >
                        <Cup size="30" color="#ffffff" />
                      </div>
                      <h6 className=" fw-semibold mb-0 fs-6 pt-2   base-font-inter text-white">
                        Completed
                      </h6>
                      <h4 className="  fw-semibold mb-0   base-font-inter text-white fs-4 px-1">
                        <AnimatedNumbers
                          animateToNumber={Number(tasks?.filter((task: any) => task.status == "completed")?.length)}
                          configs={(number, index) => {
                            return {
                              mass: 1,
                              tension: 230 * (index + 1),
                              friction: 150,
                            };
                          }}
                        />
                      </h4>
                    </div>
                    <div className="d-flex flex-column gap-2 py-3 px-2 shadow border bg-warning rounded-4 card-width">
                      <div
                        className="dashboardBox-card-2 "
                        style={{ backgroundColor: "#f7f7f7a1" }}
                      >
                        <NoteRemove size="30" color="#ffffff" />
                      </div>
                      <h6 className=" fw-semibold mb-0 fs-6 pt-2  base-font-inter text-white">
                        Pending
                      </h6>
                      <h6 className="  fw-semibold mb-0   base-font-inter text-white fs-4 px-1">
                        <AnimatedNumbers
                          animateToNumber={Number(tasks?.filter((task: any) => task.status == "pending")?.length)}
                          configs={(number, index) => {
                            return {
                              mass: 1,
                              tension: 230 * (index + 1),
                              friction: 150,
                            };
                          }}
                        />
                      </h6>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-1 px-2">
                    <FlashCircle size="26" className="text-warning" />
                    <h5 className="text-base-pop fw-semibold mb-0 pe-3 pt-1">
                      High Priority Tasks
                    </h5>
                    <h5 className=" rounded-pill px-4 py-1 mb-0  bg-base-color d-flex justify-content-center align-items-center">
                      <span className="mb-0 text-white fs-5 text-base-inter fw-semibold">
                        {tasks?.filter((task: any) => task.priority == "High" && task.status != "denied" && task.status != "completed")?.length}
                      </span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="d-flex justify-content-between align-items-center px-2">
              <h3 className="fs-3 fw-semibold light-icon base-font-pop">
                Projects
              </h3>
              <div className="d-flex justify-content-between align-items-center gap-4">
              </div>
            </div>
            <div className="mt-4 overflow-scroll" style={{ height: "20rem" }}>
              {
                <>
                  {projects?.length == 0 ?
                    <>
                      <div className="bg-white py-3 border  px-2  rounded-3 shadow align-items-center row mb-3 mx-0">
                        <div className="col-10 text-center">
                          <h2 className='fs-5 fw-semibold text-base-color base-font-pop'>No Projects Added</h2>
                        </div>
                      </div>
                    </>
                    :
                    projects?.map((data: any, i: any) => {
                      return (
                        <div className="bg-white py-3 border  px-2 justify-content-center  rounded-3 shadow align-items-center mb-3 row mx-0 project_task" key={i}>
                          <NavLink to={`/tasks/${data?.team?.project?.id}`} className="row text-center text-decoration-none  m-0 px-0 col-11 align-items-center">
                            <div className="col-1">
                              <div
                                className="rounded-circle border object-fit-cover overflow-hidden"
                                style={{ width: "3.5rem", height: "3.5rem" }}
                              >
                                <img
                                  src={data?.team?.project?.project_logo != "null" && data?.team?.project?.project_logo != "undefined" ? data?.team?.project.project_logo : userImg}
                                  alt=""
                                  className="w-100 h-100 object-fit-cover"
                                />
                              </div>
                            </div>
                            <h5
                              className="col-3 fs-5 mb-0 text-capitalize  fw-semibold text-base-color base-font-pop text-start"
                            >
                              {data?.team?.project?.project_name.length > 16 ? `${data?.team?.project?.project_name.slice(0, 16)}...` : data?.team?.project?.project_name}
                            </h5>
                            <div
                              className="col-1 d-flex justify-content-center align-items-center "
                              style={{ fontWeight: "500" }}
                            >
                              <h5 className=" fs-5 mb-0 text-se p-3 px-2 rounded-circle   base-font-pop text-uppercase bg-background-color" style={{
                                fontWeight: "600"
                              }}>
                                {data?.team?.project?.project_key.length > 5 ? `${data?.team?.project?.project_key.slice(0, 5)}...` : data?.team?.project?.project_key}
                              </h5>
                            </div>
                            <h5
                              className="col-3 fs-5 text-se text-capitalize  base-font-pop"
                              style={{ fontWeight: "400" }}
                            >
                              {data?.team?.project?.project_type.length > 17 ? `${data?.team?.project?.project_type.slice(0, 17)}...` : data?.team?.project?.project_type}
                            </h5>
                            <h5
                              className="col-4 fs-5 text-se text-capitalize d-flex align-items-center gap-2  base-font-pop"
                              style={{ fontWeight: "400" }}
                            >
                              {data?.team?.project?.project_lead?.firstname} {data?.team?.project?.project_lead?.lastname.length > 10 ? `${data?.team?.project?.project_lead?.lastname.slice(0, 10)}...` : data?.team?.project?.project_lead?.lastname}
                            </h5>
                          </NavLink>
                        </div>
                      )
                    })}
                </>
              }
            </div>
          </div>
        </div>
        {
          tasks?.filter((task: any) => task.status != "completed" && task.status != "denied")?.length != 0
          && <div
            className="col-3 rounded-3 py-3 px-0 "
            style={{ backgroundColor: "#0000000d" }}
          >
            <div className="">
              <div className="d-flex flex-column gap-3 ">
                <h5 className="fs-5 mb-0  fw-semibold text-black base-font-pop px-3">
                  Remaining Tasks
                </h5>
                <div
                  className="overflow-scroll d-flex flex-column gap-3 px-2"
                  style={{ height: "100vh" }}
                >
                  {tasks?.length == 0 ? <>
                    <h2 className='fs-4 ms-2 fw-semibold  base-font-pop'>No Tasks Assigned Yet</h2>
                  </> :
                    <>
                      {tasks?.map((task: any) => {
                        if (task.status != 'completed' && task.status != "denied") {
                          return (
                            <>
                              <NavLink to="/TaskDetails" state={{ from: task }} className="bg-white py-3 border text-decoration-none project_task  px-2  rounded-3 shadow align-items-center">
                                <div className="d-flex justify-content-start flex-column-reverse align-items-start px-2 ">
                                  <h5 className="fs-5 fw-semibold light-icon base-font-pop mb-2  align-items-center d-flex gap-2 text-capitalize">
                                    <div
                                      className="rounded-circle"
                                      style={{
                                        width: "1rem",
                                        height: "1rem",
                                        backgroundColor: `#${Math.floor(
                                          Math.random() * 16777215
                                        ).toString(16)}`,
                                      }}
                                    ></div>
                                    {task?.task_name.length > 21 ? `${task?.task_name.slice(0, 21)}...` : task?.task_name}
                                  </h5>
                                  <div className="d-flex justify-content-between align-items-center w-100 mb-2">
                                    <h5 className="base-font-500 fs-6 mb-1">#{task?.team?.project.project_key.toUpperCase()}-{task?.id}</h5>
                                    <h5 className={task?.status == 'pending' ? 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-yellow' :
                                      task?.status == 'completed' ? 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-green' :
                                        task?.status == 'inprogress' ? 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-blue' : 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-blue'}>
                                      <span className={task?.status == 'pending' ? 'mb-0 fs-5 text-base-inter text-capitalize fw-semibold status-color-yellow' :
                                        task?.status == 'completed' ? 'mb-0 fs-5 text-base-inter text-capitalize fw-semibold status-color-green' :
                                          task?.status == 'inprogress' ? 'mb-0 fs-5 text-base-inter text-capitalize fw-semibold status-color-blue' : 'mb-0 fs-5 text-base-inter text-capitalize fw-semibold status-color-blue'} >
                                        {task?.status}
                                      </span>
                                    </h5>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center  justify-content-between">
                                  <h5 className={task?.priority == 'High' ? 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-red' :
                                    task?.priority == 'Normal' ? 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-green' :
                                      task?.priority == 'Low' ? 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-blue' : 'rounded-pill px-4 py-1 mb-0  d-flex justify-content-center align-items-center status-bg-blue'}>
                                    <span className={task?.priority == 'High' ? 'mb-0 fs-5 text-base-inter fw-semibold status-color-red' :
                                      task?.priority == 'Normal' ? 'mb-0 fs-5 text-base-inter fw-semibold status-color-green' :
                                        task?.priority == 'Low' ? 'mb-0 fs-5 text-base-inter fw-semibold status-color-blue' : 'mb-0 fs-5 text-base-inter fw-semibold status-color-blue'} >
                                      {task?.priority}
                                    </span>
                                  </h5>
                                  <div className="d-flex gap-2 align-items-center">
                                    <CalendarAdd size="26" />
                                    <h5 className="text-base-pop fw-semibold mb-0 pe-3">
                                      {moment(task?.due_date.split("T")[0]).format("DD/MM/YYYY")}
                                    </h5>
                                  </div>
                                </div>
                              </NavLink>
                            </>
                          )
                        }
                      })
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        }

      </div>
    </section>
  );
};

export default Project;
