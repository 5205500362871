import React, { useState } from "react";
import "../../../css/BasicDetails.css";
import swal from "sweetalert";
import axios from "axios";
import { Button, Card, CardBody, CardFooter, Input, Label } from "reactstrap";
import { environment } from "../../../env";
import { Bank, Edit, Trash, UserEdit } from "iconsax-react";

const BankDetails = (props: any) => {
  const [userDetail, setUserDetail] = useState({
    bank_name: props.details.bank_name,
    acc_holder_name: props.details.acc_holder_name,
    acc_number: props.details.acc_number,
    acc_ifsc_number: props.details.acc_ifsc_number,
    acc_type: props.details.acc_type,
    acc_branch: props.details.acc_branch,
  });
  let [show, setShow] = useState(true);

  const handleOnChange = (e: any) => {
    setUserDetail({
      ...userDetail,
      [e.target.name]: e.target.value,
    });
  };

  const submit = (e: any) => {
    e.preventDefault();
    // axios.post('url', {
    axios
      .put(
        `${environment.baseUrl}update-basic-details/${props.details.employee_id}`,
        {
          bank_name: `${userDetail.bank_name}`,
          acc_holder_name: `${userDetail.acc_holder_name}`,
          acc_number: `${userDetail.acc_number}`,
          acc_ifsc_number: `${userDetail.acc_ifsc_number}`,
          acc_type: `${userDetail.acc_type}`,
          acc_branch: `${userDetail.acc_branch}`,
        }
      )
      .then((res) => {
        if (res.status == 200) {
          swal({
            title: "Success",
            text: "Bank Details is updated Successfully !",
            icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
          });
          props.update(!props.update);
          setShow(!show);
        }
      })
      .catch((res) => console.log("error"));
  };

  return (
    <div>
      <Card className="mt-3 mx-3 mb-2 px-3 border-0 position-relative">
        <div className="">
          <div className="d-flex align-items-center justify-content-between">
            <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
              <span className="base-text-color base-font-700 fs-4">
                <Bank size="35" variant="Bulk" />
              </span>
              <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                Bank Details
              </h4>
            </div>
            {show && (
              <Button
                className="tb-btn base-font-muli fw-bold d-flex align-items-center "
                onClick={() => {
                  setShow(!show);
                }}
              >
                <span>Edit</span>
                <UserEdit size="32" variant="Bulk" className="ps-2" />
              </Button>
            )}
          </div>
        </div>
        {show ? (
          <>
            <CardBody className="p-0 py-2">
              <div className=" row px-3">
                <div className="col-4">
                  <Label className="BDLabels base-font-muli fw-bold fs-5  ">
                    Bank Name
                  </Label>
                  <Label className="BDLabelInput">
                    {userDetail.bank_name == "null" ? (
                      <span className="d-flex justify-content-center w-25">
                        {" "}
                        -{" "}
                      </span>
                    ) : (
                      userDetail.bank_name
                    )}
                  </Label>
                </div>
                <div className="col-4">
                  <Label className="BDLabels base-font-muli fw-bold fs-5  ">
                    Account Number
                  </Label>
                  <Label className="BDLabelInput">
                    {userDetail.acc_number == "null" ? (
                      <span className="d-flex justify-content-center w-25">
                        {" "}
                        -{" "}
                      </span>
                    ) : (
                      userDetail.acc_number
                    )}
                  </Label>
                </div>
                <div className="col-4">
                  <Label className="BDLabels base-font-muli fw-bold fs-5  ">
                    Account Type
                  </Label>

                  <Label className="BDLabelInput">
                    {userDetail.acc_type == "null" ? (
                      <span className="d-flex justify-content-center w-25">
                        {" "}
                        -{" "}
                      </span>
                    ) : (
                      userDetail.acc_type
                    )}
                  </Label>
                </div>
                <div className="col-4">
                  <Label className="BDLabels base-font-muli fw-bold fs-5  ">
                    Account Holder's Name
                  </Label>
                  <Label className="BDLabelInput">
                    {userDetail.acc_holder_name == "null" ? (
                      <span className="d-flex justify-content-center w-100">
                        {" "}
                        -{" "}
                      </span>
                    ) : (
                      userDetail.acc_holder_name
                    )}
                  </Label>
                </div>
                <div className="col-4">
                  <Label className="BDLabels base-font-muli fw-bold fs-5  ">
                    IFSC Code
                  </Label>
                  <Label className="BDLabelInput">
                    {userDetail.acc_ifsc_number == "null" ? (
                      <span className="d-flex justify-content-center w-75">
                        {" "}
                        -{" "}
                      </span>
                    ) : (
                      userDetail.acc_ifsc_number
                    )}
                  </Label>
                </div>
                <div className="col-4">
                  <Label className="BDLabels base-font-muli fw-bold fs-5  ">
                    Branch Name
                  </Label>
                  <Label className="BDLabelInput">
                    {userDetail.acc_branch == "null" ? (
                      <span className="d-flex justify-content-center w-75">
                        {" "}
                        -{" "}
                      </span>
                    ) : (
                      userDetail.acc_branch
                    )}
                  </Label>
                </div>
              </div>
            </CardBody>
          </>
        ) : (
          <>
            <form
              id="details"
              onSubmit={(e) => {
                submit(e);
              }}
            >
              <CardBody className="p-0 py-2">
                <div className="row px-2 ">
                  <div className="col-6">
                    <Label className="BDLabels base-font-muli fw-bold fs-6  ">
                      Bank Name
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="Bank_name"
                      name="bank_name"
                      value={
                        userDetail.bank_name == "null"
                          ? (userDetail.bank_name = "")
                          : userDetail.bank_name
                      }
                      onChange={handleOnChange}
                      placeholder="Bank Name"
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className="BDLabels base-font-muli fw-bold fs-6  ">
                      Account Number
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="acc_number"
                      name="acc_number"
                      value={
                        userDetail.acc_number == "null"
                          ? (userDetail.acc_number = "")
                          : userDetail.acc_number
                      }
                      onChange={handleOnChange}
                      placeholder="Account Number"
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className="BDLabels base-font-muli fw-bold fs-6  ">
                      Account Type
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="acc_type"
                      name="acc_type"
                      value={
                        userDetail.acc_type == "null"
                          ? (userDetail.acc_type = "")
                          : userDetail.acc_type
                      }
                      onChange={handleOnChange}
                      placeholder="Account Type"
                    ></Input>
                  </div>
                  <div className="width-right w-50">
                    <Label className="BDLabels base-font-muli fw-bold fs-6  ">
                      Account Holder's Name
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="acc_holder_name"
                      name="acc_holder_name"
                      value={
                        userDetail.acc_holder_name == "null"
                          ? (userDetail.acc_holder_name = "")
                          : userDetail.acc_holder_name
                      }
                      onChange={handleOnChange}
                      placeholder="Account Holder's Name"
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className="BDLabels base-font-muli fw-bold fs-6  ">
                      IFSC Code
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="acc_ifsc_number"
                      name="acc_ifsc_number"
                      value={
                        userDetail.acc_ifsc_number == "null"
                          ? (userDetail.acc_ifsc_number = "")
                          : userDetail.acc_ifsc_number
                      }
                      onChange={handleOnChange}
                      placeholder="IFSC Code"
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className="BDLabels base-font-muli fw-bold fs-6  ">
                      Branch Name
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="branch_name"
                      name="acc_branch"
                      value={
                        userDetail.acc_branch == "null"
                          ? (userDetail.acc_branch = "")
                          : userDetail.acc_branch
                      }
                      onChange={handleOnChange}
                      placeholder="Branch Name"
                    ></Input>
                  </div>
                </div>
              </CardBody>
              <CardFooter className="border-0 bg-transparent pt-4">
                <div className="d-flex justify-content-end gap-3">
                  <button className="update-btn">
                    Update
                    <Edit size="28" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                  <button
                    className="close-btn"
                    onClick={() => {
                      setShow(!show);
                    }}
                  >
                    Cancel
                    <Trash size="24" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                </div>
              </CardFooter>
            </form>
          </>
        )}
      </Card>
    </div>
  );
};

export default BankDetails;
