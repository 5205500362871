import React from 'react'

const ErrorComponents = ({ error }: any) => {
  return (
    <>
      <div className="fs-6 base-font-500 text-left mt-1"><span className='text-danger '>{error}</span></div>
    </>
  )
}

export default ErrorComponents