import React, { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";
import AddDocument from "./addDocumentModel";
import { environment } from "../../env";
// import kamlesh from '../../pdf/kamlesh-JoiningLetter.pdf';
import PDFViewer from "pdf-viewer-reactjs";
import { NavLink } from "react-router-dom";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Table from "../Table/Table";
import EmptyTable from "../errorComponents/EmptyTable";
import { DocumentText, DocumentText1, ReceiptEdit } from "iconsax-react";

interface UploadDocumentData {
  employee_id: any;
  doc_type: any;
  doc_info: any;
  created_date: any;
  file: any;
}

const UserDocuments = () => {
  const EmpId = localStorage.getItem("employee_id");
  const [uploadDoc, setUploadDoc] = useState<UploadDocumentData[]>([]);
  const [update, setUpdate] = useState(false);

  const GetUploadDocument = async () => {
    await axios
      .get(`${environment.baseUrl}get-documents/${EmpId}`)
      .then((res) => {
        setUploadDoc(res.data);
      })
      .catch((res) => console.log("Upload doc error"));
  };

  const ExamplePDFViewer = (file: any) => {
    window.open(`${file}`);
  };

  const columns: TableColumn<UploadDocumentData>[] = [
    {
      name: "EmpId",
      selector: (row) => row.employee_id,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Document Type",
      selector: (row) => row.doc_type,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Document Name",
      selector: (row) => row.doc_info,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Upload Date",
      selector: (row) => moment(row.created_date).format("DD/MM/YYYY"),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "View File",
      selector: (row) => (
        <Link onClick={() => ExamplePDFViewer(row.file)} to="">
          View
        </Link>
      ),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
  ];

  useEffect(() => {
    GetUploadDocument();
  }, [update]);

  return (
    <>
      <section className="main-wrapper-section ">
        <div className=" py-3 px-5 d-flex gap-5 flex-column">
          <div className="">
            <div className="d-flex align-items-center justify-content-between pb-4">
              <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
                <span className="base-text-color base-font-700 fs-4">
                  <DocumentText size="35" variant="Bulk" />
                </span>
                <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                  Documents
                </h4>
              </div>
              <AddDocument update={setUpdate} />
            </div>
            {uploadDoc[0] ? (
              <Table columns={columns} data={uploadDoc} />
            ) : (
              <EmptyTable />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDocuments;
