import { Modal, ModalBody, ModalFooter } from 'reactstrap';

const HolidaysInfo = (props: any) => {
    return (
        <div>
            <Modal isOpen={props.modal} centered className='modalstyle' size='md' >
                <ModalBody >
                    <div className=''>
                        <h2 className="fs-4 text-base-color base-font-pop fw-semibold text-center pb-3 ">Holiday Information</h2>
                        <h1 className="fs-2 text-base-red base-font-pop fw-semibold text-center pt-2">{props.selectedinfo}</h1>
                    </div>
                </ModalBody>
                <ModalFooter className='border-0 justify-content-center'>
                    <button onClick={() => props.set(false)} className="close-btn">
                        Close
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default HolidaysInfo;