import React, {  useState } from 'react';
import moment from 'moment';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
} from 'reactstrap';
import axios from 'axios';
import swal from 'sweetalert';
import { environment } from '../../env';
import { DocumentText1 } from 'iconsax-react';
import { useSelector } from 'react-redux';

function Mispunch(props: any) {
    let EmpId = localStorage.getItem('employee_id')
    const [modal, setModal] = useState(false);

    const user = useSelector((state: any) => state?.root?.reducers?.userData)

    const [backdrop, setBackdrop] = useState(true);
    const [data, setData] = useState({
        date: '',
        timein: '',
        timeout: '',
        comment: ''
    })

    const [keyboard, setKeyboard] = useState(true);

    const toggle = () => setModal(!modal);

    const handle = (e: any) => {
        const newdata: any = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
    }

    const submit = (e: any) => {
        props.update
        e.preventDefault();
        // axios.post('url', {
        axios.post(`${environment.baseUrl}mispunch/`, {
            employee_id: EmpId,
            date: data.date,
            day: data.date,
            login_time: moment(data.timein, 'HH:mm').format(),
            logout_time: moment(data.timeout, 'HH:mm').format(),
            mispunch: "True",
            remark: "pending",
            comment: data.comment,
            user: user.id
        }).then(
            res => {
                if (res.status == 200) {
                    props.update(true);
                    setData({
                        date: '',
                        timein: '',
                        timeout: '',
                        comment: ''
                    })
                    swal({
                        title: "Success",
                        text: 'Mispunch Applied Successfully !',
                        icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                    })
                }

            }
        ).catch(res => console.log("error"))
    }
        
    return (
        <div>
            <Form inline onSubmit={(e) => e.preventDefault()}>
                {props.modal == true ?
                       <button onClick={toggle} className="submit-btn">
                       Okay
                   </button>
                :<Button  className='tb-btn base-font-muli fw-bold ' onClick={toggle}>
            Apply Mispunch  <DocumentText1 size="32" variant="Bulk" className='ps-1'/>
            </Button>}
            </Form>
            <Modal
                isOpen={modal}
                toggle={toggle}
                backdrop={backdrop}
                keyboard={keyboard}
                centered
                size='lg'
                className='mispunch-modal'
            >
                <ModalHeader toggle={toggle} className="py-3 px-3"><span className='base-font-muli fs-4 text-base-color fw-bold px-3 '>Mispunch</span></ModalHeader>
                <ModalBody className='p-4'>
                    <Form id='details' onSubmit={(e) => { submit(e) }}>
                        <h2 className="fs-2 mb-0 base-font-muli fs-4 text-black fw-bold">
                            Name : <span className='fs-2 mb-0 base-font-muli fs-4 text-base-color fw-bold'>{user.firstname} {user.lastname}
                            </span>
                        </h2>
                        <div className='pt-4'>
                            <div className='row mb-2'>
                                <div className="col-6">
                                <Label for='date' className="text-black base-font-muli fw-bold fs-5">
                                    Date
                                </Label>
                                <Input
                                    className='py-1 fs-6 shadow-none input-border'
                                    id="date"
                                    name="date"
                                    type="date"
                                    value={data.date}
                                    onChange={(e) => handle(e)}
                                />
                                </div>
                                <div className="col-6">
                                <Label className="text-black base-font-muli fw-bold fs-5">
                                    Day
                                </Label>
                                <br />
                                <Label value="default" className="py-1 fs-5 shadow-none input-border base-font-pop w-100 fw-bold text-success">
                                    {moment(data.date).format('dddd')}
                                </Label>
                               </div>
                            </div>
                            <div className='row mb-3'>
                            <div className="col-6">
                            <Label for='timein' className="text-black base-font-muli fw-bold fs-5">
                                    Time In
                                </Label>
                                <Input
                                    className='py-1 fs-6 shadow-none input-border'
                                    id="timein"
                                    name="timein"
                                    type="time"
                                    value={data.timein}
                                    onChange={(e) => handle(e)}
                                />
                                </div>
                                <div className="col-6">
                                <Label for='timeout' className="text-black base-font-muli fw-bold fs-5">
                                    Time Out
                                </Label>
                                <Input
                                    className='py-1 fs-6 shadow-none input-border'
                                    id="timeout"
                                    name="timeout"
                                    type="time"
                                    value={data.timeout}
                                    onChange={(e) => handle(e)}
                                /></div>
                            </div>
                        </div>
                        <div>
                            <Label for='comment' className="text-black base-font-muli fw-bold fs-5">
                                Comment
                            </Label>

                            <textarea
                                className='py-1 px-1 fs-5 base-font-pop w-100  input-textarea shadow-none border-primary rounded-2'
                                style={{outline:"none"}}
                                id="comment"
                                name="comment"
                                placeholder="Comment"
                                value={data.comment}
                                onChange={(e) => handle(e)}
                            />
                        </div>

                    </Form>


                </ModalBody>
                <ModalFooter
                    className="py-3"
                >
                    <button
                        type="submit"
                        form="details"
                        value="Submit"
                        onClick={toggle}
                        className="submit-btn"
                    >Submit</button>
                    <button  onClick={toggle}
                        className="close-btn"
                    >
                        Cancel
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default Mispunch;