export const environment = {
  // --------------------Local Api Url--------------------

  // baseUrl: 'http://127.0.0.1:8000/',

  // imageUrl: 'http://127.0.0.1:8000/',
  // ----------------------------------------------------

  // --------------------Live Api Url--------------------

  baseUrl: "http://3.6.88.46/",
  imageUrl: "http://3.6.88.46/",
  // ----------------------------------------------------JN180370243
};

// === AWS Configuration ===
import AWS from 'aws-sdk'
window.Buffer = window.Buffer || require("buffer").Buffer;

export const S3_BUCKET = 'instalogisticts-media-techiebears';
export const REGION = 'ap-south-1';
// export const S3_BUCKET = 'smartshopassests';
// export const REGION = 'us-east-1';


export const awsConfigration = AWS.config.update({
  accessKeyId: 'AKIA3UY6PSUDMRLGKBP7',
  secretAccessKey: '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'
})


// // === Bucket configuration ===

export const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})

// == LINK == 
export const link = 'https://instalogisticts-media-techiebears.s3.ap-south-1.amazonaws.com/'
