import { ResponsivePie } from "@nivo/pie";
import { useMemo } from "react";
var data = [
  {
    id: "pending",
    label: "pending",
    value: 0,
    color: "hsl(41, 70%, 50%)",
  },
  {
    id: "completed",
    label: "completed",
    value: 0,
    color: "hsl(299, 70%, 50%)",
  },
];
const PiaGraph = (dataa: any) => {
  const FilterData = () => {
    data[0].value = 0;
    data[1].value = 0;
    dataa?.data?.map((task: any) => {
      if (task.status == "inprogress" || task.status == "pending") {
        data[0].value = data[0].value + 1;
      } else if (task.status == "completed") {
        data[1].value = data[1].value + 1;
      }
    });
    let total = data[0].value + data[1].value;
    data[0].value = (data[0].value / total) * 100;
    data[1].value = (data[1].value / total) * 100;
  };
  useMemo(() => {
    FilterData();
  }, [dataa]);
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 10, right: 30, bottom: 30, left: 30 }}
      innerRadius={0.5}
      padAngle={1.4}
      cornerRadius={4}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      colors={{ scheme: "paired" }}
      borderColor={{
        from: "color",
        modifiers: [["darker", 0.2]],
      }}
      enableArcLinkLabels={false}
      valueFormat={(value) => `${Math.round(value)}%`}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{ from: "color" }}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor="#FFF"
      legends={[]}
      theme={{
        fontSize: 15,
        fontFamily: "Nunito', sans-serif",
        tooltip: {
          container: {
            background: "#fff",
          },
        },
      }}
    />
  );
};

export default PiaGraph;
