import React, { useState, useRef, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import swal from "sweetalert";
import { Select } from '@mui/material';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    Label,
    Form,
    FormGroup,
} from 'reactstrap';
import axios from 'axios';
import { S3_BUCKET, environment, link, myBucket } from '../../env';
import { useForm } from 'react-hook-form';
import { DocumentUpload } from 'iconsax-react';

interface UserData {
    firstname: any;
    lastname: any;

}


const AddDocument = (props: any) => {
    const [modal, setModal] = useState(false);
    const [upload, setupload] = useState<any>();
    var date = Math.round(+new Date() / 1000);


    let EmpId = localStorage.getItem("employee_id");

    const [UploadingDetails, setUploadingDetails] = useState(
        {
            EmpId: `${EmpId}`,
            doc_type: '',
            doc_info: '',
            file: '',
        }
    );


    const toggle = () => { setModal(!modal) };

    const handleOnChange = (event: any) => {

        setUploadingDetails({
            ...UploadingDetails,
            [event.target.name]: event.target.value
        });
    }

    const handleOnFileChange = (e: any) => {
        const fileList = e.target.files;
        setupload(fileList[0])
    };

    const Image = async (data: any) => {
        const params = {
            ACL: "public-read",
            Body: data,
            Bucket: S3_BUCKET,
            Key: `document_${EmpId}_${date}_${data.name}`,
        };
        myBucket.upload(params, function (err: any, uploadData: any) {
            if (uploadData) {
                data = uploadData.Location;
                return (data);
            } else {
                console.log("error", err);
            }
        });
    }

    const uploadJoiningFile = async (document: any) => {
        let form_data = new FormData();
        form_data.append('employee_id', UploadingDetails.EmpId)
        form_data.append('doc_type', UploadingDetails.doc_type)
        form_data.append('doc_info', UploadingDetails.doc_info)
        if (upload && upload.name) {
            Image(upload)
            form_data.append('file', `${link}document_${EmpId}_${date}_${upload.name}`)
        }

        try {
            const res = await axios.post(`${environment.baseUrl}User-document-upload`, form_data);

            return res.data
        }
        catch (err) {
            console.log('err');
            return err

        }
        return

    };
    const onSubmit = async (document: any) => {
        uploadJoiningFile(document).then((docResponse) => {
            if (docResponse.status === 'success') {
                swal({
                    title: "Success",
                    text: 'Document Uploaded Sucessfully',
                    icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                })
                props.update(true)
                setModal(!modal)

            }
        });
    }


    return (
        <>
            <Button className='tb-btn base-font-muli fw-bold ' onClick={toggle}>
                Add Document
                <DocumentUpload size="32" variant="Bulk" className='ps-2' />
            </Button>
            <Modal isOpen={modal} toggle={toggle} size="md" centered className='modalstyle'>
                <ModalHeader toggle={toggle} className="py-3 px-5"><span className='base-font-muli fs-4 text-base-color fw-bold '> Add Documents</span></ModalHeader>
                <ModalBody className='d-flex justify-content py-3 px-5'>
                    <Form className='d-flex justify-content gap-3 flex-column'>
                        <div className="InputFeild" >
                            <Label htmlFor="employee_id" className="base-font-muli fw-bold text-base-black" >
                                EmployeeID :
                            </Label>
                            <Label
                                htmlFor="employee_id" className="base-font-muli fw-bold text-base-black ps-1"

                                value={UploadingDetails.EmpId}
                            >
                                {UploadingDetails.EmpId}
                            </Label>

                            {/* {errors.first_name && <span className="text-danger">Please enter First Name</span>} */}
                        </div>

                        <div className="InputFeild">
                            <Label htmlFor="doc_type" className="base-font-muli fw-bold text-base-black">
                                Document Type :
                            </Label>
                            <select id="doc_type" name="doc_type" value={UploadingDetails.doc_type} onChange={handleOnChange} className="border-primary p-2 rounded-2 base-font-pop fw-normal ms-2 w-100">
                                <option value="">Options..</option>
                                <option value="aadhar card">Aadhar Card</option>
                                <option value="pan card">Pan- Card</option>
                                <option value="other document">Other Document</option>
                            </select>
                        </div>

                        <div className="InputFeild" >
                            <Label htmlFor="doc_info" className="base-font-muli fw-bold text-base-black" >
                                Document Information :
                            </Label>
                            <input
                                type="textarea"
                                id="doc_info"
                                className="py-1 fs-6 shadow-none input-border form-control"
                                name="doc_info"
                                placeholder="Marksheet,Electioncard,etc"
                                value={UploadingDetails.doc_info}
                                onChange={handleOnChange}

                            />
                            {/* {errors.first_name && <span className="text-danger">Please enter First Name</span>} */}
                        </div>

                        <div className="InputFeild" >
                            <Label htmlFor="file" className="base-font-muli fw-bold text-base-black" >
                                Document :
                            </Label>
                            <input
                                type="file"
                                id="file"
                                name="file"
                                onChange={(e) => { handleOnFileChange(e) }}

                            />
                            {/* {errors.first_name && <span className="text-danger">Please enter First Name</span>} */}
                        </div>



                    </Form>
                </ModalBody>
                <ModalFooter className='border-0'>
                    <button onClick={onSubmit} className="submit-btn">
                        Upload
                    </button>
                    <button onClick={toggle} className="close-btn">
                        Close
                    </button>
                </ModalFooter>
            </Modal >
        </>
    )
}

export default AddDocument


