import { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, Input, Button, Label } from "reactstrap";
import axios from "axios";
import { environment } from "../../../env";
import swal from "sweetalert";
import "../../../css/BasicDetails.css";
import { Building3, Edit, Trash, UserEdit } from "iconsax-react";

const Address = (props: any) => {
  let EmpId = localStorage.getItem("employee_id");
  const [userData, setUserData] = useState({
    address: props.details.address,
    city: props.details.city,
    state: props.details.state,
    country: props.details.country,
    district: props.details.district,
    pincode: props.details.pincode,
  });

  const toggle = () => {
    setShow(!show);
  };
  const [show, setShow] = useState(true);

  const submit = (e: any) => {
    e.preventDefault();
    // axios.post('url', {
    axios
      .put(`${environment.baseUrl}update-basic-details/${EmpId}`, {
        address: userData.address,
        state: userData.state,
        country: userData.country,
        city: userData.city,
        district: userData.district,
        pincode: userData.pincode,
      })
      .then((res) => {
        if (res.status == 200) {
          swal({
            title: "Success",
            text: "Address Updated Successfully !",
            icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
          });
          setShow(!show);
          props.update(true);
        }
      })
      .catch((res) => console.log("error"));
  };
  useEffect(() => {}, [userData]);

  const handleOnChange = (event: any) => {
    setUserData({
      ...userData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div>
      <Card className="mt-3 mx-3 mb-2 px-3 border-0 position-relative">
        <div className="">
          <div className="d-flex align-items-center justify-content-between">
            <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
              <span className="base-text-color base-font-700 fs-4">
                <Building3 size="35" variant="Bulk" />
              </span>
              <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                Address
              </h4>
            </div>
            {show && (
              <Button
                className="tb-btn base-font-muli fw-bold d-flex align-items-center "
                onClick={toggle}
              >
                <span>Edit</span>
                <UserEdit size="32" variant="Bulk" className="ps-2" />
              </Button>
            )}
          </div>
        </div>
        {show ? (
          <>
            <CardBody className="p-0 py-2">
              <div className="border-bottom">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                    Address
                  </Label>
                  <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop ">
                    {" "}
                    {userData.address}{" "}
                  </Label>
                </div>
              </div>
              <div className="d-flex row pt-3">
                <div className=" col-2">
                  <div className="p-2">
                    <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                      City
                    </Label>
                    <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop">
                      {" "}
                      {userData.city}{" "}
                    </Label>
                  </div>
                </div>

                <div className=" col-2">
                  <div className="p-2">
                    <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                      District
                    </Label>
                    <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop">
                      {" "}
                      {userData.pincode}{" "}
                    </Label>
                  </div>
                </div>

                <div className=" col-3">
                  <div className="p-2">
                    <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                      State
                    </Label>
                    <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop BD-labels-font shadow-none">
                      {" "}
                      {userData.state}{" "}
                    </Label>
                  </div>
                </div>
                <br />
                <div className=" col-2">
                  <div className="p-2">
                    <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                      Pin Code
                    </Label>
                    <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop">
                      {" "}
                      {userData.pincode}{" "}
                    </Label>
                  </div>
                </div>
                <div className=" col-3">
                  <div className="p-2">
                    <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                      Country
                    </Label>
                    <Label className="BDLabelInput tb-border w-100 pl-2 BD-labels-font base-font-pop">
                      {" "}
                      {userData.country}{" "}
                    </Label>
                  </div>
                </div>
              </div>
            </CardBody>
          </>
        ) : (
          <>
            <form
              id="details"
              onSubmit={(e) => {
                submit(e);
              }}
            >
              <CardBody className="p-0 py-2">
                <div>
                  <div className="p-2">
                    <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                      Address
                    </Label>
                    <Input
                      className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                      type="text"
                      id="address"
                      name="address"
                      value={userData.address}
                      onChange={handleOnChange}
                      placeholder="Addresss"
                    />
                  </div>
                </div>

                <div className="row d-flex">
                  <div className=" col-4">
                    <div className="p-2">
                      <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                        City
                      </Label>
                      <Input
                        className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                        type="text"
                        id="city"
                        name="city"
                        value={userData.city}
                        onChange={handleOnChange}
                        placeholder="City"
                      />
                    </div>
                  </div>

                  <div className=" col-4">
                    <div className="p-2">
                      <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                        District
                      </Label>
                      <Input
                        className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                        type="text"
                        id="district"
                        name="district"
                        value={userData.district}
                        onChange={handleOnChange}
                        placeholder="District"
                      />
                    </div>
                  </div>

                  <div className=" col-4">
                    <div className="p-2">
                      <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                        State
                      </Label>
                      <Input
                        className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                        type="text"
                        id="state"
                        name="state"
                        value={userData.state}
                        onChange={handleOnChange}
                        placeholder="State"
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                        Pin Code
                      </Label>
                      <Input
                        className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                        type="number"
                        id="pincode"
                        name="pincode"
                        value={userData.pincode}
                        onChange={handleOnChange}
                        placeholder="Pincode"
                      />
                    </div>
                  </div>
                  <div className=" col-4">
                    <div className="p-2">
                      <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 pb-2 ps-1">
                        Country
                      </Label>
                      <Input
                        className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                        type="text"
                        id="country"
                        name="country"
                        value={userData.country}
                        onChange={handleOnChange}
                        placeholder="Country"
                      />
                    </div>
                  </div>
                  <br />
                </div>
              </CardBody>
              <CardFooter className="border-0 bg-transparent pt-4">
                <div className="d-flex justify-content-end gap-3">
                  <button className="update-btn">
                    Update
                    <Edit size="28" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                  <button className="close-btn" onClick={() => setShow(!show)}>
                    Cancel
                    <Trash size="24" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                </div>
              </CardFooter>
            </form>
          </>
        )}
      </Card>
    </div>
  );
};

export default Address;
