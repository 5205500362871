import { useEffect, useState } from "react";
import axios from "axios";
import { TableColumn } from "react-data-table-component";
import { S3_BUCKET, environment, link, myBucket } from "../../../env";
import swal from "sweetalert";
import "../../../css/BasicDetails.css";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Label,
  Form,
  Input,
} from "reactstrap";
import { AddSquare, BagTick2, Edit, NoteFavorite, Trash } from "iconsax-react";
import Table from "../../Table/Table";
import { Link } from "react-router-dom";
import EmptyTable from "../../errorComponents/EmptyTable";

interface ExperienceData {
  employee_id: any;
  company_name: any;
  position: any;
  joining_date: any;
  leaving_date: any;
  file: any;
}
interface DataRow {
  EmpId: any;
  Document: string;
  UploadDate: string;
  WordCount: string;
  Points: any;
  galaNo: any;
  contactNo: any;
  area: any;
  tenant: any;
}

const Experience = () => {
  var date = Math.round(+new Date() / 1000);
  const EmpId = localStorage.getItem("employee_id");
  const [data, setData] = useState<ExperienceData[]>([]);
  const [show, setShow] = useState(false);
  const [upload, setupload] = useState<any>();
  const [UploadingDetails, setUploadingDetails] = useState({
    Emp_Id: "",
    company_name: "",
    position: "",
    joining_date: "",
    leaving_date: "",
    file: "",
  });

  const Image = async (data: any) => {
    const params = {
      ACL: "public-read",
      Body: data,
      Bucket: S3_BUCKET,
      Key: `experience_${EmpId}_${date}_${data.name}`,
    };
    myBucket.upload(params, function (err: any, uploadData: any) {
      if (uploadData) {
        data = uploadData.Location;
        return (data);
      } else {
        console.log("error", err);
      }
    });
  }

  const handleOnChange = (event: any) => {
    setUploadingDetails({
      ...UploadingDetails,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnFileChange = (e: any) => {
    const fileList = e.target.files;
    setupload(fileList[0]);
  };


  const postexperience = async (document: any) => {
    let form_data = new FormData();
    form_data.append("employee_id", `${EmpId}`);
    form_data.append("company_name", UploadingDetails.company_name);
    form_data.append("position", UploadingDetails.position);
    form_data.append("joining_date", UploadingDetails.joining_date);
    form_data.append("leaving_date", UploadingDetails.leaving_date);
    if (upload && upload.name) {
      Image(upload)
      form_data.append('file', `${link}experience_${EmpId}_${date}_${upload.name}`)
    }
    try {
      const res = await axios.post(
        `${environment.baseUrl}experience-post/${EmpId}`,
        form_data,
        {}
      );
      return res.data;
    } catch (err) {
      console.log("err");
      return err;
    }
    return;
  };

  const onSubmit = async (data: any) => {
    postexperience(data).then((docResponse) => {
      if (docResponse.status === "success") {
        swal({
          title: "Success",
          text: "Experience Details Added Sucessfully",
          icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
        }).then(() => {
          GetExperience();
          setShow(!show);
        });
      }
    });
  };

  const GetExperience = async () => {
    await axios
      .get(`${environment.baseUrl}get-experience-by-id/${EmpId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((res) => console.log("Getting Table error"));
  };

  const ExamplePDFViewer = (file: any) => {
    window.open(`${file}`);
  };

  useEffect(() => {
    GetExperience();
  }, []);
  let id = 0;
  const columns: TableColumn<ExperienceData>[] = [
    {
      name: "ID",
      selector: (row) => id + 1,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Position",
      selector: (row) => row.position,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Joining Date",
      selector: (row) => row.joining_date,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Leaving Date",
      selector: (row) => row.leaving_date,
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
    {
      name: "Document",
      selector: (row) => (
        <Link onClick={() => ExamplePDFViewer(row.file)} to="">
          View
        </Link>
      ),
      sortable: true,
      style: {
        fontSize: "2.3rem",
      },
    },
  ];

  return (
    <>
      {show ? (
        <>
          <div className="mt-3 mx-3 mb-2 px-3 ">
            <div className="d-flex align-items-center justify-content-between">
              <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
                <span className="base-text-color base-font-700 fs-4">
                  <NoteFavorite size="35" variant="Bulk" />
                </span>
                <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                  Experience Letter
                </h4>
              </div>
              {!show && (
                <Button
                  className="tb-btn base-font-muli fw-bold d-flex align-items-center "
                  onClick={() => setShow(!show)}
                >
                  <span>Add Experience Letter</span>
                  <AddSquare size="32" variant="Bulk" className="ps-2" />
                </Button>
              )}
            </div>
          </div>
          {/* <table style={{ fontSize: 19 }} className="table table-hover">
                            <thead key="thead">
                                <tr> */}

          <div>
            <Card className="border-0 position-relative px-2">
              <CardBody className="pb-0">
                <Form>
                  <div className=" px-2  row">
                    <div className=" col-6 ">
                      <Label className="BDLabels base-font-muli fw-bold">
                        Company Name
                      </Label>
                      <Input
                        className="py-1 fs-6 shadow-none input-border"
                        type="text"
                        id="company_name"
                        name="company_name"
                        value={UploadingDetails.company_name}
                        onChange={handleOnChange}
                        placeholder="Comapny Name"
                      ></Input>
                    </div>
                    <div className="col-6">
                      <Label className="BDLabels base-font-muli fw-bold">
                        Joining Date
                      </Label>
                      <Input
                        className="py-1 fs-6 shadow-none input-border"
                        type="date"
                        id="Joiningdate"
                        name="joining_date"
                        value={UploadingDetails.joining_date}
                        onChange={handleOnChange}
                        placeholder="Joining Date"
                      ></Input>
                    </div>
                    <div className="col-6">
                      <Label className="BDLabels pl-1 base-font-muli fw-bold">
                        Position
                      </Label>
                      <Input
                        className="py-1 fs-6 shadow-none input-border"
                        type="text"
                        id="Position"
                        name="position"
                        value={UploadingDetails.position}
                        onChange={handleOnChange}
                        placeholder="Position"
                      ></Input>
                    </div>
                    <div className="col-6">
                      <Label className="BDLabels pl-1 base-font-muli fw-bold">
                        Leaving Date
                      </Label>
                      <Input
                        className="py-1 fs-6 shadow-none input-border"
                        type="date"
                        id="leavingdate"
                        name="leaving_date"
                        value={UploadingDetails.leaving_date}
                        onChange={handleOnChange}
                        placeholder="Leaving Date   "
                      ></Input>
                    </div>
                    <div className="InputFeild flex-column d-flex pt-3">
                      <Label
                        htmlFor="file"
                        className="base-font-muli fw-bold text-base-black"
                      >
                        Upload Document
                      </Label>
                      <input
                        className="input-border"
                        accept=".pdf"
                        type="file"
                        id="file"
                        name="file"
                        onChange={(e) => {
                          handleOnFileChange(e);
                        }}
                      />
                      <span className="fw-semibold text-danger fs-6 py-2 ps-1 base-font-pop">
                        Note : Accepts only PDF File *
                      </span>
                    </div>
                  </div>
                </Form>
              </CardBody>
              <CardFooter className="border-0 bg-transparent">
                <div className="d-flex justify-content-end gap-3">
                  <button className="update-btn" onClick={onSubmit}>
                    Upload
                    <Edit size="28" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                  <button className="close-btn" onClick={() => setShow(!show)}>
                    Cancel
                    <Trash size="24" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </>
      ) : (
        <>
          <div className="main-wrapper-section">
            <div className=" py-3 px-5 d-flex flex-column ">
              <div className="d-flex align-items-center justify-content-between pb-4">
                <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
                  <span className="base-text-color base-font-700 fs-4">
                    <NoteFavorite size="35" variant="Bulk" />
                  </span>
                  <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                    Experience
                  </h4>
                </div>
                {!show && (
                  <Button
                    className="tb-btn base-font-muli fw-bold d-flex align-items-center "
                    onClick={() => setShow(!show)}
                  >
                    <span>Add Experience</span>
                    <AddSquare size="32" variant="Bulk" className="ps-2" />
                  </Button>
                )}
              </div>
              {data[0] ? (
                <Table columns={columns} data={data} />
              ) : (
                <EmptyTable />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Experience;
