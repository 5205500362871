import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
  ButtonToolbar,
} from "reactstrap";
import swal from "sweetalert";
import moment from "moment";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { S3_BUCKET, environment, link, myBucket } from "../env";
import { getTasksData } from "../userRedux/actions";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ErrorComponents from "../components/errorComponents/ErrorComponents";
import { Hierarchy } from "iconsax-react";

const AddTaskModal = (props: any) => {
  const user = useSelector((state: any) => state?.root?.reducers?.userData);
  const teamMembers = useSelector((state: any) => state?.root?.reducers?.teamMembers);
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();
  const toggle = () => {
    setModal(!modal);
    reset();
  };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const getTasks = () => {
    axios.get(`${environment.baseUrl}remainig-tasks/${user.id}`)
      .then(res => {
        if (res.data.code != 5000) {
          dispatch(getTasksData(res.data))
        }
      })
      .catch(res => console.log("error"))
  }


  const Image = async (data: any) => {
    var date = Math.round(+new Date() / 1000);
    const params = {
      ACL: "public-read",
      Body: data,
      Bucket: S3_BUCKET,
      Key: `taskimages_${date}_${data.name}`,
    };
    myBucket.upload(params, function (err: any, uploadData: any) {
      if (uploadData) {
        data = uploadData.Location;
        return (data);
      } else {
        console.log("error", err);
      }
    });
  }

  const onSubmit = async (value: any) => {
    var date = Math.round(+new Date() / 1000);
    let files: string[] = []
    Array.from(value?.attachments).forEach(async (file: any) => {
      files.push(`${link}taskimages_${date}_${file.name}`)
      await Image(file)
    })
    value.images = JSON.stringify(files)
    value.assigned_by = user.id
    value.team = props.projectid[0]?.id
    if (props.subtask == true) {
      value.parent = props?.taskid
      value.team = props.team.team.id
    }
    try {
      await axios
        .post(`${environment.baseUrl}create-task`, value)
        .then(async (form) => {
          if (form?.data?.code === 2000) {
            swal({
              title: "Success",
              text: "Task Added Successfully",
              icon: "success",
            });
            toggle();
            getTasks();
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <button
        onClick={() => {
          toggle();
        }}
        className={props?.subtask == true ? "d-flex gap-2 align-items-center btn btn-light rounded-pill px-3 py-2 border-0 fs-5 base-font-600 mb-0 text-black" : "submit-btn bg-base-color text-white base-font-700"}
        disabled={props?.projectid == undefined}
      >
        {props?.subtask == true && <Hierarchy />}
        {props?.subtask == true ? "Add Child" : "Create"}
      </button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="xl"
        className="mispunch-modal"
      >
        <ModalHeader toggle={toggle} className="py-3 px-3">
          <span className="base-font-muli fs-4 text-base-color fw-bold px-3 ">
            Add Tasks
          </span>
        </ModalHeader>

        <ModalBody className="p-4">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="inner-login"
            encType="multipart/form-data"
          >
            <div className="row d-flex">
              <div className=" col-4">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Task Title
                  </Label>
                  <input
                    className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    type="text"
                    placeholder="Task Title"
                    {...register("task_name", { required: true })}
                  />
                  {errors.task_name && <ErrorComponents error={"Task Name is required *"} />}
                </div>
              </div>
              <div className=" col-4">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Due Date
                  </Label>
                  <input
                    className=" input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    type="date"
                    placeholder="Task Title"
                    {...register("due_date", { required: true })}
                  />
                  {errors.due_date && <ErrorComponents error={"Task Date is required *"} />}
                </div>
              </div>
              <div className=" col-4">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Reporter
                  </Label>
                  <select className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    style={{ fontSize: "1rem" }}
                    {...register("report_to", { required: true })}
                  >
                    <option value="" className='attoptions'>Employee Name...</option>
                    {props.subtask == true ?
                      teamMembers?.filter((member: any) => member?.team?.project?.id == props.projectid) &&
                      teamMembers?.filter((member: any) => member?.team?.project?.id == props.projectid).map((user: any, i: any) => {
                        return <option className='attoptions' value={`${user.team_member_name.firstname} ${user.team_member_name.lastname}`} key={i}>{user.team_member_name.firstname} {user.team_member_name.lastname}  </option>;
                      })
                      : teamMembers?.filter((member: any) => member?.team?.project?.id == props.projectid[0]?.project?.id) &&
                      teamMembers?.filter((member: any) => member?.team?.project?.id == props.projectid[0]?.project?.id).map((user: any, i: any) => {
                        return <option className='attoptions' value={`${user.team_member_name.firstname} ${user.team_member_name.lastname}`} key={i}>{user.team_member_name.firstname} {user.team_member_name.lastname}  </option>;
                      })
                    }
                  </select>
                  {errors.report_to && <ErrorComponents error={"Reporter Name is required *"} />}
                </div>
              </div>
              <div className=" col-4">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Task Type
                  </Label>
                  <select
                    {...register("type")}
                    className="border-primary p-2 rounded-2 base-font-pop fw-normal ms-2 w-100"
                  >
                    <option value="Task" selected>Task</option>
                    <option value="Bug">Bug</option>
                    <option value="Epic">Epic</option>
                  </select>
                </div>
              </div>
              <div className=" col-4">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Priority
                  </Label>
                  <select
                    {...register("priority")}
                    className="border-primary p-2 rounded-2 base-font-pop fw-normal ms-2 w-100"
                  >
                    <option value="Low" >Low</option>
                    <option value="Normal" selected>Normal</option>
                    <option value="High">High</option>
                  </select>
                </div>
              </div>
              <div className=" col-4">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Assignee Member
                  </Label>
                  <select className="BDLabelInput input-border w-100 pl-2 BD-labels-font BD-labels-font shadow-none"
                    style={{ fontSize: "1rem" }}
                    {...register("assigned_to", { required: true })}
                  >
                    <option value="" className='attoptions'>Employee Name...</option>
                    {props.subtask == true ?
                      teamMembers?.filter((member: any) => member.team.project.id == props.projectid).map((user: any, i: any) => {
                        return <option className='attoptions' value={user.id} key={i}>{user.team_member_name.firstname} {user.team_member_name.lastname}  </option>;
                      }) :
                      teamMembers?.filter((member: any) => member.team.project.id == props.projectid[0]?.project?.id).map((user: any, i: any) => {
                        return <option className='attoptions' value={user.id} key={i}>{user.team_member_name.firstname} {user.team_member_name.lastname}  </option>;
                      })}
                  </select>
                  {errors.assigned_to && <ErrorComponents error={"Assigned Name is required *"} />}
                </div>
              </div>
              <div className=" col-4">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Status
                  </Label>
                  <select
                    {...register("status")}
                    className="border-primary p-2 rounded-2 base-font-pop fw-normal ms-2 w-100"
                  >
                    {/* change in Add Task Page */}
                    <option value="pending">Pending</option>
                    <option value="inprogress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="denied">Denied</option>
                  </select>
                </div>
              </div>
              <div className=" col-4">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Url Attachment
                  </Label>
                  <input
                    type="text"
                    className="border-primary p-2 rounded-2 base-font-pop fw-normal ms-2 w-100"
                    placeholder="https://www.example.com"
                    {...register("url_attachments")}
                  />
                </div>
              </div>
              <div className=" col-4">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Image Attachment
                  </Label>
                  <input
                    type="file"
                    multiple
                    id="file"
                    className="my-3"
                    {...register("attachments")}
                  />
                </div>
              </div>
              <div className=" col-12">
                <div className="p-2">
                  <Label className="BDLabels m-0 base-dont-muli fw-800 fs-5 ps-1">
                    Task Description
                  </Label>
                  {/* <textarea
                    className="py-1 px-1 fs-5 base-font-pop w-100  input-textarea shadow-none border-primary rounded-2"
                    style={{ outline: "none" }}
                    {...register("description")}
                    placeholder="Enter Task Description..."
                  /> */}
                  <div className="mt-1">
                    <CKEditor
                      editor={ClassicEditor}
                      data={""}
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setValue("description", data)
                      }}
                      onBlur={(event, editor) => {
                      }}
                      onFocus={(event, editor) => {
                      }} />
                  </div>
                </div>
              </div>
            </div>
            <ModalFooter className="border-0 pb-0">
              <button type="submit" className="submit-btn">
                submit
              </button>
              <button onClick={toggle} className="close-btn">
                Close
              </button>
            </ModalFooter>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddTaskModal;
