import { useState, useEffect } from 'react';
import { DatePicker, Space } from 'antd';
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Form,
} from 'reactstrap';

import axios from 'axios';
import { environment } from '../../../env';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';
import { DocumentText1 } from 'iconsax-react';
import moment from 'moment';
import { RangePickerProps } from 'antd/es/date-picker';
import { useSelector } from 'react-redux';
import { useBadge } from '@mui/base';

const ApplyLeaveModel = (props: any) => {

    const user = useSelector((state: any) => state?.root?.reducers?.userData);

    const EmpId = localStorage.getItem('employee_id');
    const userid = localStorage.getItem('userid');
    const [modal, setModal] = useState(false);
    const [days, setDays] = useState(1);
    let [show, setShow] = useState(true);
    const [btndis, setBtndis] = useState(false);
    const { RangePicker } = DatePicker;
    const today = new Date()
    const [leavedata, setLeaveData] = useState(
        {
            employee_id: EmpId,
            leave_type: '',
            start_date: '',
            end_date: '',
            leave_durations: '',
            leave_reason: '',
            user: userid,
            show: 1
        }
    );

    const { register, handleSubmit, formState: { errors }, reset, watch } = useForm();


    const toggle = () => { setModal(!modal); setBtndis(false) }

    const handleOnChange = (event: any) => {
        setLeaveData({
            ...leavedata,
            [event.target.name]: event.target.value
        });
    }

    const hide = (e: any) => {
        if (e.target.name === 'leave_durations') {
            if (e.target.value === "halfday") {
                setShow(true)
            }
            else if (e.target.value === "fullday") {
                setShow(true)
            }
            else if (e.target.value === "aboveaday") {
                setShow(false)
            }
        }

    };

    const functioncall = (e: any) => {

        handleOnChange(e);
        hide(e);
    }
    const OnSubmit = async () => {
        setBtndis(true)
        try {
            if (leavedata.end_date == '') {
                leavedata.end_date = leavedata.start_date
            }
            await axios.post(`${environment.baseUrl}apply-leave`, leavedata).then((leave) => {
                if (leave.data.status === 'success') {
                    toggle();
                    props.update(true);
                    setBtndis(false)
                    setLeaveData({
                        "employee_id": EmpId,
                        "leave_type": "",
                        "start_date": "",
                        "end_date": "",
                        "leave_durations": "",
                        "leave_reason": "",
                        "user": userid,
                        "show": 1
                    });
                }
                swal({
                    title: "Success",
                    text: 'Applied For Leave Successfully !',
                    icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                })
            })
        }
        catch (err) {
            setBtndis(false)
            console.log("Leave error");
        }
    };

    const submit = (e: any) => {
        if (e[0] == undefined) {
            setLeaveData({
                ...leavedata,
                "start_date": e.format("YYYY-MM-DD"),
                "end_date": e.format("YYYY-MM-DD")
            });
        } else {
            setLeaveData({
                ...leavedata,
                "start_date": e[0].format("YYYY-MM-DD"),
                "end_date": e[1].format("YYYY-MM-DD")
            });
            var days = e[1].diff(e[0], 'days')
            setDays(days + 1)
        }
    }

    const disabledDate: RangePickerProps['disabledDate'] = (current) => {
        return current && current < moment(today);
    };
    return (
        <div>
            <Form inline onSubmit={(e) => e.preventDefault()}>
                <Button className='tb-btn base-font-muli fw-bold ' onClick={toggle}>
                    Apply Leave  <DocumentText1 size="32" variant="Bulk" className='ps-1' />
                </Button>
            </Form>
            <Modal
                isOpen={modal}
                toggle={toggle}
                centered className='modalstyle' size='lg'
            >
                <ModalHeader className="py-3 px-4"><span className='base-font-muli fs-4 text-base-color fw-bold '>Apply For Leave</span></ModalHeader>
                <ModalBody className='py-3 px-4'>
                    <Form id='details' className="d-flex justify-content gap-1 flex-column">
                        <div className="">
                            <label htmlFor="employee_id" className="base-font-muli fw-bold text-base-black fs-5" >
                                Employee ID :
                            </label>
                            <label htmlFor="employee_id" className="base-font-500 text-black ps-2 pb-1 fs-5" >
                                {EmpId}
                            </label>
                        </div>
                        <div className='d-flex align-items-center mt-2 gap-2'>
                            <label htmlFor="leave_durations" className="base-font-muli fw-bold text-base-black fs-5 py-1 mb-0" >
                                Leave Duration :
                            </label>
                            <div className="d-flex gap-1">
                                <input
                                    type="radio"
                                    id="halfday"
                                    name='leave_durations'
                                    value="halfday"
                                    checked={leavedata.leave_durations === "halfday"}
                                    onChange={functioncall}
                                    className="py-1 fs-4 shadow-none input-border mb-0"
                                /><label htmlFor="halfday" className='base-font-muli fw-bold text-base-black fs-5 mb-0 px-2'>Half Day</label>
                                <input
                                    type="radio"
                                    id="fullday"
                                    name='leave_durations'
                                    value="fullday"
                                    checked={leavedata.leave_durations === "fullday"}
                                    onChange={functioncall}
                                    className="py-1 fs-4 shadow-none input-border mb-0"
                                />
                                <label htmlFor="fullday" className='base-font-muli fw-bold text-base-black fs-5 mb-0 px-2'>Full Day</label>
                                <input
                                    type="radio"
                                    id="aboveaday"
                                    name='leave_durations'
                                    value="aboveaday"
                                    checked={leavedata.leave_durations === "aboveaday"}
                                    onChange={functioncall}
                                    className="py-1 fs-4 shadow-none input-border mb-0"
                                />
                                <label htmlFor="aboveaday" className='base-font-muli fw-bold text-base-black fs-5 mb-0 px-2'>Above a Day</label>
                            </div>
                            <div >
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-2 gap-3 py-2">
                            <label htmlFor="start_date" className="base-font-muli fw-bold text-base-black fs-5 " >
                                Date :
                            </label>
                            {show ?
                                <>
                                    <Space direction="vertical" className='ms-5' size={20}>
                                        <DatePicker className='py-1 fs-6 w-100 shadow-none input-border form-control'
                                            disabledDate={disabledDate}
                                            onChange={(e: any) => {
                                                submit(e)
                                            }} />
                                    </Space>
                                </>
                                : <>
                                    <Space direction="vertical" className='ms-5' size={20}>
                                        <RangePicker className='py-1 fs-6 w-100 shadow-none input-border form-control'
                                            disabledDate={disabledDate}
                                            onChange={(e: any) => {
                                                submit(e)
                                            }} />
                                    </Space>
                                </>}
                        </div>
                        <div >
                            <div className="d-flex align-items-center mt-2 gap-3 py-2">
                                <label htmlFor="leave_type" className="base-font-muli fw-bold text-base-black fs-5">
                                    Leave Type :
                                </label>
                                <select id="leave_type" name="leave_type" value={leavedata.leave_type} onChange={handleOnChange} className="border-primary p-2 rounded-2 base-font-pop fw-normal  w-50 fs-6">
                                    <option value="">Options...</option>
                                    <option disabled={user.comp_off < days} value="CompOff">Comp Off | {user.comp_off} remaining</option>
                                    <option disabled={user.sick_leave < days} value="Sick Leave">Sick Leave | {user.sick_leave} remaining</option>
                                    <option disabled={user.previlage_leave < days} value="Privilage Leave">Privilage Leave | {user.previlage_leave} remaining</option>
                                    <option value="UnPaid Leave">Un-Paid Leave</option>
                                </select>
                                <div className='base-font-pop fs-5 text-base-green text-capitalize'>
                                    {leavedata.leave_type == '' || leavedata.leave_type == 'UnPaid Leave' ? <> </> : <><Label>{leavedata.leave_type == 'CompOff' ? props.data?.comp_off : leavedata.leave_type == 'Privilage Leave' ? props.data?.previlage_leave :
                                        leavedata?.leave_type == 'Sick Leave' && props.data?.sick_leave} leaves Available</Label></>}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mt-2 gap-3 py-2">
                            <Label for='leave_reason' className="text-black base-font-muli fw-bold fs-5 pt-2">
                                Reason of Leave:
                            </Label>

                            <textarea
                                className='py-1 px-1 fs-5 base-font-pop w-75 input-textarea shadow-none border-primary rounded-2'
                                style={{ outline: "none" }}
                                id="leave_reason"
                                name="leave_reason"
                                placeholder="Reason for Leave.."
                                value={leavedata.leave_reason}
                                onChange={handleOnChange}
                            />
                        </div>
                    </Form>
                </ModalBody>
                <ModalFooter className='border-0'>
                    <button disabled={btndis} onClick={() => { toggle; OnSubmit() }} className="submit-btn">
                        Submit
                    </button>
                    <button onClick={toggle} className="close-btn">
                        Cancel
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default ApplyLeaveModel;



