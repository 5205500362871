import {
  Category2,
  Element4,
  Note,
  Personalcard,
  ReceiptEdit,
  CalendarTick,
  ReceiptItem,
  MenuBoard,
  Gift,
  Trade,
  Clock,
} from "iconsax-react";

const SidebarApi = [
  {
    title: "Dashboard",
    icon: <Element4 variant="Bulk" size="24" />,
    link: "/dashboard",
  },
  {
    title: "Attendance",
    icon: <CalendarTick variant="Bulk" size="24" />,
    link: "/attendance",
    childs: [
      {
        title: "Attendance",
        icon: <Category2 variant="Bulk" size="24" />,
        link: "/attendance",
      },
      {
        title: "Leaves",
        icon: <ReceiptEdit variant="Bulk" size="24" />,
        link: "leave",
      },
      {
        title: "Mispunch",
        icon: <Note variant="Bulk" size="24" />,
        link: "/usermispunch",
      },
      {
        title: "Break Log",
        icon: <Clock variant="Bulk" size="24" />,
        link: "/userbreaklog",
      },
    ],
  },
  {
    title: "Projects",
    icon: <Trade variant="Bulk" size="24" />,
    link: "/projects",
  },
  {
    title: "Basic Details",
    icon: <Personalcard variant="Bulk" size="24" />,
    link: "/basicdetails",
  },

  {
    title: "Holidays",
    icon: <MenuBoard variant="Bulk" size="24" />,
    link: "/holidays",
  },

  {
    title: "Salary Slips",
    icon: <ReceiptItem variant="Bulk" size="24" />,
    link: "/usersalarysliptable",
  },

  {
    title: "Rewards",
    icon: <Gift variant="Bulk" size="24" />,
    link: "/rewards",
  },
];

export default SidebarApi;
