import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { Card, CardBody, CardFooter, Input } from "reactstrap";
import axios from "axios";
import { Phone } from 'react-telephone'
import { Button } from "reactstrap";
import { S3_BUCKET, environment, link, myBucket } from "../../../env";
import defaultimage from "../../../image/user-demo.jpeg";
import "../../../css/BasicDetails.css";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Edit, Trash, UserEdit } from "iconsax-react";
import { content } from "html2canvas/dist/types/css/property-descriptors/content";
import ResetPassword from "./ResetPassword";

function BasicDetails(props: any) {
  const [render, setRender] = useState(false);
  const [show, setShow] = useState(true);
  var date = Math.round(+new Date() / 1000);
  const [upload, setUpload] = useState<any>();
  const [contect, setContect] = useState<any>(props?.basicdetails?.phone_no);
  const [emgcontectno, setEmgContectNo] = useState<any>(
    props?.basicdetails?.emg_contact_number
  );
  const [userDetail, setUserDetail] = useState({
    firstname: props?.basicdetails?.firstname,
    lastname: props?.basicdetails?.lastname,
    employee_id: props?.basicdetails?.employee_id,
    gender: props?.basicdetails?.gender,
    email_id: props?.basicdetails?.email_id,
    phone_no: props?.basicdetails?.phone_no,
    profile_pic: props?.basicdetails?.profile_pic,
    office_email: props?.basicdetails?.office_email,
    personal_email: props?.basicdetails?.personal_email,
    pan: props?.basicdetails?.pan,
    aadhar: props?.basicdetails?.aadhar,
    emg_contact_name: props?.basicdetails?.emg_contact_name,
    relationship_with_emg_contact: props?.basicdetails?.relationship_with_emg_contact,
    emg_contact_number: props?.basicdetails?.emg_contact_number,
    reporting_manager: props?.basicdetails?.reporting_manager,
    check_in_time: props?.basicdetails?.check_in_time,
    check_out_time: props?.basicdetails?.check_out_time,
    blood_group: props?.basicdetails?.blood_group,
    date_of_birth: props?.basicdetails?.date_of_birth,
    department: props?.basicdetails?.department,
    designation: props?.basicdetails?.designation,
    date_of_joining: props?.basicdetails?.date_of_joining,
    probation_period: props?.basicdetails?.probation_period,
    notice_period: props?.basicdetails?.notice_period,
  });
  const imageUploader = React.useRef(null);

  const Image = async (data: any) => {
    const params = {
      ACL: "public-read",
      Body: data,
      Bucket: S3_BUCKET,
      Key: `profilepic_${props.basicdetails.employee_id}_${date}_${data.name}`,
    };
    myBucket.upload(params, function (err: any, uploadData: any) {
      if (uploadData) {
        data = uploadData.Location;
        return (data);
      } else {
        console.log("error", err);
      }
    });
  }

  const handleOnChange = (event: any) => {
    setUserDetail({
      ...userDetail,
      [event.target.name]: event.target.value,
    });
  };
  const handleImageChange = (e: any) => {
    const fileList = e.target.files;
    setUpload(fileList[0])
  };

  const ProfilePostMethod = async () => {
    let form_data = new FormData();
    form_data.append("personal_email", userDetail.personal_email);
    form_data.append("firstname", userDetail.firstname);
    form_data.append("lastname", userDetail.lastname);
    form_data.append("pan", userDetail.pan);
    form_data.append("aadhar", userDetail.aadhar);
    form_data.append("blood_group", userDetail.blood_group);
    form_data.append("gender", userDetail.gender);
    form_data.append("date_of_birth", userDetail.date_of_birth);
    form_data.append("emg_contact_name", userDetail.emg_contact_name);
    form_data.append(
      "relationship_with_emg_contact",
      userDetail.relationship_with_emg_contact
    );
    form_data.append("emg_contact_number", emgcontectno);
    form_data.append("phone_no", contect);
    if (upload && upload.name) {
      Image(upload);
      form_data.append("profile_pic", `${link}profilepic_${props.basicdetails.employee_id}_${date}_${upload.name}`);
    }
    try {
      const res = await axios.put(
        `${environment.baseUrl}update-basic-details/${props.basicdetails.employee_id}`,
        form_data
      );
      return res.data;
    } catch (err) {
      console.log("err");
      return err;
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    ProfilePostMethod().then((profileResponse) => {
      if (profileResponse.status === "success") {
        swal({
          title: "Success",
          text: "Profile is updated Successfully !",
          icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
        }).then(() => {
          props.update(true);
          setShow(!show);
          setFile("");
        });
        setRender(true);
      }
    });
  };
  const [file, setFile] = useState("");

  function handleimg(e: any) {
    handleImageChange(e);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  useEffect(() => {
    console.log('render', render)
  }, [render]);


  return (
    <>
      <Card className="rounded-tb border-0 position-relative">
        {show ? (
          <>
            <CardBody className="p-0">
              <div className="basic-details-back-main">
                <div className="basic-details-back">
                  <div className="basic-details-user-img  d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center ">
                      <div className="basic-details-imgBox">
                        <img
                          src={
                            props?.basicdetails?.profile_pic != 'null'
                              ? `${props?.basicdetails?.profile_pic}`
                              : 'https://instalogisticts-media-techiebears.s3.ap-south-1.amazonaws.com/default.jpg'
                          }
                          alt="user-img"
                          className="user-image-fit"
                        />
                      </div>
                      <div className="nameBox d-flex flex-column pt-3 px-3">
                        <h1 className="base-font-Dancing fw-bold fs-1 text-capitalize text-base-color">
                          {userDetail.firstname} {userDetail.lastname}.
                        </h1>
                        <h6 className="base-font-pop fw-bold status-color-blue fs-6  text-capitalize ">
                          EMP ID :- {userDetail.employee_id}
                        </h6>
                        <h6 className="base-font-pop fw-600 fs-6  text-capitalize text-dark">
                          {userDetail.email_id}
                        </h6>
                        <p className="base-font-pop fw-600 mb-0 text-capitalize ">
                          {userDetail.phone_no}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex justify-items-evenly gap-5">
                      <ResetPassword />
                      <Button
                        className="tb-btn base-font-muli fw-bold d-flex align-items-center "
                        onClick={() => setShow(!show)}
                      >
                        <span>Edit</span>
                        <UserEdit size="32" variant="Bulk" className="ps-2" />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="userBoxs px-5">
                <div className="d-flex justify-content-center align-items-center ">
                  <div className="row g-3 ">
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          First Name
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.firstname == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.firstname
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Last Name{" "}
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.lastname == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.lastname
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Personal Email
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.personal_email == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.personal_email
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800  pb-1">
                          Contact Number
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {contect == "null" ? (
                            <>---</>
                          ) : (
                            contect
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800  pb-1">
                          Pan Number
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.pan == "null" ? <>---</> : userDetail.pan}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800  pb-1">
                          Aadhar Number
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.aadhar == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.aadhar
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800  pb-1">
                          Blood Group
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.blood_group == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.blood_group
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Gender
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.gender == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.gender
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Date Of Birth
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.date_of_birth == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.date_of_birth
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Emergency Contact Name
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.emg_contact_name == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.emg_contact_name
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Relation with Employee
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.relationship_with_emg_contact ==
                            "null" ? (
                            <>---</>
                          ) : (
                            userDetail.relationship_with_emg_contact
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Emergency Contact Number
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {emgcontectno == "null" ? (
                            <>---</>
                          ) : (
                            emgcontectno
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Office Email
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.email_id == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.email_id
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Date of Joining
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.date_of_joining == "null" ? (
                            <> --- </>
                          ) : (
                            userDetail.date_of_joining
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          {" "}
                          Reporting Manager
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.reporting_manager == "null" ? (
                            <> --- </>
                          ) : (
                            userDetail.reporting_manager
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          {" "}
                          Check-In Time
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.check_in_time == "null" ? (
                            <> --- </>
                          ) : (
                            userDetail.check_in_time
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Check-Out Time
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.check_out_time ? (
                            <>---</>
                          ) : (
                            userDetail.check_out_time
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          {" "}
                          Department
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.department ? (
                            <>---</>
                          ) : (
                            userDetail.department
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Designation
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.designation ? (
                            <>---</>
                          ) : (
                            userDetail.designation
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          {" "}
                          Probation Period
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.probation_period == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.probation_period
                          )}
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                      <div className="d-flex flex-column">
                        <label className="BD-labels mb-2 base-font-muli fw-800 ">
                          Notice Period
                        </label>
                        <label className="fs-5 label-inputs base-font-pop">
                          {userDetail.notice_period == "null" ? (
                            <>---</>
                          ) : (
                            userDetail.notice_period
                          )}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </>
        ) : (
          <>
            <form id="details" onSubmit={(e) => onSubmit(e)}>
              <CardBody className="p-0">
                <div className="basic-details-back-main">
                  <div className="basic-details-back">
                    <div className="basic-details-user-img  d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center ">
                        <div className="basic-details-imgBox position-relative">
                          {file ? (
                            <img src={file} className="user-image-fit" />
                          ) : (
                            <img
                              src={
                                props.basicdetails.profile_pic != 'null'
                                  ? `${props.basicdetails.profile_pic}`
                                  : 'https://instalogisticts-media-techiebears.s3.ap-south-1.amazonaws.com/default.jpg'
                              }
                              alt="user-img"
                              className="user-image-fit"
                            />
                          )}
                          <div className="upload-img-main">
                            <label htmlFor="file-input">
                              <UserEdit
                                size="28"
                                variant="Bulk"
                                className="upload-img-icon "
                              />
                            </label>

                            <div className="">
                              <input
                                id="file-input"
                                type="file"
                                className=""
                                name=""
                                ref={imageUploader}
                                accept="image/jpeg,image/png,image/gif"
                                onChange={(e) => {
                                  handleimg(e);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="nameBox d-flex flex-column pt-3 ps-4">
                          <h1 className="base-font-Dancing fw-bold fs-1 text-capitalize text-base-color">
                            {userDetail.firstname} {userDetail.lastname}.
                          </h1>
                          <h6 className="base-font-pop fw-bold status-color-blue fs-6  text-capitalize ">
                            EMP ID :- {userDetail.employee_id}
                          </h6>
                          <h6 className="base-font-pop fw-600 fs-6  text-capitalize text-dark">
                            {userDetail.personal_email}
                          </h6>
                          <p className="base-font-pop fw-600 mb-0 text-capitalize ">
                            {userDetail.phone_no}
                          </p>
                        </div>
                      </div>
                      {show && (
                        <Button
                          className="tb-btn base-font-muli fw-bold d-flex align-items-center "
                          onClick={() => setShow(!show)}
                        >
                          <span>Edit</span>
                          <UserEdit size="32" variant="Bulk" className="ps-2" />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="userBoxs px-5">
                  <div className="row " id="main">
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          First Name
                        </p>
                        <Input
                          className="py-1 fs-6 shadow-none input-border"
                          type="text"
                          id="first_name"
                          name="firstname"
                          maxLength={10}
                          value={userDetail.firstname}
                          onChange={handleOnChange}
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Last Name
                        </p>
                        <Input
                          className="py-1 fs-6 shadow-none input-border"
                          type="text"
                          id="last_name"
                          name="lastname"
                          maxLength={10}
                          value={userDetail.lastname}
                          onChange={handleOnChange}
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <label className="m-0 BD-labels-head base-font-muli fw-800 ">
                          Personal Email
                        </label>
                        <Input
                          type="text"
                          id="pemail"
                          name="personal_email"
                          value={userDetail.personal_email}
                          onChange={handleOnChange}
                          placeholder="Personal Email"
                          className="py-1 fs-6 shadow-none input-border"
                        // style={{ paddingLeft: '1rem', fontSize: 14, width: '100%', height: '1.7rem' }}
                        />
                      </div>
                    </div>
                    <div className='col-4'>
                      <div className="pt-4 ">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">Phone Number</p>
                        <Phone type="tel"
                          maxLength={12}
                          defaultCountry="in"
                          value={contect}
                          onChange={(e: any) => { setContect(e.target.value) }}
                          placeholder="Contact Number"
                          className="py-1 pt-2 mt-1 fs-6 shadow-none input-border">
                          <Phone.Country className='border-0 w-25 ' />
                          <Phone.Number placeholder="1234567890" className=' ps-2 w-75 fs-6 title-font-500  border-0' />
                        </Phone>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800 pb-2">
                          Pan Number
                        </p>
                        <Input
                          className="py-1 fs-6 shadow-none input-border"
                          type="text"
                          id="pancard_number"
                          name="pan"
                          maxLength={10}
                          value={userDetail.pan}
                          onChange={handleOnChange}
                          placeholder="Pan Card Number"
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <label className="m-0 BD-labels-head base-font-muli fw-800 pb-2">
                          Aadhar Number
                        </label>
                        <Input
                          type="text"
                          id="Aadhar_number"
                          name="aadhar"
                          maxLength={12}
                          value={userDetail.aadhar}
                          onChange={handleOnChange}
                          placeholder="Aadhar Card Number"
                          className="py-1 fs-6 shadow-none input-border"
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800 pb-2 ">
                          Blood Group
                        </p>
                        <Input
                          type="text"
                          id="blood_group"
                          name="blood_group"
                          className="py-1 fs-6 shadow-none input-border"
                          value={userDetail.blood_group}
                          onChange={handleOnChange}
                          placeholder="Blood Group"
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800 pb-2">
                          Gender
                        </p>
                        <div className="d-flex">
                          <div className="d-flex pt-2">
                            <input
                              type="radio"
                              id="male"
                              name="gender"
                              value="Male"
                              checked={userDetail.gender === "Male"}
                              onChange={handleOnChange}
                              placeholder="Example@gmail.com"
                            />
                            {"  "}
                            <label
                              htmlFor="male"
                              className="ps-1 fs-6 mb-0 Labels-font label-inputs text-black base-font-muli fw-800"
                            >
                              Male
                            </label>
                          </div>
                          <div className="d-flex pt-2">
                            <input
                              type="radio"
                              id="female"
                              name="gender"
                              value="Female"
                              checked={userDetail.gender === "Female"}
                              onChange={handleOnChange}
                              style={{
                                marginLeft: "30px",
                                fontWeight: 300,
                              }}
                            />
                            {"  "}
                            <label
                              htmlFor="female"
                              className="ps-1 fs-6 mb-0 Labels-font label-inputs base-font-muli text-black fw-800"
                            >
                              Female
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800 pb-2">
                          Date Of Birth
                        </p>
                        <Input
                          type="date"
                          id="dob"
                          name="date_of_birth"
                          value={userDetail.date_of_birth}
                          onChange={handleOnChange}
                          placeholder="Date Of Birth"
                          className="py-1 fs-6 shadow-none input-border"
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800 pb-2">
                          Emergency Contact Name
                        </p>
                        <Input
                          type="text"
                          id="emg_contact_name"
                          name="emg_contact_name"
                          value={userDetail.emg_contact_name}
                          onChange={handleOnChange}
                          placeholder="Emergency Contact Person"
                          className="py-1 fs-6 shadow-none input-border"
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800 pb-2">
                          Relation Emergency Contact
                        </p>
                        <Input
                          type="text"
                          id="relationship_with_emg_contact"
                          name="relationship_with_emg_contact"
                          value={userDetail.relationship_with_emg_contact}
                          onChange={handleOnChange}
                          placeholder="Relation with Emergency Contact"
                          className="py-1 fs-6 shadow-none input-border"
                        />
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="pt-3">
                        <p className="m-0 BD-labels-head base-font-muli fw-800 pb-2">
                          Emergency Contact Number
                        </p>
                        <Phone type="tel"
                          maxLength={12}
                          defaultCountry="in"
                          value={emgcontectno}
                          onChange={(e: any) => { setEmgContectNo(e.target.value) }}
                          placeholder="Contact Number"
                          className="py-1 pt-2 mt-1 fs-6 shadow-none input-border">
                          <Phone.Country className='border-0 w-25 ' />
                          <Phone.Number placeholder="1234567890" className=' ps-2 w-75 fs-6 title-font-500  border-0' />
                        </Phone>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Office Email
                        </p>
                        <label
                          className="border-1 w-100 Labels-font"
                          style={{ paddingTop: "2px", fontSize: 15 }}
                        >
                          {userDetail.email_id}
                        </label>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Date of Joining
                        </p>
                        <label
                          className="border-1 w-100 Labels-font"
                          style={{ paddingTop: "2px", fontSize: 15 }}
                        >
                          {userDetail.date_of_joining}
                        </label>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Reporting Manager
                        </p>
                        <label
                          className="border-1 w-100 Labels-font"
                          style={{ paddingTop: "2px", fontSize: 15 }}
                        >
                          {userDetail.reporting_manager}
                        </label>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Check-In Time
                        </p>
                        <label
                          className="border-1 w-100 Labels-font"
                          style={{ paddingTop: "2px", fontSize: 15 }}
                        >
                          {userDetail.check_in_time}
                        </label>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Check-Out Time
                        </p>
                        <label
                          className="border-1 w-100 Labels-font"
                          style={{ paddingTop: "2px", fontSize: 15 }}
                        >
                          {userDetail.check_out_time}
                        </label>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Department
                        </p>
                        <label
                          className="border-1 w-100 Labels-font"
                          style={{ paddingTop: "2px", fontSize: 15 }}
                        >
                          {userDetail.department}
                        </label>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Designation
                        </p>
                        <label
                          className="border-1 w-100 Labels-font"
                          style={{ paddingTop: "2px", fontSize: 15 }}
                        >
                          {userDetail.designation}
                        </label>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Probation Period
                        </p>
                        <label
                          className="border-1 w-100 Labels-font"
                          style={{ paddingTop: "2px", fontSize: 15 }}
                        >
                          {userDetail.probation_period}
                        </label>
                      </div>
                    </div>
                    <div className=" col-4">
                      <div className="p-2">
                        <p className="m-0 BD-labels-head base-font-muli fw-800">
                          Notice Period
                        </p>
                        <label
                          className="border-1 w-100 Labels-font"
                          style={{ paddingTop: "2px", fontSize: 15 }}
                        >
                          {userDetail.notice_period}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </CardBody>
              <CardFooter className="border-0 bg-transparent">
                <div className="d-flex justify-content-end gap-3">
                  <button className="update-btn">
                    Update
                    <Edit size="28" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                  <button
                    className="close-btn"
                    onClick={() => {
                      setShow(!show);
                      setFile("");
                    }}
                  >
                    Cancel
                    <Trash size="24" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                </div>
              </CardFooter>
            </form>
          </>
        )}
      </Card>
    </>
  );
}

export default BasicDetails;
