import {
    Category2,
    Element4,
    Note,
    Personalcard,
    ReceiptEdit,
    CalendarTick,
    ReceiptItem,
    MenuBoard,
    Gift,
    Trade,
    Clock,
    Profile2User,
  } from "iconsax-react";
  
  const SidebarApi2 = [
    {
      title: "Dashboard",
      icon: <Element4 variant="Bulk" size="24" />,
      link: "/dashboard",
    },
    {
      title: "Attendance",
      icon: <CalendarTick variant="Bulk" size="24" />,
      link: "/attendance",
      childs: [
        {
          title: "Attendance",
          icon: <Category2 variant="Bulk" size="24" />,
          link: "/attendance",
        },
        {
          title: "Mispunch",
          icon: <Note variant="Bulk" size="24" />,
          link: "/usermispunch",
        },
        {
          title: "Break Log",
          icon: <Clock variant="Bulk" size="24" />,
          link: "/userbreaklog",
        },
      ],
    },
    {
      title: "Team Lead",
      icon: <Profile2User variant="Bulk" size="24" />,
      link: "/teamMembers",
      childs: [
        {
          title: "Team Members",
          icon: <Profile2User variant="Bulk" size="24" />,
          link: "/teamMembers",
        },
        {
          title: "Attendance",
          icon: <Category2 variant="Bulk" size="24" />,
          link: "/teamAttendance",
        },
        {
          title: "Leaves",
          icon: <Note variant="Bulk" size="24" />,
          link: "/teamLeaves",
        },
      ],
    },
    {
      title: "Projects",
      icon: <Trade variant="Bulk" size="24" />,
      link: "/projects",
    },
    {
      title: "Basic Details",
      icon: <Personalcard variant="Bulk" size="24" />,
      link: "/basicdetails",
    },
  
    {
      title: "Holidays",
      icon: <MenuBoard variant="Bulk" size="24" />,
      link: "/holidays",
    },
  
    {
      title: "Salary Slips",
      icon: <ReceiptItem variant="Bulk" size="24" />,
      link: "/usersalarysliptable",
    },
  
    {
      title: "Rewards",
      icon: <Gift variant="Bulk" size="24" />,
      link: "/rewards",
    },
  ];
  
  export default SidebarApi2;
  