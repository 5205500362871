import React, { useState, useEffect } from 'react';
import swal from "sweetalert";
import axios from 'axios';
import { useForm } from "react-hook-form";
import { Button, Card, CardBody, CardFooter, CardTitle, Form, Input, Label } from 'reactstrap'
import { useNavigate } from 'react-router-dom';
import { environment } from '../../../env';

const AddBankDetails = () => {

    const [userDetail, setUserDetail] = useState(
        {
            bank_name: ' ',
            acc_holder_name: ' ',
            acc_number: ' ',
            acc_ifsc_number: ' ',
            acc_type: ' ',
            acc_branch: ' '
        }
    );
    let EmpId = localStorage.getItem("employee_id");
    let userId = localStorage.getItem("userid");
    const navigate = useNavigate();

    const {
        reset,
        formState: { errors },
    } = useForm();

    const handleOnChange = (e: any) => {
        setUserDetail({
            ...userDetail,
            [e.target.name]: e.target.value
        });
    }

    const UserPersonalDetails = async () => {
        try {
            const result = await axios(`${environment.baseUrl}get-basic-details/${EmpId}`).then((res) => {

                if (res.data.bank_name === "null") {
                    res.data.bank_name = ""
                }
                if (res.data.acc_holder_name === "null") {
                    res.data.acc_holder_name = ""
                }
                if (res.data.acc_number === "null") {
                    res.data.acc_number = ""
                }
                if (res.data.acc_ifsc_number === "null") {
                    res.data.acc_ifsc_number = ""
                }
                if (res.data.acc_branch === "null") {
                    res.data.acc_branch = ""
                }
                if (res.data.acc_type === "null") {
                    res.data.acc_type = ""
                }

                setUserDetail(res.data)
                localStorage.setItem("LoggedUserDetails", JSON.stringify(res.data))
                reset(res.data)
            })
                .catch((error) => {
                    console.log("Error", error);
                })

        } catch (err) {
            console.log("Error", err);
        }
    };
    useEffect(() => {
        UserPersonalDetails();
    }, [])

    const ProfilePostMethod = async () => {
        let form_data = new FormData();
        form_data.append('bank_name', userDetail.bank_name)
        form_data.append('acc_holder_name', userDetail.acc_holder_name)
        form_data.append('acc_number', userDetail.acc_number)
        form_data.append('acc_ifsc_number', userDetail.acc_ifsc_number)
        form_data.append('acc_type', userDetail.acc_type)
        form_data.append('acc_branch', userDetail.acc_branch)
        try {
            const res = await axios.put(`${environment.baseUrl}update-basic-details/${EmpId}`, form_data);
            return res.data
        }
        catch (err) {
            console.log("err");
            return err;

        }
        return
    };

    const onSubmit = async (e: any) => {
        e.preventDefault();
        ProfilePostMethod().then((profileResponse) => {
            if (profileResponse.status === 'success') {
                swal({
                    title: "Success",
                    text: 'Bank Details is updated Successfully !',
                    icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
                })
                    .then(() => {
                        navigate(-1)
                    })
            }
        });
    }



    return (
        <div>
            <Card style={{ background: "white", width: "95%", marginLeft: "36px", height: "80%" }}>
                <CardTitle >
                    <h1 className="h3 mb-0 text-gray-800" style={{ fontWeight: 500, paddingTop: "20px", paddingLeft: "20px" }}>Bank Account Details</h1>
                </CardTitle>
                <CardBody style={{ marginTop: "50px" }}>
                    <Form
                        id="details" onSubmit={(e) => onSubmit(e)}>
                        <div style={{ justifyContent: "space-between", flexDirection: "row", display: "flex", gap: 20, marginTop: '-35px' }}>
                            <div className="width-left" style={{ width: "50%" }}>
                                <Label style={{ color: "black", fontSize: 18, fontWeight: 500 }}>

                                    Bank Name
                                </Label>
                                <Input
                                    type="text"
                                    id="Bank_name"
                                    name="bank_name"
                                    value={userDetail.bank_name}
                                    onChange={handleOnChange}
                                    placeholder="Bank Name">
                                </Input>
                                <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>

                                    Account Number
                                </Label>
                                <Input
                                    type="text"
                                    id="acc_number"
                                    name="acc_number"
                                    value={userDetail.acc_number}
                                    onChange={handleOnChange}
                                    placeholder="Account Number">
                                </Input>
                                <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>

                                    Account Type
                                </Label>
                                <Input
                                    type="text"
                                    id="acc_type"
                                    name="acc_type"
                                    value={userDetail.acc_type}
                                    onChange={handleOnChange}
                                    placeholder="Account Type">
                                </Input>

                            </div>
                            <div className="width-right" style={{ width: "50%" }}>
                                <Label style={{ color: "black", fontSize: 18, fontWeight: 500 }}>

                                    Account Holder's Name
                                </Label>
                                <Input
                                    type="text"
                                    id="acc_holder_name"
                                    name="acc_holder_name"
                                    value={userDetail.acc_holder_name}
                                    onChange={handleOnChange}
                                    placeholder="Account Holder's Name">
                                </Input>
                                <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                    IFSC Code
                                </Label>
                                <Input
                                    type="text"
                                    id="acc_ifsc_number"
                                    name="acc_ifsc_number"
                                    value={userDetail.acc_ifsc_number}
                                    onChange={handleOnChange}
                                    placeholder="IFSC Code">
                                </Input>
                                <Label style={{ paddingTop: "20px ", color: "black", fontSize: 18, fontWeight: 500 }}>
                                    Branch Name
                                </Label>
                                <Input
                                    type="text"
                                    id="branch_name"
                                    name="acc_branch"
                                    value={userDetail.acc_branch}
                                    onChange={handleOnChange}
                                    placeholder="Branch Name">
                                </Input>
                            </div>
                        </div>
                    </Form>
                </CardBody>
                <CardFooter>
                    <Button
                        color='success'
                        type="submit"
                        form="details"
                        value="Submit"
                        style={{ fontWeight: 600 }}>
                        Submit
                    </Button>
                </CardFooter>
            </Card>
        </div>
    )
}

export default AddBankDetails