import { BREAKLOG, HOLIDAYS, LEADLEAVES, LOGIN, PROJECT, REMINDER, TASKS, TEAMMEMBER, TEAMS, USERATTENDANCE, USERDATA } from "./types"
export const loginAction = (data:any) => {
    return {
        data:data,
        type: LOGIN
    }
}

export const userDataAction = (data:any) => {
    return {
        data:data,
        type: USERDATA
    }
}

export const userAttendanceAction = (data:any) => {
    return {
        data:data,
        type: USERATTENDANCE
    }
}
export const HolidaysAction = (data:any) => {
    return {
        data:data,
        type: HOLIDAYS
    }
}
export const ReminderAction = (data:any) => {
    return {
        data:data,
        type: REMINDER
    }
}
export const getProjectData = (data: object) => {
    return {
        data: data,
        type: PROJECT
    }
}
export const getTeamsData = (data: object) => {
    return {
        data: data,
        type: TEAMS
    }
}
export const getTasksData = (data: object) => {
    return {
        data: data,
        type: TASKS
    }
}
export const getTeamMembersData = (data: object) => {
    return {
        data: data,
        type: TEAMMEMBER
    }
}
export const getBreakLogData = (data: object) => {
    return {
        data: data,
        type: BREAKLOG
    }
}
export const getLeadLeaveData = (data: object) => {
    return {
        data: data,
        type: LEADLEAVES
    }
}