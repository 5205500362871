export const LOGIN = "LOGIN";
export const USERDATA = "USERDATA";
export const USERATTENDANCE = "USERATTENDANCE";
export const HOLIDAYS = "HOLIDAYS";
export const REMINDER = "REMINDER";
export const PROJECT = "PROJECT"
export const TEAMS = "TEAMS"
export const TEAMMEMBER = "TEAMMEMBER"
export const TASKS = "TASKS"
export const BREAKLOG = "BREAKLOG"
export const LEADLEAVES = "LEADLEAVES"
