import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Form,
  Input,
  Label,
} from "reactstrap";
import { environment } from "../../../env";
import swal from "sweetalert";
import "../../../css/BasicDetails.css";
import { Edit, People, Trash, UserEdit } from "iconsax-react";

const FamilyDetails = (props: any) => {
  const [userDetail, setUserDetail] = useState({
    father_name: props.details.father_name,
    father_occupation: props.details.father_occupation,
    father_contact: props.details.father_contact,
    mother_name: props.details.mother_name,
    mother_occupation: props.details.mother_occupation,
    mother_contact: props.details.mother_contact,
    maritial_status: props.details.maritial_status,
    spouse_name: props.details.spouse_name,
    spouse_dob: props.details.spouse_dob,
    number_of_children: props.details.number_of_children,
    spouse_ocupation: props.details.spouse_ocupation,
    spouse_contact_no: props.details.spouse_contact_no,
  });
  let [show, setShow] = useState(false);
  let [Fshow, setFShow] = useState(true);

  const handleOnChange = (e: any) => {
    setUserDetail({
      ...userDetail,
      [e.target.name]: e.target.value,
    });
  };

  const submit = (e: any) => {
    e.preventDefault();
    // axios.post('url', {
    axios
      .put(
        `${environment.baseUrl}update-basic-details/${props.details.employee_id}`,
        {
          father_name: userDetail.father_name,
          father_occupation: userDetail.father_occupation,
          father_contact: userDetail.father_contact,
          mother_name: userDetail.mother_name,
          mother_occupation: userDetail.mother_occupation,
          mother_contact: userDetail.mother_contact,
          maritial_status: userDetail.maritial_status,
          spouse_name: userDetail.spouse_name,
          spouse_dob: userDetail.spouse_dob,
          number_of_children: userDetail.number_of_children,
          spouse_ocupation: userDetail.spouse_ocupation,
          spouse_contact_no: userDetail.spouse_contact_no,
        }
      )
      .then((res) => {
        if (res.status == 200) {
          swal({
            className: "styleTitle",
            title: "Success",
            text: "Family Details updated Successfully !",
            icon: "https://media2.giphy.com/media/Z4qWN3oS8O9jdOkOvL/200.gif?cid=790b76118bbdf4f8b676987331e1f5fe9465bcec1989bdb1&rid=200.gif&ct=g",
          });
          setFShow(!Fshow);
          props.update(!props.update);
        }
      })
      .catch((res) => console.log("error"));
  };

  return (
    <div>
      <Card className="mt-3 mx-3 mb-2 px-3 border-0 position-relative">
        <div className="">
          <div className="d-flex align-items-center justify-content-between">
            <div className="dashboard-heading col-6  px-0 d-flex align-items-center">
              <span className="base-text-color base-font-700 fs-4">
                <People size="35" variant="Bulk" />
              </span>
              <h4 className="fs-2 mb-0 text-black fw-bold  pt-2  base-font-pop text-capitalize ps-2">
                Family Details
              </h4>
            </div>
            {Fshow && (
              <Button
                className="tb-btn base-font-muli fw-bold d-flex align-items-center "
                onClick={() => setFShow(!Fshow)}
              >
                <span>Edit</span>
                <UserEdit size="32" variant="Bulk" className="ps-2" />
              </Button>
            )}
          </div>
        </div>
        {Fshow ? (
          <>
            <CardBody className="p-0 py-2">
              <Form id="details">
                <div className="row px-3">
                  <div className="col-4">
                    <Label className="BDLabels base-font-muli fs-5 ">
                      Father's Name
                    </Label>
                    <Label className="BDLabelInput">
                      {userDetail.father_name == "null" ? (
                        <span className="d-flex justify-content-center w-25">
                          {" "}
                          -{" "}
                        </span>
                      ) : (
                        userDetail.father_name
                      )}
                    </Label>
                  </div>
                  <div className="col-4">
                    <Label className="BDLabels base-font-muli fs-5 ">
                      Father's Occupation
                    </Label>

                    <Label className="BDLabelInput">
                      {userDetail.father_occupation == "null" ? (
                        <span className="d-flex justify-content-center w-25">
                          {" "}
                          -{" "}
                        </span>
                      ) : (
                        userDetail.father_occupation
                      )}
                    </Label>
                  </div>
                  <div className="col-4">
                    <Label className="BDLabels base-font-muli fs-5 ">
                      Father's Contact Number
                    </Label>

                    <Label className="BDLabelInput">
                      {userDetail.father_contact == "null" ? (
                        <span className="d-flex justify-content-center w-25">
                          {" "}
                          -{" "}
                        </span>
                      ) : (
                        userDetail.father_contact
                      )}
                    </Label>
                  </div>
                  <div className="col-4">
                    <Label className="BDLabels base-font-muli fs-5 ">
                      Mother's Name
                    </Label>

                    <Label className="BDLabelInput">
                      {userDetail.mother_name == "null" ? (
                        <span className="d-flex justify-content-center w-25">
                          {" "}
                          -{" "}
                        </span>
                      ) : (
                        userDetail.mother_name
                      )}
                    </Label>
                  </div>
                  <div className="col-4">
                    <Label className="BDLabels base-font-muli fs-5 ">
                      Mother's Occupation
                    </Label>

                    <Label className="BDLabelInput">
                      {userDetail.mother_occupation == "null" ? (
                        <span className="d-flex justify-content-center w-25">
                          {" "}
                          -{" "}
                        </span>
                      ) : (
                        userDetail.mother_occupation
                      )}
                    </Label>
                  </div>
                  <div className="col-4">
                    <Label className="BDLabels base-font-muli fs-5 ">
                      Mother's Contact
                    </Label>

                    <Label className="BDLabelInput">
                      {userDetail.mother_contact == "null" ? (
                        <span className="d-flex justify-content-center w-25">
                          {" "}
                          -{" "}
                        </span>
                      ) : (
                        userDetail.mother_contact
                      )}
                    </Label>
                  </div>
                </div>
                <div className="card mt-3 border-0 ">
                  <Label className="BDLabels pl-3 base-font-muli fs-5">
                    Your Maritial Status
                  </Label>
                  <Label className="BDLabelInput border-bottom pl-3">
                    {userDetail.maritial_status == "null" ? (
                      <span className="d-flex justify-content-center w-25">
                        {" "}
                        -{" "}
                      </span>
                    ) : (
                      userDetail.maritial_status
                    )}
                  </Label>
                  {userDetail.maritial_status === "UnMarried" ||
                  userDetail.maritial_status === undefined ||
                  userDetail.maritial_status === "" ? (
                    <></>
                  ) : (
                    <>
                      <div className="row px-3">
                        <div className="col-4">
                          <Label className="BDLabels base-font-muli  fs-5 ">
                            Spouse Name
                          </Label>

                          <Label className="BDLabelInput">
                            {userDetail.spouse_name == "null" ? (
                              <span className="d-flex justify-content-center w-25">
                                {" "}
                                -{" "}
                              </span>
                            ) : (
                              userDetail.spouse_name
                            )}
                          </Label>
                        </div>
                        <div className="col-4">
                          <Label className="BDLabels base-font-muli  fs-5 ">
                            Spouse Occupation
                          </Label>

                          <Label className="BDLabelInput">
                            {userDetail.spouse_ocupation == "null" ? (
                              <span className="d-flex justify-content-center w-25">
                                {" "}
                                -{" "}
                              </span>
                            ) : (
                              userDetail.spouse_ocupation
                            )}
                          </Label>
                        </div>
                        <div className="col-4">
                          <Label className="BDLabels base-font-muli  fs-5 ">
                            Number Of Children
                          </Label>

                          <Label className="BDLabelInput">
                            {userDetail.number_of_children == "null" ? (
                              <span className="d-flex justify-content-center w-25">
                                {" "}
                                -{" "}
                              </span>
                            ) : (
                              userDetail.number_of_children
                            )}
                          </Label>
                        </div>
                        <div className="col-4">
                          <Label className="BDLabels base-font-muli  fs-5 ">
                            Spouse DOB
                          </Label>

                          <Label className="BDLabelInput">
                            {userDetail.spouse_dob == "null" ? (
                              <span className="d-flex justify-content-center w-25">
                                {" "}
                                -{" "}
                              </span>
                            ) : (
                              userDetail.spouse_dob
                            )}
                          </Label>
                        </div>
                        <div className="col-4">
                          <Label className="BDLabels base-font-muli  fs-5 ">
                            Spouse Contact Number
                          </Label>

                          <Label className="BDLabelInput">
                            {userDetail.spouse_contact_no == "null" ? (
                              <span className="d-flex justify-content-center w-25">
                                {" "}
                                -{" "}
                              </span>
                            ) : (
                              userDetail.spouse_contact_no
                            )}
                          </Label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </Form>
            </CardBody>
          </>
        ) : (
          <>
            <form
              id="details"
              onSubmit={(e) => {
                submit(e);
              }}
            >
              <CardBody className="p-0 py-2">
                <div className="row px-3">
                  <div className="col-6">
                    <Label className="BDLabels base-font-600 fs-6 ">
                      Father's Name
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="f_name"
                      name="father_name"
                      value={
                        userDetail.father_name == "null"
                          ? (userDetail.father_name = "")
                          : userDetail.father_name
                      }
                      onChange={handleOnChange}
                      placeholder="Father's Name"
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className="BDLabels base-font-600 fs-6 ">
                      Father's Occupation
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="f_occupation"
                      name="father_occupation"
                      value={userDetail.father_occupation}
                      onChange={handleOnChange}
                      placeholder="Father's Occupation"
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className="BDLabels base-font-600 fs-6 ">
                      Father's Contact Number
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="f_contact"
                      name="father_contact"
                      value={userDetail.father_contact}
                      onChange={handleOnChange}
                      placeholder="Father's Contact"
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className="BDLabels base-font-600 fs-6 ">
                      Mother's Name
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="m_name"
                      name="mother_name"
                      value={userDetail.mother_name}
                      onChange={handleOnChange}
                      placeholder="Mother's Name"
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className="BDLabels base-font-600 fs-6 ">
                      Mother's Occupation
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="m_occupation"
                      name="mother_occupation"
                      value={userDetail.mother_occupation}
                      onChange={handleOnChange}
                      placeholder="Mother's Occupation"
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className="BDLabels base-font-600 fs-6 ">
                      Mother's Contact
                    </Label>
                    <Input
                      className="py-1 fs-6 shadow-none input-border"
                      type="text"
                      id="m_contact"
                      name="mother_contact"
                      value={userDetail.mother_contact}
                      onChange={handleOnChange}
                      placeholder="Mother's Contact Number"
                    ></Input>
                  </div>
                </div>
                <div className="card mt-3 px-3 border-0 row">
                  <div className="col-6">
                    <Label className="pb-1  BaDInput BD-maritial-font fs-6 base-font-600 text-black">
                      Maritial Status
                    </Label>
                  </div>
                  <div className="col-6">
                    <Label className="pb-1  BaDInput BD-maritial-font fs-6 base-font-600 text-black">
                      Un-Married
                    </Label>
                    <Input
                      className="RadioInput fs-5 mx-0 px-0"
                      type="radio"
                      id="maritial_status"
                      name="maritial_status"
                      value="UnMarried"
                      checked={userDetail.maritial_status === "UnMarried"}
                      onChange={handleOnChange}
                    ></Input>
                  </div>
                  <div className="col-6">
                    <Label className=" pb-1  BaDInput BD-maritial-font fs-6 base-font-600 text-black">
                      Married
                    </Label>
                    <Input
                      className="RadioInput fs-5 mx-0 px-0"
                      type="radio"
                      id="maritial_status"
                      name="maritial_status"
                      value="Married"
                      checked={userDetail.maritial_status === "Married"}
                      onChange={handleOnChange}
                    ></Input>
                  </div>
                  {show || userDetail.maritial_status === "Married" ? (
                    <>
                      <div className="row px-3">
                        <div className="col-6">
                          <Label className="BDLabels base-font-600 fs-6 ">
                            Spouse Name
                          </Label>
                          <Input
                            className="py-1 fs-6 shadow-none input-border"
                            type="text"
                            id="s_name"
                            name="spouse_name"
                            value={userDetail.spouse_name}
                            onChange={handleOnChange}
                            placeholder="Spose Name"
                          ></Input>
                        </div>
                        <div className="col-6">
                          <Label className="BDLabels base-font-600 fs-6 ">
                            Spouse Occupation
                          </Label>
                          <Input
                            className="py-1 fs-6 shadow-none input-border"
                            type="text"
                            id="spouse_ocupation"
                            name="spouse_ocupation"
                            value={userDetail.spouse_ocupation}
                            onChange={handleOnChange}
                            placeholder="Spose Occupation"
                          ></Input>
                        </div>
                        <div className="col-6">
                          <Label className="BDLabels base-font-600 fs-6 ">
                            Number Of Children
                          </Label>
                          <Input
                            className="py-1 fs-6 shadow-none input-border"
                            type="text"
                            id="number_of_children"
                            name="number_of_children"
                            value={userDetail.number_of_children}
                            onChange={handleOnChange}
                            placeholder="Number Of Children"
                          ></Input>
                        </div>
                        <div className="col-6">
                          <Label className="BDLabels base-font-600 fs-6 ">
                            Spouse DOB
                          </Label>
                          <Input
                            className="py-1 fs-6 shadow-none input-border"
                            type="date"
                            id="s_dob"
                            name="spouse_dob"
                            value={userDetail.spouse_dob}
                            onChange={handleOnChange}
                            placeholder="Spouse Dob"
                          ></Input>
                        </div>
                        <div className="col-6">
                          <Label className="BDLabels base-font-600 fs-6 ">
                            Spouse Contact Number
                          </Label>
                          <Input
                            className="py-1 fs-6 shadow-none input-border"
                            type="text"
                            id="spouse_contact_no"
                            name="spouse_contact_no"
                            value={userDetail.spouse_contact_no}
                            onChange={handleOnChange}
                            placeholder="Spouse Contact Number"
                          ></Input>
                        </div>
                      </div>
                      <div></div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </CardBody>
              <CardFooter className="border-0 bg-transparent">
                <div className="d-flex justify-content-end gap-3">
                  <button className="update-btn">
                    Update
                    <Edit size="28" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                  <button
                    className="close-btn"
                    onClick={() => {
                      setFShow(!Fshow);
                    }}
                  >
                    Cancel
                    <Trash size="24" className="ps-1 pb-1" variant="Bulk" />
                  </button>
                </div>
              </CardFooter>
            </form>
          </>
        )}
      </Card>
    </div>
  );
};

export default FamilyDetails;
