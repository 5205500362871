import React from "react";
import { RModalImages } from "react-modal-images";

const DocumentViewModal = (props: any) => {
  return (
    <figure className=" d-flex justify-content-center align-items-center flex-column m-0 p-0 w-100 h-100 imgBoxstyle " title={props.data}>
      <RModalImages
        className="m-0 p-0 rounded-3 w-100 h-100 imgBoxstylebox"
        small={props.data}
        large={props.data}
        alt={props.data ? `${props.data.split('taskimages_')[1]}` : "No Image"}
      />
    </figure>
  );
};

export default DocumentViewModal;
