import Favicon from '../../../image/Favicon.png'
import './salaryslip.css'
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import { jsPDF } from "jspdf";
import { useRef } from 'react';
import { environment } from '../../../env';
import axios from 'axios';
import moment from 'moment';
import html2canvas from 'html2canvas';

const SalarySlip = (props:any) => {
    const [show2, setShow2] = useState(false);
    const [usersalary, setUserSalary] = useState<any>({
        days_paid: '',
        payslip_month: '',
        days_present: '',
        basic: '',
        hra: '',
        conveyance: '',
        special_allowance: '',
        medical_allowance: '',
        total_earnings: '',
        net_pay: '',
        pf: '',
        esi: '',
        income_tax: '',
        advance: '',
        Prof_tax: '',
        total_deductions: '',
        user: ''

    });
    const [modal, setModal] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const handleClose = () => setShow2(false);
    const toggle = () => { setModal(!modal) };
    const pdfRef = useRef(null);
    const userid = localStorage.getItem('userid')

    const GeneratePDF = () => {
        // const content: any = pdfRef.current;
        // const doc = new jsPDF('p', 'pt', 'a3');
        // doc.html(content, {
        //     callback: function (doc:any) {
        //         // doc.save('sample.pdf'); 
        //         window.open(doc.output('bloburl'));
        //         return true;

        //     },
        //     // html2canvas: { scale: 0.26 },



        // });
        const ht: any = document.getElementById("divToPrint");
        html2canvas(ht, { useCORS: true }).then(function (canvas) {

            var imgWidth = 200;
            var pageHeight = 284.5;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            var doc = new jsPDF('p', 'mm', 'a4');
            var position = 8;
            var pageData = canvas.toDataURL('image/png');
            var imgData = encodeURIComponent(pageData);
            doc.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight);
            doc.setLineWidth(0.3);
            // doc.setDrawColor(0, 0, 0);
            doc.rect(3, 3, 204, 291);
            heightLeft -= pageHeight;

            while (heightLeft >= 5) {
                position = heightLeft - imgHeight;
                doc.addPage();
                doc.addImage(imgData, 'PNG', 5, position, imgWidth, imgHeight);
                doc.setLineWidth(0.3);
                // doc.setDrawColor(0, 0, 0);
                doc.rect(3, 3, 204, 291);
                heightLeft -= pageHeight;
            }
            window.open(doc.output('bloburl'));
            // doc.save(`test.pdf`);
        });
    }

    const UserSalary = () => {
        axios.get(`${environment.baseUrl}get-salaryslip/${props?.id}`).then(res => {
            setUserSalary(res.data[0])
        })
            .catch(res => console.log("error")
            )
    }

    useEffect(() => {
        UserSalary()
    }, [])




    return (
        <>

            <Link to='#' onClick={() => setLgShow(true)}>View</Link>

            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                show2={show2}
                onHide2={handleClose}
                className="SS-model"
                centered
            >
                <header id='divToPrint' ref={pdfRef}>

                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            <div style={{ marginLeft: '117px', color: '#000' }}><text>TechieBears Pvt. Ltd.</text></div>
                            <div className="nav">
                                <div className="main d-flex">
                                    <div className="imgdiv" style={{ marginTop: '-35px' }}>
                                        <img src={Favicon} alt="Favicon" />
                                    </div>
                                    <div className="fs-6 ml-5">
                                        <text className="SS-border"> 1458/0,R.No.103, 1st Floor, Shivram Nagar, NR Achal Guru Temple,
                                            Kamatghar, Bhiwandi, Thane, Maharashtra - 421302</text>
                                    </div>
                                </div>
                            </div>
                            <h4 style={{ textAlign: "center" }} className="SS-secondeTitle">{moment(usersalary?.payslip_month).format('MMMM')} Salary Slip</h4>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body   >
                        <div className="row">
                            <div className="col-4 d-flex">
                                <div className=" ml-2 pl-1 pt-2">
                                    <h6 className="font-weight-bold SS-empCode">Emp Code : </h6>
                                </div>
                                <div>
                                    <h6 className="pl-2 pt-2 SS-empCode">{usersalary?.user.employee_id}</h6>
                                </div>
                            </div>
                            <div className="col-4 d-flex">
                                <div className="ml-2 pl-4 pt-2">
                                    <h6 className="font-weight-bold SS-Branch">Branch :</h6>
                                </div>
                                <div>
                                    <h6 className=" pt-2 ml-1 SS-empCode"> Mumbai</h6>
                                </div>
                            </div>
                            <div className="col-4 d-flex">
                                <div className="p-2">
                                    <h6 className="font-weight-bold SS-Joining-dt">Joining Dt :</h6>
                                </div>
                                <div>
                                    <h6 className="pt-2 SS-empCode-dt">{usersalary?.user.date_of_joining == 'null'? "-" :usersalary?.user.date_of_joining}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row SS-emp-section">
                            <div className="col-4 d-flex">
                                <div className=" ml-2 pl-1 pt-2">
                                    <h6 className="font-weight-bold SS-employee">Name : </h6>
                                </div>
                                <div>
                                    <h6 className="pt-2 SS-employee-name text-capitalize">{usersalary?.user.firstname} {usersalary?.user.lastname}</h6>
                                </div>
                            </div>
                            <div className="col-4 d-flex">
                                <div className="ml-2 pl-4 pt-2">
                                    <h6 className="font-weight-bold SS-Department">Department :</h6>
                                </div>
                                <div>
                                    <h6 className=" pt-2 ml-1 SS-empCode"> {usersalary?.user.department =='null'? "-" :usersalary?.user.department}</h6>
                                </div>
                            </div>
                            <div className="col-4 d-flex">
                                <div className="p-2">
                                    <h6 className="font-weight-bold SS-Joining-dt">Designation :</h6>
                                </div>
                                <div>
                                    <h6 className="pt-2 SS-empCode-dt">{usersalary?.user.designation == 'null'? "-" :usersalary?.user.designation}</h6>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4 d-flex">
                                <div className=" ml-2 pl-1 pt-2">
                                    <h6 className="font-weight-bold">Days Paid : </h6>
                                </div>
                                <div>
                                    <h6 className="pl-2 pt-2">{usersalary?.days_paid}</h6>
                                </div>
                            </div>
                            <div className="col-4 d-flex">
                                <div className="ml-2 pl-4 pt-2">
                                    <h6 className="font-weight-bold">Days Present :</h6>
                                </div>
                                <div>
                                    <h6 className=" pt-2 ml-1">{usersalary?.days_present}</h6>
                                </div>
                            </div>
                            <div className="col-4 d-flex">
                                <div className="p-2">
                                    <h6 className="font-weight-bold">PAN :</h6>
                                </div>
                                <div>
                                    <h6 className="pt-2">{usersalary?.user.pan == 'null'? "-" :usersalary?.user.pan}</h6>
                                </div>
                            </div>
                        </div>
                        <table className="table text-dark">
                            <thead>
                                <tr className="">
                                    <th scope="col" className=" earnings border">
                                        Earnings Components
                                    </th>
                                    <th scope="col" className="border">
                                        Fixtures
                                    </th>

                                    <th scope="col" className="border">
                                        Deductions
                                    </th>
                                    <th scope="col" className="border">
                                        Amount
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="border">Basic</td>
                                    <td> {usersalary?.basic}</td>
                                    <td className="border">PF</td>
                                    <td className="border">{usersalary?.pf}</td>
                                </tr>
                                <tr>
                                    <td className="border">H.R.A</td>
                                    <td className="border">{usersalary?.hra}</td>
                                    <td className="border">ESI</td>
                                    <td className="border">{usersalary?.esi}</td>
                                </tr>
                                <tr>
                                    <td className="other border">Conveyance</td>
                                    <td className="border">{usersalary?.conveyance}</td>
                                    <td className="border">Income Tax</td>
                                    <td className="border">{usersalary?.income_tax}</td>
                                </tr>
                                <tr>
                                    <td className="border">Special Allowance</td>
                                    <td className="border">{usersalary?.special_allowance}</td>
                                    <td className="border">Advance</td>
                                    <td className="border">{usersalary?.advance}</td>
                                </tr>
                                <tr>
                                    <td className="border">Medical Allowance</td>
                                    <td className="border">{usersalary?.medical_allowance}</td>
                                    <td className="border">Prof Tax</td>
                                    <td className="border">{usersalary?.Prof_tax}</td>
                                </tr>
                                <tr>
                                    <td className="border fw-bold">Total Earnings</td>
                                    <td className="border">{usersalary?.total_earnings}</td>
                                    <td className="border fw-bold">Total Deductions</td>
                                    <td className="border">{usersalary?.total_deductions}</td>
                                </tr>
                                <tr>
                                    <td className="border fw-bold">Net Pay</td>
                                    <td className="border">{usersalary?.net_pay}</td>

                                    <td />
                                    <td className="border-right" />
                                </tr>
                            </tbody>
                        </table>
                        <h6 style={{ fontSize: 12 }}>
                            This is system generated payslip, no signature required
                        </h6>
                    </Modal.Body>

                </header>
                <Modal.Footer className='border-0'>
                    <button onClick={()=>{setLgShow(!lgShow)}} className="close-btn">
                    Cancel
                    </button>
                    <button onClick={GeneratePDF} className="submit-btn">
                    Download
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default SalarySlip
